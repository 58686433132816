import React from "react";

const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <g clip-path="url(#clip0_141_37254)">
      <path d="M27.5 15C27.5 17.4723 26.7669 19.889 25.3934 21.9446C24.0199 24.0002 22.0676 25.6024 19.7835 26.5485C17.4995 27.4946 14.9861 27.7421 12.5614 27.2598C10.1366 26.7775 7.90933 25.587 6.16117 23.8388C4.41301 22.0907 3.2225 19.8634 2.74019 17.4386C2.25787 15.0139 2.50542 12.5005 3.45151 10.2165C4.39761 7.93238 5.99976 5.98015 8.05538 4.60663C10.111 3.23311 12.5277 2.5 15 2.5" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.5 2.5L15 15" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 2.5H27.5V10" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_141_37254">
        <rect width="30" height="30" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  );
};

export default SendIcon;
export {};
