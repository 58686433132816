import React from "react";
import KickStartIcon from "../assets/icons/KickStartIcon";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import Step1Icon from "../assets/icons/Step1Icon";
import Step2Icon from "../assets/icons/Step2Icon";
import Step3Icon from "../assets/icons/Step3Icon";
const KickStart: React.FC = () => {
  const { theme } = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      mt={isMediumScreen ? 5 : 10}
      mb={5}
    >
      <Box>
       <Box sx={{display:'flex', flexDirection:'row', justifyContent:isMediumScreen?null:'space-between', alignItems:'center'}} width={isMediumScreen?'100%':'60%'}>
       <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "14px",
              fontWeight: 600,
              paddingBottom: "8px",
            }}
          >
            SWIFT ACCOUNT SETUP
          </label>
          {isMediumScreen ? (
            <Box mt={1}>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontSize: "40px",
                  fontWeight: 800,
                  lineHeight: "48px",
                  width: "100px",
                }}
              >
                Effortlessly kickstart your account
              </label>
            </Box>
          ) : (
            <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontSize: "40px",
                  fontWeight: 800,
                  lineHeight: "48px",
                }}
              >
                Effortlessly kickstart
              </label>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontSize: "40px",
                  fontWeight: 800,
                  lineHeight: "48px",
                }}
              >
                your account
              </label>
            </Box>
          )}
          <Box mt={2}>
            <Button
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: "47px",
                fontSize: "16px",
                fontWeight: 400,
                color: "#fff",
                padding: 1,
                pl: 2,
                pr: 2,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
        {isMediumScreen?null:(<Box sx={{alignSelf:'flex-end'}}>
          <KickStartIcon/>
        </Box>)}
       </Box>

        <Box
          mt="30px"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "98%",
            gap:'30px',
            flexWrap: "wrap",
            padding: "20px",
            borderRadius: "20px",
            border: "1.13px solid rgba(208, 208, 208, 1)",
          }}
        >
          {/* Step 1 - aligned to the left */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            width="300px"
            height="170px"
            padding="20px"
            borderRadius="18px"
            sx={{ backgroundColor: "rgba(251, 248, 255, 1)" }}
            // mt={5}
          >
            <Box>
              <Step2Icon />
            </Box>
            <Box>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 800,
                  fontSize: "16px",
                  lineHeight: "30px",
                }}
              >
                Sign Up
              </label>
            </Box>
            <Box>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
              >
                Ready to dive in? Create your account now using your email and
                phone number.
              </label>
            </Box>
          </Box>

          {/* Step 2 - aligned to the center */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            width="300px"
            height="170px"
            padding="20px"
            borderRadius="18px"
            sx={{ backgroundColor: "rgba(251, 248, 255, 1)" }}
          >
            <Box>
              <Step3Icon />
            </Box>
            <Box>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 800,
                  fontSize: "16px",
                  lineHeight: "30px",
                }}
              >
                KYC Verification
              </label>
            </Box>
            <Box>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
              >
                Validate your identity in a flash with our smooth KYC validation
              </label>
            </Box>
          </Box>

          {/* Step 3 - aligned to the right */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            // mt={2}
            width="300px"
            height="170px"
            padding="20px"
            borderRadius="18px"
            sx={{ backgroundColor: "rgba(251, 248, 255, 1)" }}
          >
            <Box>
              <Step1Icon />
            </Box>
            <Box>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 800,
                  fontSize: "16px",
                  lineHeight: "30px",
                }}
              >
                Initiate Transaction
              </label>
            </Box>
            <Box>
              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
              >
                You are all set! Dive into a world of effortless and immediate
                transfers.
              </label>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default KickStart;
