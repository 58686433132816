import React from 'react';

import {Grid, useMediaQuery, useTheme as T} from '@mui/material'

import images from '../utils/Images'
const PageNotFound: React.FC =()=>{
    const t = T()
    const isSmallScreen = useMediaQuery(t.breakpoints.down('md'))
    return(
            <Grid container lg={12} sx={{display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center"}} height={isSmallScreen?'100vh':'100%'}>
                <Grid lg={8} sm={12} xs={11} md={10} sx={{display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center"}}> 
                    <img src={images.PageNotFound.src} alt={images.PageNotFound.alt} style={{width:isSmallScreen?'100%':'75%'}}/>          
                </Grid> 
            </Grid> 
        )

}

export default PageNotFound