import React from 'react'

const HDFCbank: React.FC<React.SVGProps<SVGSVGElement>> = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="30" viewBox="0 0 181 30" fill="none">
        <path d="M0.545959 0H180.637V29.9965H0.545959V0Z" fill="#004C8F"/>
        <path d="M180.637 0H30.546V30H180.637V0Z" fill="#004C8F"/>
        <path d="M40.1165 22.1034V7.89307H44.756V12.6803H49.1457V7.89307H53.8344V22.1034H49.1457V16.5776H44.756V22.1034H40.1165Z" fill="white"/>
        <path d="M61.2352 11.4457H62.4698C63.3562 11.4457 63.9999 11.5442 64.3938 11.7412C64.6893 11.8889 64.9355 12.0859 65.1325 12.3814C65.3295 12.6768 65.4772 13.0215 65.6249 13.5175C65.7234 13.9607 65.7727 14.4566 65.7727 14.9491C65.7727 15.7862 65.6742 16.4791 65.428 17.0208C65.1817 17.5625 64.837 18.0092 64.3903 18.2554C63.9471 18.5017 63.3034 18.6494 62.4171 18.6494H61.2317V11.4457H61.2352ZM63.0115 22.1035C64.0984 22.1035 65.034 22.005 65.7762 21.808C66.5184 21.611 67.1585 21.3648 67.6017 21.0694C68.0942 20.7739 68.4881 20.3307 68.8856 19.7855C69.2795 19.2438 69.6242 18.5509 69.874 17.7138C70.1694 16.8766 70.2679 15.9375 70.2679 14.8506C70.2679 13.2713 69.9725 11.9874 69.3815 10.8548C68.7906 9.76792 67.9499 8.98002 66.8666 8.53683C65.8289 8.09363 64.5486 7.84741 63.0678 7.84741H56.5535V22.0578L63.0115 22.1035Z" fill="white"/>
        <path d="M72.4837 22.1034V7.89307H83.5354V11.3964H77.0704V13.6651H82.2516V17.07H77.0704V22.1034H72.4837Z" fill="white"/>
        <path d="M99.2268 13.3204H94.8371C94.7386 12.631 94.4924 12.0366 94.0492 11.6426C93.606 11.2487 93.0608 11.0517 92.4206 11.0517C91.5835 11.0517 90.9398 11.3471 90.3981 11.9908C89.8564 12.631 89.6102 13.6686 89.6102 15.1002C89.6102 16.0886 89.7087 16.8273 89.9549 17.3689C90.2012 17.9106 90.4966 18.2553 90.8941 18.5051C91.288 18.7513 91.7805 18.8498 92.3749 18.8498C93.1136 18.8498 93.6588 18.6528 94.102 18.2589C94.5452 17.8649 94.7914 17.2705 94.8406 16.5318H99.3288C99.2303 17.4182 99.0334 18.1604 98.7872 18.7513C98.5409 19.3422 98.0977 19.9367 97.5033 20.5276C96.9124 21.1185 96.1702 21.6145 95.3823 21.9099C94.5452 22.2546 93.606 22.4024 92.5191 22.4024C91.4323 22.4024 90.4474 22.2546 89.5082 21.9099C88.5691 21.5652 87.8304 21.122 87.1902 20.5276C86.5501 19.9367 86.0541 19.2437 85.7094 18.5051C85.217 17.4182 84.9708 16.2856 84.9708 14.9525C84.9708 13.8656 85.1677 12.8315 85.5124 11.8923C85.8571 10.9532 86.3496 10.1653 86.9933 9.47588C87.6334 8.83571 88.3264 8.33975 89.065 8.04429C90.0534 7.65034 91.0875 7.45337 92.3222 7.45337C93.3598 7.45337 94.2954 7.6011 95.2346 7.89656C96.121 8.19202 96.8631 8.63522 97.4541 9.22966C98.045 9.82059 98.4917 10.5135 98.7872 11.2522C98.9806 11.8888 99.1283 12.5325 99.2268 13.3204Z" fill="white"/>
        <path d="M114.176 11.1009C114.816 11.1009 115.262 11.1502 115.459 11.1994C115.755 11.2979 115.952 11.4457 116.1 11.6426C116.247 11.8396 116.346 12.0858 116.346 12.3813C116.346 12.726 116.198 13.0215 115.952 13.2677C115.656 13.5139 115.164 13.6124 114.422 13.6124H112.645V11.0974L114.176 11.1009ZM115.361 22.1034C116.448 22.1034 117.236 22.0542 117.679 21.9557C118.122 21.8572 118.618 21.6602 119.061 21.4632C119.504 21.217 119.898 21.02 120.099 20.7246C120.444 20.3799 120.739 19.9859 120.936 19.49C121.133 18.9975 121.231 18.4523 121.231 17.8614C121.231 17.0243 121.034 16.2821 120.591 15.7404C120.148 15.1495 119.603 14.8013 118.864 14.555C120.099 13.7671 120.69 12.7787 120.69 11.5441C120.69 10.2603 120.148 9.32465 119.061 8.63172C118.224 8.13928 116.94 7.89307 115.164 7.89307H108.108V22.1034H115.361ZM114.569 16.1344C115.509 16.1344 116.1 16.2328 116.395 16.4791C116.69 16.7253 116.838 17.0207 116.838 17.4675C116.838 17.9142 116.69 18.2554 116.395 18.4558C116.1 18.7021 115.509 18.8006 114.619 18.8006H112.695V16.0851L114.569 16.1344Z" fill="white"/>
        <path d="M131.496 17.3162H128.534L130.015 12.1351L131.496 17.3162ZM127.152 22.1034L127.693 20.2286H132.333L132.874 22.1034H137.514L132.431 7.89307H127.693L122.611 22.1034H127.152Z" fill="white"/>
        <path d="M139.487 22.1034V7.89307H143.831L148.713 15.4907V7.89307H153.205V22.1034H148.763L143.979 14.7028V22.1034H139.487Z" fill="white"/>
        <path d="M156.311 22.1034V7.89307H160.852V12.5818L165.045 7.89307H170.57L165.344 13.2219L171.116 22.1034H165.491L162.086 16.4298L160.852 17.6609V22.1034H156.311Z" fill="white"/>
        <path d="M30.546 0H0.545959V30H30.546V0Z" fill="#ED232A"/>
        <path d="M25.2662 5.23047H5.77618V24.7205H25.2662V5.23047Z" fill="white"/>
        <path d="M17.0781 0H14.0672V30H17.0781V0Z" fill="white"/>
        <path d="M30.546 13.5173H0.545959V16.5282H30.546V13.5173Z" fill="white"/>
        <path d="M20.0362 10.51H11.0562V19.49H20.0362V10.51Z" fill="#004C8F"/>
      </svg>
    )
}

export default HDFCbank;

export{};