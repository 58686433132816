import React from 'react';
import { Grid, useMediaQuery, useTheme as T } from '@mui/material';
import { useTheme } from '../assets/themeContext';
import SBIbank from '../assets/icons/SBIbank';
import AXISbank from '../assets/icons/AXISbank';
import ICICIbank from '../assets/icons/ICICIbank';
import HDFCbank from '../assets/icons/HDFCbank';
import PNBbank from '../assets/icons/PNBbank';

const BankingChoices: React.FC = () => {
  const {theme} = useTheme();
  const t = T();
  //const isScreen1200 = useMediaQuery(theme.breakpoints.down(1200));
  const isMediumScreen = useMediaQuery(t.breakpoints.down('md'));
  const isLargeScreen  = useMediaQuery(t.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(t.breakpoints.down('sm'))

  //const isAbove1200 = useMediaQuery(theme.breakpoints.up(12000))

  return (
    <>
      {isMediumScreen ? null:  
      <Grid display='flex' container flexDirection='row' alignItems='center' sx={{borderTop:'1.13px solid rgba(208, 208, 208, 1)',borderBottom:'1.13px solid rgba(208, 208, 208, 1)', marginY:'20px', paddingY:'20px'}}>
        <Grid item lg={3} xl={3} md={3} sm={3} alignSelf='center'>
          <label style={{
            fontWeight: 700,
            fontSize: '20.25px',
            color: t.palette.text.primary,
            marginBottom: t.spacing(2),
            lineHeight:'31.5px' // Adds space between the text and the logos
          }}>
            Diverse banking choices providing hassle-free deposits
          </label>
        </Grid>
        <Grid item lg={9} md={9} xl={9} sm={9} alignSelf='center'>
            <Grid display='flex' flexDirection='row' justifyContent='space-between' columnSpacing={2} alignItems='center'>
              {/* List of banks */}
              <Grid item md={2} lg={2} xl={2} sm={1}>
                <SBIbank/>
              </Grid>
              <Grid item md={2} lg={2} xl={2} sm={1}>
                <AXISbank/>
              </Grid>
              <Grid item md={0} lg={2} xl={3} sm={1}>
                <HDFCbank/>
              </Grid>
              
              { isLargeScreen ? null : 
              <Grid item lg={2} xl={2} sm={1}>
              <ICICIbank />
              </Grid>
              } 

              {isLargeScreen?null: 
              <Grid item lg={2} xl={2} sm={2}>
              <PNBbank />
              </Grid>
              }
  
              {/* ... more items ... */}
              {/* Conditionally render the '& many more' text based on screen size */}
              {!isSmallScreen && (
                <Grid item md={2} style={{ flexGrow: 1 }} sm={2} lg={2} xl={2}>
                  <label style={{ color: theme.palette.secondary.main, textAlign: 'right', fontSize:'20.25px', fontWeight:'700', lineHeight:'31.5px'}}>
                    & many more
                  </label>
                </Grid>
              )}
            </Grid>
          </Grid>
          </Grid>
    }
     </> 
  );
 
};
export default BankingChoices;
