import React, { useState } from "react";
import { useTheme } from "../assets/themeContext";
import {
  Grid,
  Button,
  Card,
  TextField,
  Box,
  useMediaQuery,
  useTheme as T,
  Link,
  InputAdornment,
} from "@mui/material";
import { contactLinks, socialLinks } from "../utils/Links";
import UserIcon from "../assets/icons/UserIcon";
import MailIcon from "../assets/icons/MailIcon";

const GetInTouch: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  const [mail, setMail] = useState<string>();
  const [name, setName] = useState<string>();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: isSmallScreen ? 0 : t.spacing(4),
        bgcolor: "#FDFAFF",
        marginY: "20px",
      }}
    >
      <Card
        sx={{
          maxWidth: 960,
          width: "100%",
          borderRadius: "16px",
          p: isSmallScreen ? 0.3 : t.spacing(6),
          boxShadow: t.shadows[0],
          display:'flex', 
          flexDirection:'column'
        }}
      >
        <label
          style={{
            color: theme.palette.secondary.light,
            opacity: 0.6,
            fontSize: isSmallScreen ? "14px" : "20px",
            fontWeight: 400,
          }}
        >
          WANT TO KNOW MORE ABOUT US?
        </label>
        <label
          style={{
            color: theme.palette.secondary.main,
            fontSize: isSmallScreen ? "36px" : "48px",
            fontWeight: 600,
          }}
        >
          Let's get in touch!
        </label>

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} mt={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              {contactLinks.map((contact, index) => (
                <Link
                  key={index}
                  href={contact.url}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  }}
                  color="inherit"
                  underline="none"
                >
                  {contact.icon}
                  <label>{contact.info}</label>
                </Link>
              ))}

              <label
                style={{
                  color: theme.palette.secondary.main,
                  fontSize: "21px",
                  fontWeight: 600,
                  paddingTop: "20px",
                }}
              >
                Connect with us
              </label>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: t.spacing(3),
                }}
              >
                {socialLinks.map((social, index) => (
                  <Link
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: theme.palette.secondary.main }}
                  >
                    {social.icon}
                  </Link>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: t.spacing(2),
              }}
            >
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "&.Mui-focused fieldset": {
                      borderColor: "#D8D8D8", // Use the color you prefer
                    },
                  },
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "&.Mui-focused fieldset": {
                      borderColor: "#D8D8D8", // Use the color you prefer
                    },
                  },
                }}
              />
              <Box textAlign="center">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: t.shape.borderRadius,
                    mt: 2,
                    mb: 2,
                    backgroundColor: theme.palette.primary.main,
                    width: 120,
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default GetInTouch;
