import React from 'react';
import { Container,Grid, Box,useMediaQuery,useTheme as T } from '@mui/material';
// import TrustedBar from '../pages/TrustedBar';

import { useTheme } from '../assets/themeContext';
import Conversion from '../pages/Conversion';
// import LoginForm from '../pages/Loginform';
// import SignUpForm from '../pages/SignUpForm'
import SendMoneyInstant from './SendMonetInstant';
import Promotion from './Promotion';
import BankingChoices from './BankingChoices';
import Footer from '../components/Footer';
import Download from './Download';
import FaqSection from './FaqsSection';
import GetInTouch from './GetInTouch';
import Referral from './Referral';
import FeatureCards from './FeatureCards';
import SafetyFeatures from './SafetyFeatures';
import RedeemBox from './RedeemBox';
import Header from './Header';
import KickStart from './KickStart';
import Rewards from './Rewards';


const MyComponent: React.FC = () => {
  const theme = useTheme();
  const t= T();
  const isSmallScreen = useMediaQuery(t.breakpoints.down('xs'));

 

  return (
    <>
    <Grid  item xs={12}>
    <Promotion/>
    </Grid>
    <Container maxWidth="lg" sx={{  
      paddingLeft: isSmallScreen ? t.spacing(0) : t.spacing(4), 
      paddingRight: isSmallScreen ? t.spacing(0) : t.spacing(4),
    }}>
     <Grid>
      <Header/>
    </Grid>
 <Grid container justifyContent="center" spacing={isSmallScreen ? 1 : 4}>
      <Grid item xs={12} lg={7} md={6} order={{ xs: 2, md: 1 }} justifyContent="center">
       <SendMoneyInstant/>
      </Grid>
      <Grid item xs={12} lg={5} md={6} order={{ xs: 1, md: 1 }} container justifyContent="center">
        <Conversion />
      </Grid>
    </Grid>
    <Grid>
      <BankingChoices/>
    </Grid>
    <Grid>
      <KickStart/>
    </Grid>
    <Box>
      <SafetyFeatures/>
    </Box>
    <Grid>
      <RedeemBox/>
    </Grid>
    <Grid>
      <FeatureCards/>
    </Grid>
    <Grid>
      <Referral/>
    </Grid>
    <Grid>
     < Rewards />
    </Grid>
    <Grid>
      <FaqSection/>
    </Grid>
    <Grid>
      <GetInTouch/>
    </Grid>
    </Container>
    <Grid>
      <Download/>
    </Grid>
    <Grid sx={{backgroundColor:'#1E1E20'}} lg={12} md={12} xl={12} sm={12} xs={12}>
    <Container maxWidth="lg" sx={{  
      paddingLeft: isSmallScreen ? t.spacing(0) : t.spacing(4), 
      paddingRight: isSmallScreen ? t.spacing(0) : t.spacing(4),
    }}>
      <Footer/>
      </Container>
    </Grid>
    
    </>
  );
};

export default MyComponent;
