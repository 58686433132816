import React, { useState } from "react";
import {
  Grid,
  useMediaQuery,
  useTheme as T,
  List,
  Box,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemButton,
} from "@mui/material";
import { faqData } from "../utils/Links";
import RightArrowIcon from "../assets/icons/RightArrowIcon";
import RightArrowSelected from "../assets/icons/RightArrowSelected";
import MobileArrowUpIcon from "../assets/icons/MobileArrowUpIcon";
import MobileArrowDownIcon from "../assets/icons/MobileArrowDownIcon";

const FaqSection: React.FC = () => {
  const t = T();
  const isMediumScreen = useMediaQuery(t.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));

  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  // State hook to keep track of selected faq index
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const onAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Grid sx={{ paddingY: "30px" }}>
      <Grid
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMediumScreen ? null : "center",
          width: "100%",
        }}
      >
        {isMediumScreen ? (
          <Grid sx={{ textAlign: isSmallScreen ? "left" : null , display:'flex', flexDirection:'column'}} lg={12}>
            <label
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "400",
                color: "#474747",
                lineHeight: "22.4px",
              }}
            >
              WHATS ON YOUR MIND?
            </label>
            <label
              style={{
                fontFamily: "Inter",
                fontSize: "36px",
                fontWeight: "700",
                color: "#000000",
                lineHeight: "43.2px",
                paddingTop: "10px",
              }}
            >
              We will help with some of that
            </label>
          </Grid>
        ) : null}
        {isMediumScreen ? (
          // for smaller devices
          <Grid lg={12} sx={{ marginY: "25px" }}>
            {faqData.map((faqItem, index) => (
              <Accordion
                key={index}
                expanded={index === expandedIndex}
                onChange={() => onAccordionChange(index)}
                style={{
                  margin: "0",
                  boxShadow: "rgba(0, 0, 0, 0.15)",
                  border: "none",
                  backgroundColor: "#FFF",
                  borderRadius: "2px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    index === expandedIndex ? (
                      <MobileArrowUpIcon />
                    ) : (
                      <MobileArrowDownIcon />
                    )
                  }
                  aria-controls={`faq-panel-${index}`}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    paddingX: "20px",
                    paddingY: "10px",
                    gap: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor:
                        index === expandedIndex
                          ? "rgba(109, 30, 212, 1)"
                          : "rgba(205, 167, 254, 1)",
                      marginRight: "15px",
                      alignSelf: "center",
                    }}
                  />
                  <label
                    style={{
                      fontFamily: "Inter",
                      fontSize: isSmallScreen ? "13px" : "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    {faqItem.question}
                  </label>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "rgba(253, 250, 255, 1)",
                    padding: "20px",
                  }}
                >
                  <Box sx={{ paddingY: "3px" }}>
                    <label
                      style={{
                        fontFamily: "Inter",
                        fontSize: isSmallScreen ? "12px" : "14px",
                        fontWeight: "450",
                        lineHeight: "24px",
                        color: "rgba(31, 31, 31, 1)",
                      }}
                    >
                      {faqItem.answer}
                    </label>
                  </Box>
                  <Box sx={{ paddingY: "3px" }}>
                    <label
                      style={{
                        fontFamily: "Inter",
                        fontSize: isSmallScreen ? "12px" : "14px",
                        fontWeight: "450",
                        lineHeight: "24px",
                        color: "rgba(31, 31, 31, 1)",
                      }}
                    >
                      {faqItem.subAnswer}
                    </label>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        ) : (
          //  For Larger devices
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Grid lg={6} md={5} sx={{ position: "relative", left: "7%" }}>
              <List sx={{ padding: "0" }}>
                {faqData.map((faqItem, index) => (
                  <ListItemButton
                    key={index}
                    selected={selectedIndex === index}
                    onClick={() => handleListItemClick(index)}
                    style={{
                      backgroundColor:
                        index === selectedIndex
                          ? "rgba(253, 250, 255, 1)"
                          : "#FFF",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    sx={{
                      paddingY: "20px",
                      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          backgroundColor:
                            index === selectedIndex
                              ? "rgba(109, 30, 212, 1)"
                              : "rgba(205, 167, 254, 1)",
                          marginRight: "15px",
                          alignSelf: "center",
                        }}
                      />
                      <ListItemText
                        primary={faqItem.question}
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#1F1F1F",
                        }}
                      />
                    </Box>
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {index === selectedIndex ? (
                        <RightArrowSelected />
                      ) : (
                        <RightArrowIcon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                ))}
              </List>
            </Grid>

            <Grid
              lg={6}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                backgroundColor: "rgba(253, 250, 255, 1)",
                paddingX: "20px",
                paddingTop: "20px",
                borderRadius: "16px",
                boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Grid
                lg={10}
                md={10}
                sx={{ backgroundColor: "rgba(253, 250, 255, 1)" }}
              >
                {selectedIndex !== null && (
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "rgba(253, 250, 255, 1)",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box sx={{ paddingY: "10px" }}>
                      <label
                        style={{
                          fontFamily: "Inter",
                          fontSize: "20px",
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: "#474747",
                        }}
                      >
                        WHAT’S ON YOUR MIND ?
                      </label>
                    </Box>
                    <Box sx={{ paddingY: "10px" }}>
                      <label
                        style={{
                          fontFamily: "Inter",
                          fontSize: "48px",
                          fontWeight: "600",
                          lineHeight: "57.6px",
                          color: "#1F1F1F",
                        }}
                      >
                        We will help with some of that
                      </label>
                    </Box>
                    <Box sx={{ paddingY: "10px" }}>
                      <label
                        style={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "28px",
                          color: "#1F1F1F",
                        }}
                      >
                        {faqData[selectedIndex].question}
                      </label>
                    </Box>
                    <Box sx={{ paddingY: "10px" }}>
                      <label
                        style={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#1F1F1F",
                        }}
                      >
                        {faqData[selectedIndex].answer}
                      </label>
                    </Box>
                    <Box sx={{ paddingY: "5px" }}>
                      <label
                        style={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#1F1F1F",
                        }}
                      >
                        {faqData[selectedIndex].subAnswer}
                      </label>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default FaqSection;
