import React from 'react';

const CheckmarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props} // Spread operator to include any other props passed to the component
    >
      <circle cx="9" cy="9" r="9" fill="#6D1ED4" />
      <path
        d="M5 9L7.8 12L13 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckmarkIcon;
export {};