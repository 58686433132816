import React from "react";
import { Grid, Box, useMediaQuery, useTheme, Link } from "@mui/material";
import { blogs, socialLinks } from "../utils/Links";
import FooterLogo from "../assets/icons/FooterLogo";
import ICICIbank from "../assets/icons/ICICIbank";
import AXISbank from "../assets/icons/AXISbank";
import PciDssIcon from "../assets/icons/PciDssIcon";
import { Label } from "@mui/icons-material";

const Footer: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box pt={3}>
      <Grid container>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <FooterLogo style={{ marginLeft: theme.spacing(-5) }} />
          </Box>
          <Box
            display="flex"
            my={1}
            justifyContent="space-between"
            sx={{ width: isSmallScreen ? "100px" : "200px" }}
          >
            {socialLinks.map((social, index) => (
              <Link
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "var(--neutral-300, #D4D4D4)" }}
              >
                {social.icon}
              </Link>
            ))}
          </Box>
          <Box my={1}>
            <label
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "var(--neutral-300, #D4D4D4)",
                textAlign: "left",
              }}
            >
              © 2024 APT Remit. All rights reserved.
            </label>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
          lg={2}
          marginTop={'20px'}
          sx={{
            paddingLeft: { xs: 0, sm: 3, xl: 6 },
          }}
        >
          <Box mb={"10px"}>
            <Link
              href="https://aptremit.io/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "#fff",
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              About
            </Link>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={"10px"}>
            <label style={{ fontSize: "14px", color: "#fff" }}>Blogs</label>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <label style={{ fontSize: "14px", color: "#fff" }}>
              Contact us
            </label>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          marginTop={'20px'}
          sx={{
            paddingLeft: { xs: 0, sm: 0, xl: 6 },
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <label style={{ fontSize: "14px", fontWeight: 600, color: "#fff" }}>
              PARTNERS
            </label>
          </Box>
          <Box my={1}>
            <Link
              href="https://bytex.io/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "var(--neutral-300, #D4D4D4)",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                gap: "8px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                cursor: "pointer",
              }}
            >
              <img
                src="https://assets.bytex.io/white_bytex_icon.png"
                style={{ width: "20px", height: "20px" }}
              />
              <label style={{ fontSize: "15px", cursor: "pointer" }}>
                Bytex
              </label>
            </Link>
          </Box>
          <Box my={1}>
            <Link
              href="https://cryptoforce.in/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "var(--neutral-300, #D4D4D4)",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                gap: "8px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                cursor: "pointer",
              }}
            >
              <img
                src="https://cryptoforce.in/img/icons/Cryptoforce/favicon.ico"
                style={{ width: "20px", height: "20px" }}
              />
              <label style={{ fontSize: "15px", cursor: "pointer" }}>
                Cryptoforce
              </label>
            </Link>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <label
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#fff",
                marginTop: "10px",
              }}
            >
              BANKING PARTNERS
            </label>
          </Box>
          <Box mt={'5px'}>
            <ICICIbank />
          </Box>
          <Box >
            <AXISbank />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          marginTop={'20px'}
          sx={{
            paddingLeft: { xs: 0, sm: 3, xl: 6 },
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <label style={{ fontSize: "14px", fontWeight: 600, color: "#fff" }}>
              SECURE
            </label>
          </Box>
          <Box>
            <PciDssIcon />
          </Box>
          {/* <Box my={1}>
            <Link
              sx={{
                color: "var(--neutral-300, #D4D4D4)",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                ":hover": { textDecoration: "underline" },
              }}
            >
              xecdfvdfvdf
            </Link>
          </Box> */}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          marginTop={'20px'}
          sx={{
            paddingLeft: { xs: 0, sm: 0, xl: 6 },
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <label style={{ fontSize: "14px", fontWeight: 600, color: "#fff" }}>
              ILLUSTRATIONS BY
            </label>
          </Box>

          <Box my={1}>
            <Link
              href="https://storyset.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "var(--neutral-300, #D4D4D4)",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                ":hover": { textDecoration: "underline" },
              }}
            >
              <label style={{ fontSize: "15px" }}>storyset.com</label>
            </Link>
          </Box>
        </Grid>

        <Grid
          width="100%"
          sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
          my={6}
        >
          <label style={{ color: "#fff", fontSize: "14px" }}>
            © 2024 AptRemit. All Rights Reserved.
          </label>
          <label style={{ color: "#fff", fontSize: "14px" }}>
            AptRemit is a financial technology company offering electronic
            financial payment services (NAICS Code 12345) in partnership with
            Bytex. and Bytex Canada Inc. which holds the Money Service Business
            (MSB) License in the USA (Registration Number: XXXXXXXXXX) and in
            Canada (Registration Number: XXXXXXXXXX) respectively.
          </label>
          <label style={{ color: "#fff", fontSize: "14px" }}>
            AptRemit is recognised as a Virtual Asset Service Provider (VASP)
            under Indian laws and is compliant with Anti-Money Laundering and
            Counter-Financing of Terrorism (AML-CFT) framework under PMLA
            (Prevention of Money Laundering Act 2002) regulations.
          </label>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3px",
              alignItems: "center",
              color: "#fff",
              fontSize: "14px",
              width: "100%",
            }}
          >
            <label>For any queries or grievances, please write to us at</label>
            <Link
              href="https://aptremit.io/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "#fff",
                fontSize: "14px",
                textDecoration: "underline",
              }}
            >
              aptremit.io
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
