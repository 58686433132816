import React from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme as T,
} from "@mui/material";
import MultiLayerIcon from "../assets/icons/MultiLayerIcon";
import SecureIcon from "../assets/icons/SecureIcon";
import SeamLessIcon from "../assets/icons/SeamLessIcon";

const SafetyFeatures: React.FC = () => {
  const t = T();
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "50px",
      }}
      lg={12}
    >
      <Grid
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: isSmallScreen ? null : "center",
        }}
      >
        <Grid
          sx={{ padding: "10px", textAlign: isSmallScreen ? "left" : "center", display:"flex", flexDirection:"column"}}
          lg={12}
        >
          <label
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              color: "#6D1ED4",
              fontWeight: "600",
              lineHeight: "16.94px",
            }}
          >
            WHY CHOOSE APTREMIT TRANSFER
          </label>
          {/* Remove auto if neccessary */}
          <label
            style={{
              fontFamily: "Inter",
              fontSize: "40px",
              color: "#1F1F1F",
              fontWeight: "800",
              lineHeight: "48px",
              width: isSmallScreen ? "auto" : "500px",
              paddingTop: "15px",
            }}
          >
            Your safety now comes at zero fee
          </label>
        </Grid>
        <Grid
          lg={12}
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            flexWrap: isSmallScreen ? null : "wrap",
            paddingY: "25px",
            marginY: "30px",
            gap: "30px",
            minWidth: isSmallScreen ? null : "100%",
            justifyContent: "space-around",
          }}
        >
          <Grid sm={6} xs={12} md={4} lg={3}>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.17)",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Box
                height={64}
                width={64}
                sx={{
                  borderRadius: "20px",
                  marginBottom: "20px",
                  boxShadow: "0px 6px 30px 0px rgba(109, 30, 212, 0.12)",
                  bgcolor: "#FFF",
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MultiLayerIcon />
              </Box>
              <label
                style={{
                  paddingTop: "10px",
                  paddingBottom:'10px',
                  fontFamily: "Inter",
                  fontSize: "24px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "30px",
                  width: "247px",
                }}
              >
                Multi Layered Security
              </label>
              <label
                style={{
                  paddingTop: "10px",
                  paddingBottom:'10px',
                  fontFamily: "Inter",
                  fontSize: "18px",
                  color: "#474747",
                  lineHeight: "30px",
                  fontWeight: "450",
                  width: "247px",
                }}
              >
                Robust, multi-layered security for utmost security
              </label>
            </Box>
          </Grid>

          <Grid sm={6} xs={12} md={4} lg={3}>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.17)",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Box
                height={64}
                width={64}
                sx={{
                  borderRadius: "20px",
                  marginBottom: "20px",
                  boxShadow: "0px 6px 30px 0px rgba(109, 30, 212, 0.12)",
                  bgcolor: "#FFF",
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SecureIcon />
              </Box>
              <label
                style={{
                  paddingTop: "10px",
                  paddingBottom:'10px',
                  fontFamily: "Inter",
                  fontSize: "24px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "30px",
                  width: "247px",
                }}
              >
                Fully Secure & Compliant
              </label>
              <label
                style={{
                  paddingTop: "10px",
                  paddingBottom:'10px',
                  fontFamily: "Inter",
                  fontSize: "18px",
                  color: "#474747",
                  lineHeight: "30px",
                  fontWeight: "500",
                  width: "247px",
                }}
              >
                Ensuring your complete security and compliance
              </label>
            </Box>
          </Grid>

          <Grid sm={6} xs={12} md={6} lg={3}>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.17)",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Box
                height={64}
                width={64}
                sx={{
                  borderRadius: "20px",
                  marginBottom: "20px",
                  boxShadow: "0px 6px 30px 0px rgba(109, 30, 212, 0.12)",
                  bgcolor: "#FFF",
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SeamLessIcon />
              </Box>
              <label
                style={{
                  paddingTop: "10px",
                  paddingBottom:'10px',
                  fontFamily: "Inter",
                  fontSize: "24px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "30px",
                  width: "247px",
                }}
              >
                Seamless & Stress free
              </label>
              <label
                style={{
                  paddingTop: "10px",
                  paddingBottom:'10px',
                  fontFamily: "Inter",
                  fontSize: "18px",
                  color: "#474747",
                  lineHeight: "30px",
                  fontWeight: "500",
                  width: "247px",
                }}
              >
                Experience effortless and secure transactions
              </label>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SafetyFeatures;
