import React from 'react'

const AXISbank: React.FC<React.SVGProps<SVGSVGElement>>=()=>{
   return(
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="28" viewBox="0 0 109 28" fill="none">
  <path d="M32.1703 27.8076H22.3241L16.3458 17.3822H26.1905L32.1703 27.8076Z" fill="#800000"/>
  <path d="M16.3105 0.370232L21.2476 8.86106L10.3418 27.8079H0.514526L16.3105 0.370232Z" fill="#800000"/>
  <path d="M38.2798 13.793L36.6205 10.614L34.8798 13.793H38.2798ZM38.9647 15.1212H34.1774L32.9326 17.3821H31.1085L36.653 7.44526L41.9959 17.3821H40.1475L38.9647 15.1212Z" fill="#800000"/>
  <path d="M45.7403 12.5026L42.654 8.04731H44.5779L46.72 11.2006L48.96 8.04731H50.899L47.6779 12.5026L51.0638 17.3821H49.1699L46.6938 13.8046L44.1344 17.3821H42.223L45.7403 12.5026Z" fill="#800000"/>
  <path d="M54.266 17.3823H52.5796V8.04707H54.2655L54.266 17.3823Z" fill="#800000"/>
  <path d="M62.6995 9.42084L61.3223 10.1039C61.0654 9.73302 60.8249 9.49113 60.5889 9.37722C60.3475 9.24731 60.0382 9.1838 59.6543 9.1838C59.1889 9.1838 58.8021 9.2953 58.486 9.52119C58.1782 9.73642 58.0236 10.0084 58.0241 10.3375C58.0241 10.7995 58.4317 11.164 59.2403 11.4398L60.3581 11.8252C61.2665 12.1228 61.9302 12.5043 62.3553 12.942C62.7819 13.387 62.9845 13.9266 62.9845 14.5747C62.9845 15.4302 62.6437 16.1457 61.9529 16.6974C61.2661 17.2679 60.4013 17.5496 59.377 17.5496C58.4051 17.5496 57.6009 17.3111 56.9717 16.8249C56.3425 16.3377 55.959 15.6649 55.8005 14.7986L57.5112 14.4826C57.5926 15.0347 57.7221 15.406 57.9213 15.6183C58.266 16.0187 58.7808 16.2238 59.4444 16.2238C59.9752 16.2238 60.4085 16.0813 60.7551 15.7798C61.1061 15.4884 61.2772 15.1127 61.2772 14.6629C61.2772 14.4826 61.251 14.3139 61.1783 14.1685C61.1298 14.0104 61.0339 13.8762 60.9073 13.7482C60.7842 13.628 60.6102 13.4985 60.4085 13.3904C60.2069 13.2765 59.9655 13.1801 59.6853 13.069L58.6058 12.6953C57.0652 12.1572 56.3071 11.3681 56.3071 10.3259C56.3071 9.62298 56.6299 9.0442 57.2698 8.57351C57.9087 8.09458 58.7095 7.85075 59.6669 7.85172C60.9553 7.85172 61.9733 8.37525 62.6995 9.42084Z" fill="#800000"/>
  <path d="M71.296 16.0531H72.3416C73.1119 16.0531 73.6732 15.9305 74.0315 15.6847C74.4057 15.4109 74.5986 15.0691 74.5986 14.6585C74.5986 14.2523 74.4057 13.9125 74.0475 13.6309C73.6931 13.3759 73.0659 13.2314 72.1516 13.2314H71.296V16.0531ZM71.296 12.054H71.8312C72.4575 12.054 72.9224 11.9401 73.2118 11.7074C73.5007 11.4742 73.6509 11.1339 73.6509 10.688C73.6509 10.242 73.5007 9.90656 73.203 9.68843C72.8986 9.45332 72.4667 9.33892 71.8942 9.33892H71.296V12.054ZM69.6082 17.3823V8.04707H71.296C72.045 8.04707 72.6412 8.08876 73.0867 8.18813C73.5351 8.28169 73.9132 8.45281 74.2205 8.6724C74.538 8.9201 74.7804 9.21337 74.968 9.57596C75.1619 9.9245 75.2559 10.2919 75.2559 10.6579C75.2559 11.3264 74.9442 11.8916 74.3301 12.3565C74.9302 12.5247 75.3936 12.8213 75.7373 13.2455C76.0897 13.6629 76.2613 14.1457 76.2613 14.7133C76.2613 15.4366 75.954 16.0531 75.332 16.5607C74.9622 16.8719 74.5443 17.0905 74.0804 17.2059C73.571 17.3256 72.9369 17.3823 72.1696 17.3823H69.6082Z" fill="#800000"/>
  <path d="M83.5279 13.793L81.8623 10.614L80.1143 13.793H83.5279ZM84.2147 15.1212H79.4206L78.1743 17.3821H76.3492L81.8855 7.44526L87.2376 17.3821H85.3801L84.2147 15.1212Z" fill="#800000"/>
  <path d="M88.3441 17.3823V7.398L96.5058 14.5359V8.04708H98.1966V17.9519L90.033 10.8382V17.3823H88.3441Z" fill="#800000"/>
  <path d="M101.982 11.774L106.344 8.04731H108.593L103.535 12.2767L108.628 17.3821H106.364L102.334 13.2273L101.982 13.5191V17.3821H100.289V8.04731H101.982V11.774Z" fill="#800000"/>
</svg> 
   )
}
export default AXISbank;

export {};