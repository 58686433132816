import React from "react";
import { useTheme } from "../assets/themeContext";
import { Grid, Box, useMediaQuery, useTheme as T } from "@mui/material";
import ReferalIcon from "../assets/icons/ReferralIcon";
import ReferralSmallIcon from "../assets/icons/ReferralSmallIcon";

const Referral: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const matchesSM = useMediaQuery(t.breakpoints.up("md"));

  return (
    <Box mt={5} mb={5}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="space-between"
      >
        {matchesSM && (
          <Grid item sm={5} xs={12} md={5} xl={6} lg={5}>
            <ReferalIcon />
            {/* <img src={images.Referral.src} alt={images.Referral.alt} style={{ maxWidth: '100%' }} /> */}
          </Grid>
        )}
        <Grid item xs={12} sm={5} order={{ xs: 1, sm: 2 }} sx={{display:'flex', flexDirection:'column'}}>
          <label
            style={{
              color: theme.palette.secondary.light,
              fontSize: matchesSM ? "20px" : "16px",
              fontWeight: 400,
            }}
          >
            BOUNTIFUL REFERRALS
          </label>
          <label
            style={{
              color: theme.palette.secondary.main,
              fontSize: matchesSM ? "48px" : "24px",
              fontWeight: 600,
              lineHeight:matchesSM?"57.8px":'35px',
            }}
          >
            Spread the glee, Rewards for thee!
          </label>
          <label
            style={{
              color: theme.palette.secondary.light,
              fontSize: matchesSM ? "20px" : "16px",
              fontWeight: 400,
            }}
          >
            Referring friends unveil a bundle of exclusive rewards for you.
            Enjoy the joy of seamless remittance and bonuses that await with
            every referral!
          </label>
        </Grid>
        {!matchesSM && (
          <Grid item sm={5} xs={12} md={5} xl={6} lg={5} order={{ xs: 2, sm: 1 }}>
            <ReferralSmallIcon/>
            {/* <img src={images.Referral.src} alt={images.Referral.alt} style={{ maxWidth: '100%' }} /> */}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default Referral;
