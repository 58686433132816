import React from 'react';

const EarlyBirdIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="500" height="416" viewBox="0 0 500 416" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M240.16 105.89C296.39 83.8897 396.03 25.6697 427.93 109.73C459.59 193.19 344.75 256.84 280 265.54C214.55 274.33 54.3596 232.54 89.9296 146.48C114.4 87.2897 178.06 130.15 240.16 105.89Z" fill="#6D1ED4"/>
    <path opacity="0.9" d="M240.16 105.89C296.39 83.8897 396.03 25.6697 427.93 109.73C459.59 193.19 344.75 256.84 280 265.54C214.55 274.33 54.3596 232.54 89.9296 146.48C114.4 87.2897 178.06 130.15 240.16 105.89Z" fill="white"/>
    <path d="M250 425.56C357.083 425.56 443.89 420.492 443.89 414.24C443.89 407.988 357.083 402.92 250 402.92C142.918 402.92 56.1104 407.988 56.1104 414.24C56.1104 420.492 142.918 425.56 250 425.56Z" fill="#F5F5F5"/>
    <path d="M77.3301 287.89H76.3301V301.33H77.3301V287.89Z" fill="#6D1ED4"/>
    <path d="M77.3301 305.33H76.3301V310.89H77.3301V305.33Z" fill="#6D1ED4"/>
    <path d="M79.8301 238.77H243.36V414.24H84.5101C83.2715 414.24 82.0837 413.748 81.2079 412.872C80.3321 411.996 79.8401 410.808 79.8401 409.57V238.77H79.8301Z" fill="#6D1ED4"/>
    <path opacity="0.2" d="M243.36 238.77H79.8301V251.21H173.92L177.04 414.24L179.22 251.21H243.36V238.77Z" fill="black"/>
    <path d="M78.4199 212.1H243.36V247.66H78.4199C77.8019 247.66 77.2093 247.414 76.7723 246.977C76.3353 246.54 76.0899 245.948 76.0899 245.33V214.44C76.0885 214.133 76.1478 213.829 76.2643 213.545C76.3809 213.261 76.5523 213.003 76.7688 212.786C76.9853 212.568 77.2426 212.396 77.5259 212.278C77.8092 212.16 78.113 212.1 78.4199 212.1Z" fill="#6D1ED4"/>
    <path d="M142.8 414.24L141.09 212.1H176.65L178.35 414.24H142.8Z" fill="#263238"/>
    <path d="M307.81 414.239H243.4L243.4 238.769H312.45V409.569C312.45 410.803 311.962 411.986 311.093 412.861C310.224 413.736 309.044 414.231 307.81 414.239Z" fill="#6D1ED4"/>
    <path opacity="0.2" d="M307.81 414.239H243.4L243.4 238.769H312.45V409.569C312.45 410.803 311.962 411.986 311.093 412.861C310.224 413.736 309.044 414.231 307.81 414.239Z" fill="black"/>
    <path opacity="0.2" d="M243.359 238.77H312.439V251.21H273.889L268.649 414.24L269.219 251.21H243.359V238.77Z" fill="black"/>
    <path d="M313.859 247.66H243.359V212.1H313.859C314.165 212.1 314.468 212.16 314.751 212.278C315.034 212.395 315.291 212.566 315.507 212.783C315.723 212.999 315.895 213.256 316.012 213.539C316.129 213.821 316.189 214.124 316.189 214.43V245.32C316.191 245.627 316.131 245.931 316.015 246.215C315.898 246.499 315.727 246.757 315.511 246.974C315.294 247.192 315.037 247.364 314.753 247.482C314.47 247.6 314.166 247.66 313.859 247.66Z" fill="#6D1ED4"/>
    <path opacity="0.2" d="M313.859 247.66H243.359V212.1H313.859C314.165 212.1 314.468 212.16 314.751 212.278C315.034 212.395 315.291 212.566 315.507 212.783C315.723 212.999 315.895 213.256 316.012 213.539C316.129 213.821 316.189 214.124 316.189 214.43V245.32C316.191 245.627 316.131 245.931 316.015 246.215C315.898 246.499 315.727 246.757 315.511 246.974C315.294 247.192 315.037 247.364 314.753 247.482C314.47 247.6 314.166 247.66 313.859 247.66Z" fill="black"/>
    <path d="M268.649 414.24L271.629 212.1H293.819L290.839 414.24H268.649Z" fill="#263238"/>
    <path opacity="0.2" d="M268.649 414.24L271.629 212.1H293.819L290.839 414.24H268.649Z" fill="black"/>
    <path d="M280.23 145V144.95H251.14C249.465 145.09 247.831 145.537 246.32 146.27C216.82 158.63 202.48 191.02 196.14 212.06H225.9C225.9 212.06 296.33 143 280.23 145Z" fill="#263238"/>
    <path opacity="0.5" d="M225.899 212.1C225.899 184.77 246.579 158.67 276.079 146.31C305.579 133.95 225.899 212.1 225.899 212.1Z" fill="black"/>
    <path d="M287.83 162.31H266.24C264.862 162.428 263.512 162.766 262.24 163.31C236.8 172.49 216.87 196.54 206.12 212.16H228.22C228.22 212.16 300.36 160.81 287.83 162.31Z" fill="#263238"/>
    <path opacity="0.5" d="M228.26 212.099C238.67 185.099 258.94 172.429 284.38 163.249C309.82 154.069 228.26 212.099 228.26 212.099Z" fill="black"/>
    <path d="M142.45 136.47V136.41H113.29C112.755 136.4 112.228 136.532 111.762 136.794C111.296 137.056 110.909 137.438 110.64 137.9C101.54 151.83 140.64 188.36 169.07 212.09H198.83C198.83 212.09 155.23 134.2 142.45 136.47Z" fill="#263238"/>
    <path opacity="0.5" d="M198.829 212.1C170.439 188.37 131.299 151.84 140.399 137.91C149.499 123.98 198.829 212.1 198.829 212.1Z" fill="black"/>
    <path d="M172.85 155.999H151.21C150.774 156.01 150.344 156.114 149.951 156.303C149.558 156.493 149.21 156.764 148.93 157.099C139.29 167.449 160.77 194.579 176.93 212.189H199C199 212.189 182.8 154.259 172.85 155.999Z" fill="#263238"/>
    <path opacity="0.5" d="M199 212.099C182.84 194.479 161.35 167.359 171 157.009C180.65 146.659 199 212.099 199 212.099Z" fill="black"/>
    <path d="M384.52 399.59L392.16 397.12L388.48 378.78L380.84 381.24L384.52 399.59Z" fill="#FF8B7B"/>
    <path d="M323.93 406.04H331.95L334.09 387.45H326.06L323.93 406.04Z" fill="#FF8B7B"/>
    <path d="M332.719 405.11H322.929C322.772 405.11 322.62 405.165 322.499 405.266C322.379 405.366 322.297 405.506 322.269 405.66L320.999 412.8C320.972 412.977 320.983 413.158 321.032 413.329C321.081 413.501 321.168 413.66 321.285 413.796C321.402 413.931 321.547 414.039 321.71 414.112C321.873 414.185 322.05 414.222 322.229 414.22C325.379 414.17 327.669 413.98 331.639 413.98C334.079 413.98 341.439 414.24 344.809 414.24C348.179 414.24 348.479 410.91 347.089 410.6C340.849 409.25 336.089 407.39 334.089 405.6C333.71 405.271 333.221 405.096 332.719 405.11Z" fill="#263238"/>
    <path d="M391.819 396.26L383.469 399C383.318 399.049 383.189 399.149 383.101 399.281C383.014 399.414 382.975 399.572 382.989 399.73L383.669 407.01C383.686 407.186 383.742 407.356 383.833 407.507C383.924 407.658 384.049 407.787 384.197 407.883C384.345 407.979 384.513 408.041 384.689 408.063C384.864 408.085 385.042 408.067 385.209 408.01C388.209 407.01 392.499 405.41 396.279 404.19C400.699 402.76 403.329 402.04 408.509 400.36C411.649 399.36 411.509 395.9 410.089 396.04C403.689 396.66 400.089 397.83 393.849 396.31C393.191 396.099 392.487 396.082 391.819 396.26Z" fill="#263238"/>
    <path d="M351.58 162.48C352.58 164.73 353.7 167.16 354.82 169.48C355.94 171.8 357.08 174.15 358.28 176.43C359.48 178.71 360.66 181 361.94 183.19C363.22 185.38 364.52 187.54 365.89 189.61C366.59 190.61 367.27 191.61 368.01 192.55L369.1 193.94C369.301 194.216 369.525 194.473 369.77 194.71C370.12 195.079 370.546 195.366 371.02 195.55C371.665 195.76 372.348 195.822 373.02 195.73C375.067 195.365 377.025 194.611 378.79 193.51C379.79 192.93 380.79 192.26 381.79 191.56C382.79 190.86 383.79 190.05 384.71 189.36L389.35 193.19C388.558 194.444 387.682 195.644 386.73 196.78C385.783 197.927 384.757 199.006 383.66 200.01C382.517 201.081 381.278 202.045 379.96 202.89C378.51 203.843 376.935 204.59 375.28 205.11C373.345 205.736 371.296 205.924 369.28 205.66C367.118 205.373 365.059 204.563 363.28 203.3C362.464 202.719 361.701 202.066 361 201.35L359.49 199.69C358.49 198.59 357.6 197.45 356.67 196.32C353.293 191.827 350.251 187.091 347.57 182.15C346.24 179.72 344.97 177.28 343.79 174.79C342.61 172.3 341.46 169.86 340.38 167.19L351.58 162.48Z" fill="#FF8B7B"/>
    <path d="M336.89 163.2C335.46 170.73 344.82 181.5 344.82 181.5L358.57 175.58C358.57 175.58 357.14 166.95 351.65 160.72C344.92 153.09 338.46 155 336.89 163.2Z" fill="#6D1ED4"/>
    <path opacity="0.2" d="M344.32 165.861C340.57 164.521 337.8 164.961 336.8 165.861C337.48 173.021 344.8 181.461 344.8 181.461L354.54 177.271C353.82 173.941 350.19 168.001 344.32 165.861Z" fill="black"/>
    <path d="M383.55 191.05L387.94 181.8L394.1 188.24C394.1 188.24 392.35 195.62 386.58 194.68L383.55 191.05Z" fill="#FF8B7B"/>
    <path d="M391.459 177.45L396.229 183.36L394.099 188.24L387.939 181.8L391.459 177.45Z" fill="#FF8B7B"/>
    <path opacity="0.2" d="M388.48 378.78L390.38 388.24L382.73 390.7L380.84 381.25L388.48 378.78Z" fill="black"/>
    <path opacity="0.2" d="M326.059 387.46H334.089L332.989 397.04H324.949L326.059 387.46Z" fill="black"/>
    <path d="M300.869 163.31C312.439 192.82 313.869 225.2 315.269 231.64L357.579 224.3C358.579 184.42 348.579 162.38 345.899 157.21C345.707 156.84 345.421 156.526 345.07 156.3C344.72 156.074 344.316 155.943 343.899 155.92C342.719 155.84 340.899 155.75 338.659 155.74C337.479 155.74 336.209 155.74 334.889 155.81C334.159 155.81 333.419 155.89 332.679 155.94C326.624 156.639 320.614 157.684 314.679 159.07C313.559 159.32 312.419 159.6 311.319 159.89C310.219 160.18 309.319 160.46 308.319 160.75C304.139 162 300.869 163.31 300.869 163.31Z" fill="#6D1ED4"/>
    <path opacity="0.2" d="M305 175.18C306.4 179.8 307.6 184.4 308.61 188.86C309.94 180.79 310.53 172.5 310.53 172.5L305 175.18Z" fill="black"/>
    <path d="M313.09 138.63C315.22 144.22 318.2 154.63 314.82 159.19C318.5 161.77 323.24 164.67 330.15 163.72C337.06 162.77 335.05 158.25 332.8 156.07C326.08 155.65 325.42 150.83 325.8 146.14L313.09 138.63Z" fill="#FF8B7B"/>
    <path d="M334.65 165.63C334.65 165.63 340 160.79 334.14 155.74C333.375 155.691 332.607 155.773 331.87 155.98C331.87 155.98 338.44 161.6 330.96 163.58C325.258 163.225 319.717 161.544 314.78 158.67C314.183 158.802 313.612 159.032 313.09 159.35C315.15 163.48 329.15 166.72 329.15 166.72C329.281 166.068 329.507 165.438 329.82 164.85C331.196 168.712 332.259 172.678 333 176.71C333.944 176.569 334.877 176.359 335.79 176.08C335.013 172.1 334.012 168.167 332.79 164.3C333.478 164.64 334.106 165.09 334.65 165.63Z" fill="white"/>
    <path opacity="0.2" d="M318.249 141.7L325.749 146.14C325.659 147.235 325.659 148.335 325.749 149.43C323.229 149.5 319.369 147.38 318.659 144.84C318.338 143.826 318.199 142.763 318.249 141.7Z" fill="black"/>
    <path d="M328.07 130.751C333.95 128.511 333.34 113.471 324.29 115.691C318.17 117.191 318.8 119.041 319.92 122.971C321.04 126.901 324.35 132.171 328.07 130.751Z" fill="#263238"/>
    <path d="M310.889 130.051C312.729 137.801 313.409 142.431 318.069 145.761C325.069 150.761 334.069 145.511 334.629 137.371C335.089 130.041 331.999 118.591 323.789 116.811C321.981 116.407 320.1 116.473 318.324 117.001C316.549 117.53 314.938 118.504 313.645 119.831C312.352 121.158 311.42 122.794 310.938 124.582C310.456 126.371 310.439 128.254 310.889 130.051Z" fill="#FF8B7B"/>
    <path d="M311.999 135.67C317.879 133.43 315.619 118.67 306.569 120.92C300.449 122.42 301.509 125.92 303.229 129.36C305.079 133 308.309 137.09 311.999 135.67Z" fill="#263238"/>
    <path d="M325.439 109.59C323.909 105.68 315.939 102.86 314.489 110.46C307.789 110.01 291.829 122.98 310.779 122.93C320.149 125.29 334.029 120.13 334.239 114.7C334.449 109.27 327.739 107.31 325.439 109.59Z" fill="#263238"/>
    <path d="M308.9 137.54C309.909 139.076 311.482 140.154 313.28 140.54C315.62 141 316.52 138.79 315.64 136.61C314.85 134.61 312.64 132.06 310.44 132.61C308.24 133.16 307.64 135.64 308.9 137.54Z" fill="#FF8B7B"/>
    <path d="M392.67 385.55L379.73 389.72C379.73 389.72 366.86 343.09 362 318.56C360.51 311.09 356.39 301.56 352.74 290.56C348.13 276.73 342.59 261.84 338.12 250.19C333.4 237.92 329.92 229.11 329.92 229.11L357.59 224.3C357.59 224.3 381.44 288.91 384.76 313.18C388.21 338.42 392.67 385.55 392.67 385.55Z" fill="#263238"/>
    <path opacity="0.2" d="M352.729 290.56C348.119 276.73 342.579 261.84 338.109 250.19L340.369 243.63C348.159 251.17 352.649 276.54 352.729 290.56Z" fill="black"/>
    <path d="M344.399 226.59C344.399 226.59 346.649 295.71 345.649 319.2C344.549 344.94 336.699 395.2 336.699 395.2H321.999C321.999 395.2 325.279 349.63 324.999 324.52C324.649 295.92 315.319 231.66 315.319 231.66L344.399 226.59Z" fill="#263238"/>
    <path d="M338.46 395.5H321.14V390.33L340.5 389.99L338.46 395.5Z" fill="#6D1ED4"/>
    <path d="M394.389 385.33L378.979 390.3L377.369 385.32L394.519 379.5L394.389 385.33Z" fill="#6D1ED4"/>
    <path d="M323.15 130.171C323.26 130.811 323.69 131.271 324.15 131.171C324.61 131.071 324.81 130.521 324.7 129.881C324.59 129.241 324.16 128.791 323.7 128.881C323.24 128.971 323 129.541 323.15 130.171Z" fill="#263238"/>
    <path d="M330.36 128.92C330.47 129.56 330.9 130.02 331.36 129.92C331.82 129.82 332.02 129.27 331.91 128.63C331.8 127.99 331.37 127.53 330.96 127.63C330.55 127.73 330.25 128.28 330.36 128.92Z" fill="#263238"/>
    <path d="M328.119 129.641C329.218 131.458 330.564 133.114 332.119 134.561C331.737 134.948 331.275 135.248 330.766 135.44C330.257 135.631 329.712 135.71 329.169 135.671L328.119 129.641Z" fill="#FF5652"/>
    <path d="M326.78 137.84C326.048 137.882 325.315 137.774 324.627 137.521C323.939 137.268 323.31 136.876 322.78 136.37C322.762 136.353 322.748 136.332 322.738 136.308C322.729 136.285 322.724 136.26 322.724 136.235C322.724 136.21 322.729 136.185 322.738 136.162C322.748 136.139 322.762 136.118 322.78 136.1C322.817 136.067 322.865 136.048 322.915 136.048C322.965 136.048 323.013 136.067 323.05 136.1C323.642 136.646 324.354 137.045 325.129 137.264C325.904 137.483 326.72 137.516 327.51 137.36C327.56 137.354 327.611 137.367 327.651 137.397C327.692 137.427 327.72 137.471 327.73 137.52C327.739 137.569 327.728 137.62 327.7 137.661C327.672 137.702 327.629 137.731 327.58 137.74C327.316 137.79 327.048 137.823 326.78 137.84Z" fill="#263238"/>
    <path d="M320.52 128.231C320.461 128.245 320.399 128.245 320.34 128.231C320.251 128.184 320.184 128.105 320.153 128.01C320.121 127.915 320.127 127.811 320.17 127.721C320.435 127.165 320.831 126.682 321.324 126.313C321.817 125.943 322.392 125.699 323 125.601C323.101 125.59 323.201 125.62 323.28 125.684C323.359 125.748 323.409 125.84 323.42 125.941C323.43 126.042 323.4 126.142 323.337 126.221C323.273 126.3 323.181 126.35 323.08 126.361C322.611 126.444 322.168 126.639 321.789 126.927C321.41 127.216 321.105 127.591 320.9 128.021C320.867 128.091 320.812 128.15 320.744 128.187C320.676 128.225 320.597 128.24 320.52 128.231Z" fill="#263238"/>
    <path d="M332.999 125.9C332.936 125.904 332.873 125.892 332.816 125.866C332.758 125.84 332.708 125.8 332.669 125.75C332.404 125.354 332.048 125.026 331.63 124.795C331.213 124.563 330.746 124.435 330.269 124.42C330.168 124.423 330.069 124.386 329.994 124.317C329.919 124.247 329.874 124.152 329.869 124.05C329.867 123.951 329.903 123.855 329.97 123.782C330.037 123.709 330.13 123.665 330.229 123.66C330.833 123.668 331.427 123.822 331.959 124.107C332.492 124.392 332.948 124.802 333.289 125.3C333.349 125.381 333.374 125.482 333.359 125.582C333.344 125.681 333.29 125.77 333.209 125.83C333.149 125.876 333.075 125.9 332.999 125.9Z" fill="#263238"/>
    <path d="M335.43 406.52C334.718 406.5 334.011 406.403 333.32 406.23C333.282 406.221 333.248 406.201 333.221 406.173C333.194 406.144 333.177 406.109 333.17 406.07C333.162 406.034 333.166 405.997 333.18 405.963C333.194 405.929 333.219 405.9 333.25 405.88C333.53 405.7 336.06 404.11 336.96 404.56C337.061 404.611 337.148 404.687 337.209 404.782C337.271 404.877 337.306 404.987 337.31 405.1C337.341 405.283 337.325 405.47 337.263 405.645C337.202 405.82 337.098 405.977 336.96 406.1C336.513 406.41 335.973 406.559 335.43 406.52ZM333.86 405.96C335.23 406.23 336.28 406.18 336.7 405.81C336.796 405.728 336.868 405.622 336.909 405.502C336.949 405.382 336.957 405.254 336.93 405.13C336.933 405.084 336.923 405.037 336.9 404.996C336.876 404.955 336.842 404.922 336.8 404.9C336.33 404.66 334.89 405.35 333.86 406V405.96Z" fill="#6D1ED4"/>
    <path d="M333.359 406.231C333.327 406.239 333.292 406.239 333.259 406.231C333.23 406.214 333.206 406.189 333.19 406.159C333.174 406.128 333.167 406.094 333.169 406.061C333.169 405.961 333.269 403.641 334.169 402.881C334.278 402.785 334.405 402.714 334.543 402.673C334.681 402.632 334.827 402.621 334.969 402.641C335.113 402.639 335.253 402.687 335.366 402.776C335.479 402.865 335.558 402.99 335.589 403.131C335.799 404.031 334.269 405.791 333.449 406.241L333.359 406.231ZM334.849 402.981C334.77 402.979 334.691 402.993 334.617 403.022C334.544 403.052 334.476 403.095 334.419 403.151C333.883 403.878 333.589 404.756 333.579 405.661C334.379 405.031 335.349 403.661 335.219 403.181C335.219 403.131 335.219 403.011 334.939 402.981H334.849Z" fill="#6D1ED4"/>
    <path d="M392.91 397.07C392.874 397.071 392.838 397.061 392.808 397.041C392.778 397.021 392.754 396.993 392.74 396.96C392.718 396.929 392.706 396.892 392.706 396.855C392.706 396.817 392.718 396.78 392.74 396.75C393.03 396.45 395.6 393.91 396.74 394.12C396.843 394.142 396.938 394.189 397.019 394.257C397.099 394.325 397.161 394.412 397.2 394.51C397.271 394.669 397.303 394.843 397.293 395.017C397.282 395.191 397.23 395.36 397.14 395.51C396.54 396.51 394.25 396.93 392.95 397.08L392.91 397.07ZM396.58 394.49C395.94 394.49 394.48 395.65 393.46 396.62C395.23 396.35 396.46 395.87 396.8 395.31C396.854 395.208 396.883 395.095 396.883 394.98C396.883 394.865 396.854 394.751 396.8 394.65C396.79 394.61 396.769 394.575 396.738 394.548C396.708 394.522 396.67 394.505 396.63 394.5L396.58 394.49Z" fill="#6D1ED4"/>
    <path d="M392.91 397.07H392.86C392.827 397.062 392.797 397.045 392.773 397.02C392.75 396.995 392.735 396.963 392.73 396.93C392.73 396.84 392.25 394.72 392.96 393.61C393.06 393.447 393.193 393.306 393.349 393.196C393.506 393.086 393.683 393.009 393.87 392.97C394.38 392.86 394.61 393.07 394.7 393.26C395.12 394.08 393.83 396.32 393.04 397.03C393.002 397.057 392.956 397.071 392.91 397.07ZM394.14 393.31H393.95C393.811 393.336 393.68 393.392 393.565 393.473C393.449 393.554 393.352 393.659 393.28 393.78C392.923 394.627 392.839 395.563 393.04 396.46C393.79 395.59 394.62 393.89 394.37 393.4C394.344 393.365 394.309 393.337 394.268 393.321C394.227 393.305 394.183 393.301 394.14 393.31Z" fill="#6D1ED4"/>
    <path d="M310.64 173.261C309.64 175.561 308.64 178.041 307.64 180.471C306.64 182.901 305.72 185.351 304.83 187.811C303.94 190.271 303.06 192.711 302.3 195.171C301.54 197.631 300.82 200.081 300.24 202.501C299.97 203.711 299.68 204.921 299.5 206.101L299.34 206.991V207.211V207.151C299.34 207.101 299.34 207.021 299.34 206.951C299.294 206.651 299.158 206.372 298.95 206.151C298.65 205.961 299.09 206.531 299.87 206.911C300.8 207.387 301.771 207.782 302.77 208.091C307.689 209.391 312.705 210.291 317.77 210.781L318.09 216.781C315.286 217.311 312.449 217.645 309.6 217.781C306.649 217.952 303.689 217.841 300.76 217.451C299.106 217.235 297.48 216.843 295.91 216.281C294.966 215.94 294.059 215.508 293.2 214.991C292.079 214.318 291.092 213.443 290.29 212.411C289.278 211.089 288.648 209.515 288.47 207.861C288.445 207.458 288.445 207.054 288.47 206.651V206.081V205.801L288.58 204.681C288.7 203.171 288.93 201.731 289.15 200.271C290.166 194.676 291.597 189.164 293.43 183.781C294.34 181.131 295.27 178.511 296.32 175.921C297.37 173.331 298.39 170.811 299.64 168.181L310.64 173.261Z" fill="#FF8B7B"/>
    <path d="M300.869 163.31C296.709 165.56 293.819 179.08 293.819 179.08L306.119 186.64C306.119 186.64 309.309 180.39 311.649 175.25C316.129 165.42 309.049 158.89 300.869 163.31Z" fill="#6D1ED4"/>
    <path d="M316.67 210.65L324.76 210.3L321.17 218C321.17 218 313.55 217.54 314.56 213.89L316.67 210.65Z" fill="#FF8B7B"/>
    <path d="M330.09 215.6L326.06 220.62L321.18 217.96L324.76 210.29L330.09 215.6Z" fill="#FF8B7B"/>
    </svg>
    
  );
};

export default EarlyBirdIcon;
export {};