import React from "react";

const SeamLessIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="42"
      viewBox="0 0 40 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6667 3.33337L5 23.3334H20L18.3333 36.6667L35 16.6667H20L21.6667 3.33337Z"
        stroke="#6D1ED4"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="27.3025" cy="30.4763" r="6.98413" fill="#43D991" />
      <g filter="url(#filter0_d_391_2786)">
        <path
          d="M26.0335 31.4923L29.7795 27.7463C29.8959 27.6299 30.0441 27.5717 30.224 27.5717C30.4039 27.5717 30.552 27.6299 30.6684 27.7463C30.7848 27.8627 30.843 28.0108 30.843 28.1907C30.843 28.3706 30.7848 28.5187 30.6684 28.6351L26.478 32.8256C26.351 32.9526 26.2028 33.0161 26.0335 33.0161C25.8642 33.0161 25.7161 32.9526 25.5891 32.8256L23.9383 31.1748C23.8219 31.0584 23.7637 30.9103 23.7637 30.7304C23.7637 30.5505 23.8219 30.4023 23.9383 30.2859C24.0547 30.1695 24.2028 30.1113 24.3827 30.1113C24.5626 30.1113 24.7108 30.1695 24.8272 30.2859L26.0335 31.4923Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_391_2786"
          x="15.6836"
          y="22.8573"
          width="23.2383"
          height="23.2381"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_391_2786"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_391_2786"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SeamLessIcon;
export {};
