import React from "react";

const Step2Icon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.5" y="3" width="59" height="59" rx="29.5" stroke="#6D1ED4" stroke-width="5"/>
    <path d="M19.5 43.7499C19.4999 41.8254 20.0552 39.9416 21.0992 38.3248C22.1432 36.708 23.6316 35.4268 25.3857 34.635C27.1399 33.8431 29.0853 33.5743 30.9884 33.8608C32.8916 34.1473 34.6717 34.9768 36.115 36.2499" stroke="#6D21D4" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.5 33.75C32.9518 33.75 35.75 30.9518 35.75 27.5C35.75 24.0482 32.9518 21.25 29.5 21.25C26.0482 21.25 23.25 24.0482 23.25 27.5C23.25 30.9518 26.0482 33.75 29.5 33.75Z" stroke="#6D21D4" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M40.75 37.5V45" stroke="#6D21D4" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M44.5 41.25H37" stroke="#6D21D4" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
  );
};
export default Step2Icon;
export {};
