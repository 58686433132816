import React from "react";

const PlaystoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 27"
      fill="none"
    >
      <path
        d="M7.5675 5.99991C7.90217 5.95645 8.24154 6.03069 8.5275 6.20991L18.2775 11.7524L15.81 14.2499L7.5675 5.99991ZM6.8175 6.74991C6.77048 6.91319 6.74774 7.0825 6.75 7.25241V22.7474C6.74774 22.9173 6.77048 23.0866 6.8175 23.2499L15.0675 14.9999L6.8175 6.74991ZM15.8175 15.7499L7.5675 23.9999C7.90233 24.0473 8.24304 23.9728 8.5275 23.7899L18.2775 18.2474L15.8175 15.7499ZM22.2075 13.9949L19.2525 12.3149L16.5525 14.9999L19.245 17.6924L22.2 16.0124C23.175 15.4574 23.175 14.5424 22.2 13.9874L22.2075 13.9949Z"
        fill="white"
      />
    </svg>
  );
};
export default PlaystoreIcon;
export {};
