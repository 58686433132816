import React from "react";

const CelebrationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <g clip-path="url(#clip0_198_5030)">
      <path d="M7.25 14.125L2.5 27.5L15.875 22.7625" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 3.75H5.0125" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.5 10H27.5125" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.75 2.5H18.7625" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.5 25H27.5125" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.5 2.5L24.7 3.4375C23.903 3.70299 23.2229 4.23705 22.776 4.9484C22.3292 5.65976 22.1432 6.50424 22.25 7.3375C22.375 8.4125 21.5375 9.375 20.4375 9.375H19.9625C18.8875 9.375 17.9625 10.125 17.7625 11.175L17.5 12.5" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.5 16.25L26.475 15.8375C25.4 15.4125 24.2 16.0875 24 17.225C23.8625 18.1 23.1 18.75 22.2125 18.75H21.25" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.75 2.5L14.1625 3.525C14.5875 4.6 13.9125 5.8 12.775 6C11.9 6.125 11.25 6.9 11.25 7.7875V8.75" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.75 16.25C16.1625 18.6625 17.2875 21.4625 16.25 22.5C15.2125 23.5375 12.4125 22.4125 10 20C7.58752 17.5875 6.46252 14.7875 7.50002 13.75C8.53752 12.7125 11.3375 13.8375 13.75 16.25Z" stroke="#1F1F1F" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_198_5030">
        <rect width="30" height="30" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  );
};

export default CelebrationIcon;
export {};
