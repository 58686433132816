import React from 'react';

const FooterLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return(
    <svg width="275.91" height="auto" viewBox="0 0 1043.91 303.4" xmlns="http://www.w3.org/2000/svg" {...props}>
      <style>
        {`
          .st3 { fill: #FFFFFF; }
          .st4 { fill: #6D1ED4; }
          .st5 { fill: #0B0B0C; }
        `}
      </style>
    <g>
        <path className="st3" d="M335.2,124.81c-5.9,5.15-11.29,12.95-17.79,17.13c-4.59,2.95-11.33-4.28-16.53-5.69
            c-15.25-4.13-25.96,4.08-35.65,14.61c-2.33-5.02-3.8-10.32-6.48-15.21c-1.57-2.87-7.88-9.33-7.07-12.19
            c0.9-3.19,9.03-7.32,11.86-8.96c4.59-2.65,9.5-4.68,14.64-5.98c21.6-5.49,40.09,2.16,56.89,15.24
            C335.11,124.1,335.15,124.46,335.2,124.81z"/>
        <g id="XMLID_00000080908742895601919370000017767850051428950946_">
            <g>
                <path className="st3" d="M261.63,166.81c0.34,19.66,1.04,39.75-0.14,59.37c-8.51-0.87-15.92-3.55-21.62-10.26
                    c-6.3-7.42-6.65-15.45-6.58-24.72c0.1-13.05,1.36-28.42-4.38-40.35c4.31-9.85,9.11-19.09,16.87-24.8
                    C256.52,137.29,261.37,151.45,261.63,166.81z"/>
                <path className="st4" d="M245.77,126.06c-7.76,5.71-12.56,14.96-16.87,24.8c-2.27-4.71-5.62-8.88-10.57-12.15
                    c-13.55-8.97-32.61-6.02-42.83,6.5c-10.29,12.61-9.56,31.64,1.81,43.33c13.05,13.42,39.29,13.29,49.54-3.73
                    c0.31,0.16,0.62,0.33,0.93,0.5c1.48,7.93-0.55,15.77,2.19,23.58c2.53,7.21,4.21,8.18-3.54,11.71
                    c-10.51,4.79-22.31,6.45-33.73,4.58c-23.45-3.84-43.09-22-48.71-45.11c-5.98-24.6,5.65-51.52,27.24-64.54
                    C195.48,100.92,226.35,105.77,245.77,126.06z"/>
            </g>
        </g>
    </g>
    <g>
        <path className="st4" d="M371.89,145.1c2.87-5.33,6.78-9.47,11.73-12.42s10.43-4.43,16.42-4.43c5.41,0,10.13,1.06,14.17,3.18
            c4.04,2.12,7.26,4.77,9.68,7.93v-9.99h14.36v68.79h-14.36v-10.24c-2.42,3.24-5.7,5.95-9.86,8.12c-4.16,2.16-8.91,3.24-14.23,3.24
            c-5.91,0-11.32-1.52-16.23-4.56c-4.91-3.04-8.8-7.28-11.67-12.73c-2.87-5.45-4.31-11.63-4.31-18.54S369.01,150.43,371.89,145.1z
             M420.95,151.35c-1.96-3.49-4.52-6.16-7.68-7.99c-3.16-1.83-6.58-2.75-10.24-2.75c-3.66,0-7.08,0.9-10.24,2.68
            c-3.16,1.79-5.72,4.41-7.68,7.87c-1.96,3.45-2.93,7.55-2.93,12.3c0,4.74,0.98,8.91,2.93,12.48c1.95,3.58,4.54,6.3,7.74,8.18
            c3.2,1.87,6.6,2.81,10.17,2.81c3.66,0,7.07-0.91,10.24-2.75c3.16-1.83,5.72-4.51,7.68-8.05c1.95-3.54,2.93-7.68,2.93-12.42
            C423.88,158.96,422.9,154.84,420.95,151.35z"/>
        <path className="st4" d="M481.06,131.5c4.16-2.16,8.86-3.24,14.11-3.24c5.99,0,11.46,1.48,16.42,4.43c4.95,2.95,8.84,7.1,11.67,12.42
            c2.83,5.33,4.24,11.44,4.24,18.35s-1.42,13.09-4.24,18.54c-2.83,5.45-6.72,9.7-11.67,12.73c-4.95,3.04-10.42,4.56-16.42,4.56
            c-5.24,0-9.91-1.06-13.98-3.18c-4.08-2.12-7.41-4.76-9.99-7.93v42.69h-14.23V129.37h14.23v10.11
            C473.61,136.32,476.89,133.66,481.06,131.5z M510.08,151.16c-1.96-3.45-4.54-6.08-7.74-7.87c-3.2-1.79-6.64-2.68-10.3-2.68
            c-3.58,0-6.97,0.92-10.18,2.75c-3.2,1.83-5.78,4.49-7.74,7.99c-1.96,3.49-2.93,7.62-2.93,12.36c0,4.74,0.98,8.88,2.93,12.42
            c1.95,3.54,4.54,6.22,7.74,8.05c3.2,1.83,6.6,2.75,10.18,2.75c3.66,0,7.09-0.93,10.3-2.81c3.2-1.87,5.78-4.6,7.74-8.18
            c1.95-3.58,2.93-7.74,2.93-12.48C513.02,158.71,512.04,154.61,510.08,151.16z"/>
        <path className="st4" d="M558.21,140.98v38.08c0,2.58,0.6,4.43,1.81,5.56c1.21,1.12,3.27,1.68,6.18,1.68h8.74v11.86H563.7
            c-6.41,0-11.32-1.5-14.73-4.49c-3.41-2.99-5.12-7.87-5.12-14.61v-38.08h-8.12v-11.61h8.12v-17.1h14.36v17.1h16.73v11.61H558.21z"/>
        <path className="st3" d="M610.58,131.18c3.45-1.95,7.55-2.93,12.3-2.93v14.73h-3.62c-5.58,0-9.8,1.41-12.67,4.24
            c-2.87,2.83-4.31,7.74-4.31,14.73v36.2h-14.23v-68.79h14.23v9.99C604.35,135.86,607.12,133.14,610.58,131.18z"/>
        <path className="st3" d="M698.52,169.07h-52.56c0.42,5.49,2.45,9.91,6.12,13.23c3.66,3.33,8.16,4.99,13.48,4.99
            c7.66,0,13.07-3.2,16.23-9.61h15.36c-2.08,6.33-5.85,11.51-11.3,15.54c-5.45,4.04-12.21,6.06-20.29,6.06
            c-6.58,0-12.46-1.48-17.66-4.43c-5.2-2.95-9.28-7.12-12.24-12.48c-2.95-5.37-4.43-11.59-4.43-18.66c0-7.07,1.44-13.3,4.31-18.66
            c2.87-5.37,6.91-9.51,12.11-12.42c5.2-2.91,11.17-4.37,17.91-4.37c6.49,0,12.28,1.42,17.35,4.24c5.08,2.83,9.03,6.8,11.86,11.92
            c2.83,5.12,4.24,11.01,4.24,17.67C699.02,164.66,698.85,166.99,698.52,169.07z M684.17,157.59c-0.08-5.24-1.96-9.44-5.62-12.61
            c-3.66-3.16-8.2-4.74-13.61-4.74c-4.91,0-9.11,1.56-12.61,4.68c-3.5,3.12-5.58,7.35-6.24,12.67H684.17z"/>
        <path className="st3" d="M810.81,131.62c4.29,2.25,7.66,5.58,10.11,9.99c2.45,4.41,3.68,9.74,3.68,15.98v40.57H810.5v-38.45
            c0-6.16-1.54-10.88-4.62-14.17c-3.08-3.29-7.28-4.93-12.61-4.93c-5.33,0-9.55,1.64-12.67,4.93c-3.12,3.29-4.68,8.01-4.68,14.17
            v38.45h-14.11v-38.45c0-6.16-1.54-10.88-4.62-14.17c-3.08-3.29-7.28-4.93-12.61-4.93s-9.55,1.64-12.67,4.93
            c-3.12,3.29-4.68,8.01-4.68,14.17v38.45H713v-68.79h14.23v7.86c2.33-2.83,5.28-5.03,8.86-6.62c3.58-1.58,7.41-2.37,11.49-2.37
            c5.49,0,10.4,1.17,14.73,3.49c4.33,2.33,7.66,5.7,9.99,10.11c2.08-4.16,5.33-7.47,9.74-9.93c4.41-2.45,9.16-3.68,14.23-3.68
            C801.68,128.25,806.53,129.37,810.81,131.62z"/>
        <path className="st3" d="M843.46,117.64c-1.75-1.75-2.62-3.91-2.62-6.49c0-2.58,0.87-4.74,2.62-6.49c1.75-1.75,3.91-2.62,6.49-2.62
            c2.5,0,4.62,0.87,6.37,2.62c1.75,1.75,2.62,3.91,2.62,6.49c0,2.58-0.87,4.74-2.62,6.49c-1.75,1.75-3.87,2.62-6.37,2.62
            C847.37,120.26,845.21,119.39,843.46,117.64z M856.94,129.37v68.79h-14.23v-68.79H856.94z"/>
        <path className="st3" d="M892.27,140.98v38.08c0,2.58,0.6,4.43,1.81,5.56c1.21,1.12,3.27,1.68,6.18,1.68H909v11.86h-11.23
            c-6.41,0-11.32-1.5-14.73-4.49c-3.41-2.99-5.12-7.87-5.12-14.61v-38.08h-8.12v-11.61h8.12v-17.1h14.36v17.1H909v11.61H892.27z"/>
    </g>
    </svg>
    );
};

export default FooterLogo;
