import React from "react";
import { Grid, Typography, useMediaQuery, useTheme as T } from "@mui/material";
import SmilyIcon from "../assets/icons/SmilyIcon";
import CelebrationIcon from "../assets/icons/CelebrationIcon";
import SendIcon from "../assets/icons/SendIcon";

const RedeemBox: React.FC = () => {
  const t = T();
  const isXsScreen = useMediaQuery(t.breakpoints.down("sm"));
  // const isSmScreen = useMediaQuery('(max-width:1200px)');
  const isSmScreen = useMediaQuery(t.breakpoints.down("lg"));

  return (
    <Grid container display="flex" mt={3} mb={10}>
      <Grid item lg={12}>
        {isXsScreen ? (
          <label style={{ fontWeight: 600, fontSize: "35px" }}>
            Remit. Redeem. Rejoice.
          </label>
        ) : (
          <div
            style={{
              borderRadius: "20px",
              border: "1px solid var(--Pallet-1-Grey, #DEE1E6)",
              background: "#FFF",
              padding: "20px",
            }}
          >
            <Grid
              item
              container
              paddingLeft={0}
              flexDirection="row"
              spacing={5}
              justifyContent="center"
              alignItems="center"
              pt={1}
              pb={1}
            >
              <Grid
                item
                lg={3}
                sm={12}
                style={{ textAlign: isSmScreen ? "center" : "left" }}
              >
                <Typography variant="h5" style={{ fontWeight: 600, fontFamily:"Inter" }}>
                  Remit. Redeem. Rejoice.
                </Typography>
              </Grid>

              <Grid
                item
                lg={2.8}
                sm={4}
                xs={10}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="10px"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#DEE1E6",
                    alignItems: "center",
                  }}
                >
                  <SendIcon style={{ width: "30px", height: "30px" }} />
                </div>
                <label style={{ paddingLeft: 1, fontWeight: 400 }}>
                  {" "}
                  Effortlessly Send Money{" "}
                </label>
              </Grid>
              <Grid
                item
                lg={2.9}
                sm={4}
                xs={10}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="10px"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#DEE1E6",
                    alignItems: "center",
                  }}
                >
                  <CelebrationIcon style={{ width: "30px", height: "30px" }} />
                </div>
                <label style={{ paddingLeft: 1, fontWeight: 400 }}>
                  {" "}
                  Gain exclusive benefits & perks
                </label>
              </Grid>
              <Grid
                item
                lg={2.9}
                sm={4}
                xs={10}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="10px"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#DEE1E6",
                    alignItems: "center",
                  }}
                >
                  <SmilyIcon style={{ width: "10px", height: "10px" }} />
                </div>
                <label style={{ paddingLeft: 1, fontWeight: 400 }}>
                  {" "}
                  Enjoy seamless remit experience{" "}
                </label>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default RedeemBox;
