import React from "react";

const Step1Icon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.5" y="2.5" width="60" height="60" rx="30" stroke="#6D1ED4" stroke-width="4"/>
    <path d="M33.917 22.5834H42.417V31.0834" stroke="#6D1ED4" stroke-width="2.83333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M42.4163 22.5834L22.583 42.4167" stroke="#6D1ED4" stroke-width="2.83333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
  );
};
export default Step1Icon;
export {};
