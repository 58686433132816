
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate  } from 'react-router-dom';
import { Grid,Card, CardContent, TextField, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '../assets/themeContext';



const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email Required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password Required'),
});

const defaultEmail = 'vamsi@gmail.com';
const defaultPassword = '123456';


  



const HomeScreen: React.FC = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.email === defaultEmail && values.password === defaultPassword) {
        navigate('/navbar');
      } else {
        formik.setFieldError('password', 'Incorrect email or password');
      }
    },
  });


  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


return (




  <Grid  item  lg={4} xs={10} sm={10} style={{  display: 'flex', justifyContent: 'center', marginTop: '50px', marginLeft: '12px' }}>
    
    <Grid  item lg={9} xs={10} sm={8} >
      <Card sx={{ backgroundColor: '#f2f2f2',paddingTop: '15px', boxShadow: '0px 9px 30px 0px rgba(214, 182, 255, 1)', width: '100%', height: '100%' }}>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
          <Grid>
          <div style={{ backgroundColor: '#fff', marginTop: '12px', height: '110px', borderRadius: 5 }}>
           
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              
              <Grid item xs={10} sm={10} lg={10}>
                {/* <label>Base Currency</label> */}
                <TextField 
                   fullWidth
                   id="email"
                   name="email"
                   label="Email"
                   variant="outlined"
                   value={formik.values.email}
                   onChange={formik.handleChange}
                   error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email} 
                />
              </Grid>
            </Grid>
          </div>

          <div style={{ backgroundColor: '#fff', marginTop: '30px', height: '110px', borderRadius: 5 }}>
           
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              
              <Grid item xs={10} sm={10} lg={10}>
                {/* <label>Base Currency</label> */}
                <TextField 
                  
                  fullWidth
                   id="password"
                   name="password"
                   label="password"
                   variant="outlined"
                   type={showPassword ? 'text' : 'password'}
                   value={formik.values.password}
                   onChange={formik.handleChange}
                   error={formik.touched.password && Boolean(formik.errors.password)}
                   helperText={formik.touched.password && formik.errors.password}
                   InputProps={{
 
                     endAdornment: (
                         <Button onClick={handleTogglePasswordVisibility}>
                             {showPassword ? <VisibilityOff /> : <Visibility />}
                         </Button>
                       ),
                      }} 
                  //  id="password"
                  //  name="password"
                  //  label="Password"
                  //  variant="outlined"
                  //  type={showPassword ? 'text' : 'password'}
                  //  value={formik.values.password}
                  //  onChange={formik.handleChange}
                  //  error={formik.touched.password && Boolean(formik.errors.password)}
                  //  helperText={formik.touched.password && formik.errors.password}

                  //  InputProps={{
 
                  //  endAdornment: (
                  //      <Button onClick={handleTogglePasswordVisibility}>
                  //          {showPassword ? <VisibilityOff /> : <Visibility />}
                  //      </Button>
                  //    ),
                  //   }}
                 />
              </Grid>
            </Grid>
          </div>

          
          <Grid sx={{ display: 'flex', marginTop: '20px' ,justifyContent: 'center', borderRadius: '9px' }} >
            {/* <Button style={{ backgroundColor: theme.palette.primary.main }} color="primary" variant="contained" > Transfer Now </Button> */}
            <Button
                 color="primary"
                 variant="contained"
                 type="submit"
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    marginTop: '7px',
                    padding: '14px',
                    width: '60%'
                }}
              >
                Login
              </Button>
              </Grid>
           
          </Grid>
           </form>  
        </CardContent>
      </Card>
    </Grid>
  </Grid>


);
};

export default HomeScreen;

















// import React, { useContext,useState } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { useNavigate  } from 'react-router-dom';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import { Card, CardContent } from '@mui/material';
// import { Grid } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { useTheme } from '../assets/themeContext';


// const validationSchema = Yup.object().shape({
//   email: Yup.string().email('Invalid email').required('Email Required'),
//   password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password Required'),
// });

// const defaultEmail = 'vamsi@gmail.com';
// const defaultPassword = '123456';


// const LoginPage: React.FC = ({ }) => {
//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const [showPassword, setShowPassword] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: '',
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       if (values.email === defaultEmail && values.password === defaultPassword) {
//         navigate('/navbar');
//       } else {
//         formik.setFieldError('password', 'Incorrect email or password');
//       }
//     },
//   });

 
//   const handleForgotPassword = () => {
//     // Handle forgot password logic here
//     console.log("Forgot Password clicked");
//   };


//   const handleTogglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     // <Grid container item  xs={12}  className={classes.container}>
//     <div>
//     <Grid item xs={12} style={{ backgroundColor: theme.palette.primary.main }} 
//     sx = {{fontFamily: 'Arial, sans-serif', 
//             backgroundColor: '#ffffff', 
//             display: 'flex',
//             padding: '80px',
//             alignItems: 'center',
//             textAlign: 'center',
//             justifyContent: 'center',
//             height: '100vh',}}  >
//     <Card style={{padding: '0px'}}>
//       <CardContent>
//         <h2 style={{ textAlign: 'center', }}>Login</h2>
//         <form onSubmit={formik.handleSubmit}>
//           <Grid container spacing={2} 
//                sx={{alignItems: 'center',
//                     textAlign: 'center',
//                     justifyContent: 'center'  }}>
//             <Grid item xs={10} >
//               <TextField
//                 fullWidth
//                 id="email"
//                 name="email"
//                 label="Email"
//                 variant="outlined"
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 error={formik.touched.email && Boolean(formik.errors.email)}
//                 helperText={formik.touched.email && formik.errors.email}
//               />
//             </Grid>
//             <Grid item xs={10}>
//               <TextField
//                 fullWidth
//                 id="password"
//                 name="password"
//                 label="Password"
//                 variant="outlined"
//                 type={showPassword ? 'text' : 'password'}
//                 value={formik.values.password}
//                 onChange={formik.handleChange}
//                 error={formik.touched.password && Boolean(formik.errors.password)}
//                 helperText={formik.touched.password && formik.errors.password}
//                 InputProps={{
//                   endAdornment: (
//                     <Button onClick={handleTogglePasswordVisibility}>
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </Button>
//                   ),
//                 }}
//               />
//             </Grid>
            
//             <Grid item xs={10}>
//               <Button
//                 color="primary"
//                 variant="contained"
//                 fullWidth
//                 type="submit"
//                 style={{
//                   backgroundColor: 'green',
//                   color: 'white',
//                   marginTop: '7px',
//                   padding: '14px'
//                 }}
//               >
//                 Login
//               </Button>
//             </Grid>
            
//           </Grid>
//         </form>
//         <Grid item >
//               <div style={{textAlign: 'left', 
//                            paddingLeft: '50px',
//                            marginTop: '8px',
//                            color: '#007bff',
//                            cursor: 'pointer',
//                            textDecoration: 'underline',
//                          }} onClick={handleForgotPassword}>
//                 Forgot Password?
//               </div>
//             </Grid>
//       </CardContent>
      
//     </Card>
//     </Grid>
//     </div>
//   );
// };

// export default LoginPage;




// import React, { useState } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import { Card, CardContent } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { Grid } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// //import { StackNavigationProp } from '@react-navigation/stack';

// // interface Props {
// //   navigation: StackNavigationProp<any>;
// // }

// const validationSchema = Yup.object().shape({
//   email: Yup.string().email('Invalid email').required('Required'),
//   password: Yup.string().required('Required'),
// });

// const defaultEmail = 'vamsi@gmail.com';
// const defaultPassword = '123';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//      width: '70%',
//     //margin: 'auto',
//     alignItems: 'center',
//     textAlign: 'center',
//     justifyContent: 'center'
//   },
//   root1: {
//     alignItems: 'center',
//     textAlign: 'center',
//     justifyContent: 'center'
//   },
//   head:{
//     textAlign: 'center',
//   }
// }));

// const LoginPage: React.FC = ({ }) => {
  
//   const classes = useStyles();
//   const [showPassword, setShowPassword] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: '',
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       if (values.email === defaultEmail && values.password === defaultPassword) {
//         //alert('Login successful'); // You can navigate to the home page here
//         //navigation.navigate('Grid');
//       } else {
//         alert('Invalid email or password');
//       }
//     },
//   });

//   const handleTogglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <Grid container xs={10} className={classes.root}>
//     <Card  >
//       <CardContent>
//         <h2 className={classes.head}>Login</h2>
//         <form onSubmit={formik.handleSubmit}>
//           <Grid container spacing={2} className={classes.root1}>
//             <Grid item xs={10}>
//               <TextField
//                 fullWidth
//                 id="email"
//                 name="email"
//                 label="Email"
//                 variant="outlined"
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 error={formik.touched.email && Boolean(formik.errors.email)}
//                 helperText={formik.touched.email && formik.errors.email}
//               />
//             </Grid>
//             <Grid item xs={10}>
//               <TextField
//                 fullWidth
//                 id="password"
//                 name="password"
//                 label="Password"
//                 variant="outlined"
//                 type={showPassword ? 'text' : 'password'}
//                 value={formik.values.password}
//                 onChange={formik.handleChange}
//                 error={formik.touched.password && Boolean(formik.errors.password)}
//                 helperText={formik.touched.password && formik.errors.password}
//                 InputProps={{
//                   endAdornment: (
//                     <Button onClick={handleTogglePasswordVisibility}>
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </Button>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={10}>
//               <Button
//                 color="primary"
//                 variant="contained"
//                 fullWidth
//                 type="submit"
//                 style={{
//                   backgroundColor: 'green',
//                   color: 'white',
//                   marginTop: '18px',
//                   padding: '14px'
//                 }}
//               >
//                 Login
//               </Button>
//             </Grid>
//           </Grid>
//         </form>
//       </CardContent>
//     </Card>
//     </Grid>
//   );
// };

// export default LoginPage;