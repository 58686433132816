import React from 'react';

const AppstoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return(
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
  <g clip-path="url(#clip0_141_37385)">
    <path d="M14.1278 11.0994C14.1278 9.36881 15.0596 7.85914 16.442 7.05959C15.7201 6.16047 14.6343 5.3547 13.4374 5.11825C11.8052 4.79547 10.8741 5.33292 10.2718 5.54836C9.66954 5.76381 8.86954 5.95747 8.86954 5.95747C8.86954 5.95747 8.07031 5.76381 7.46724 5.54836C6.86571 5.33292 5.93467 4.7947 4.30249 5.11748C2.67031 5.44025 1.24502 6.82236 0.665709 8.05436C-0.451533 10.432 0.00823741 13.4234 0.797509 15.5747C1.58602 17.7268 3.67107 20.7337 5.28793 20.9756C6.43812 21.1475 7.15 20.3409 8.86954 20.1364C10.5891 20.3409 11.301 21.1475 12.4511 20.9756C14.068 20.7337 16.1531 17.7268 16.9416 15.5747C16.9607 15.5218 16.9806 15.4674 16.9998 15.4129C15.3163 14.7269 14.1278 13.0539 14.1278 11.0994ZM8.46111 4.7247C8.46111 4.7247 10.0603 4.94014 11.5125 3.26636C12.9646 1.59259 12.5515 0.00903024 12.5515 0.00903024C12.5515 0.00903024 10.9523 -0.206414 9.50019 1.46736C8.04808 3.14114 8.46111 4.7247 8.46111 4.7247Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_141_37385">
      <rect width="17" height="21" fill="white"/>
    </clipPath>
  </defs>
</svg>
);
};
export default AppstoreIcon;
export {};