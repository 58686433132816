import React, { ReactNode, useEffect } from 'react';

interface SmoothScrollProps {
  children: ReactNode; 
}

const SmoothScroll: React.FC<SmoothScrollProps> = ({ children }) => {
  useEffect(() => {
    const handleSmoothScroll = (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      if (target.hash) {
        const scrollToElement = document.querySelector(target.hash);
        if (scrollToElement) {
          e.preventDefault();
          window.scrollTo({
            top: scrollToElement.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth',
          });
        }
      }
    };

    // Attach the event listener for clicks
    document.addEventListener('click', handleSmoothScroll);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('click', handleSmoothScroll);
    };
  }, []);

  return <>{children}</>;
};

export default SmoothScroll;
