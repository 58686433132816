// src/components/ThemeSwitch.tsx
import React, { useContext, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ThemeContext from '../assets/themeContext';
import { Grid } from '@mui/material';
import { useTheme } from '../assets/themeContext';

const ThemeSwitch: React.FC = () => {
  const { theme } = useTheme();
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    // Handle the case where ThemeContext is not available
    return null; // or render an appropriate fallback
  }
  const { toggleTheme, isDarkMode } = themeContext;

  return (
    <>
        {/* <FormControlLabel
          control={<Switch checked={isDarkMode} onChange={toggleTheme} />}
          label="Dark Mode"
        /> */}
    </>
  );
};

export default ThemeSwitch;
