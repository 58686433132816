import React from 'react';

const MobileArrowDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return (
        <svg 
            width="12" 
            height="8" 
            viewBox="0 0 12 8" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M10.6667 1.66675L6.00002 6.33341L1.33335 1.66675" 
                stroke="#CDA7FE" 
                stroke-width="2" 
                stroke-linecap="round" 
                stroke-linejoin="round"
            />
        </svg>
    );
}
export default MobileArrowDownIcon
export{};