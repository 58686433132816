import React from "react";

const SecureIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.619" cy="19.619" r="6.98413" fill="#43D991" />
      <path
        d="M18.3499 20.635L22.096 16.889C22.2124 16.7726 22.3605 16.7144 22.5404 16.7144C22.7203 16.7144 22.8684 16.7726 22.9848 16.889C23.1012 17.0054 23.1594 17.1535 23.1594 17.3334C23.1594 17.5133 23.1012 17.6614 22.9848 17.7778L18.7944 21.9683C18.6674 22.0953 18.5192 22.1588 18.3499 22.1588C18.1806 22.1588 18.0325 22.0953 17.9055 21.9683L16.2547 20.3175C16.1383 20.2011 16.0801 20.053 16.0801 19.8731C16.0801 19.6932 16.1383 19.545 16.2547 19.4286C16.3711 19.3122 16.5192 19.254 16.6991 19.254C16.879 19.254 17.0272 19.3122 17.1436 19.4286L18.3499 20.635Z"
        fill="white"
      />
      <path
        d="M33.3333 21.6667C33.3333 30.0001 27.5 34.1667 20.5666 36.5834C20.2036 36.7064 19.8092 36.7005 19.45 36.5667C12.5 34.1667 6.66663 30.0001 6.66663 21.6667V10.0001C6.66663 9.55803 6.84222 9.13411 7.15478 8.82155C7.46734 8.50899 7.89127 8.33339 8.33329 8.33339C11.6666 8.33339 15.8333 6.33339 18.7333 3.80006C19.0864 3.49839 19.5355 3.33264 20 3.33264C20.4644 3.33264 20.9135 3.49839 21.2666 3.80006C24.1833 6.35006 28.3333 8.33339 31.6666 8.33339C32.1087 8.33339 32.5326 8.50899 32.8451 8.82155C33.1577 9.13411 33.3333 9.55803 33.3333 10.0001V21.6667Z"
        stroke="#6D1ED4"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SecureIcon;
export {};
