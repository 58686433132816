interface Image {
  src: string;
  alt: string;
}
const axisLogo: Image = {
    src: require('../assets/banks/AXISBANK.png'),
    alt: "Axis Bank Logo", 
};
const iciciLogo: Image = {
    src: require('../assets/banks/Icici.png'),
    alt: "ICICI Bank Logo", 
};
const buckzyLogo:Image={
    src: require('../assets/banks/Buckzy-Logo.png'),
    alt: "Buckzy Logo", 
}
const masterLogo:Image={
    src: require('../assets/banks/mastercard-logo.png'),
    alt: "masterCard Logo", 
}
const sbiLogo:Image={
    src: require('../assets/banks/sbi-logo.png'),
    alt: "sbi Logo", 
}
const visaLogo:Image={
    src: require('../assets/banks/visa-logo.png'),
    alt: "visa Logo", 
}
const pnbLogo:Image={
    src: require('../assets/banks/pnb-logo.png'),
    alt: "pnb Logo", 
}
const indianFlag:Image={
    src: require('../assets/banks/india-flag.png'),
    alt: "india flag", 
}
const usaFlag:Image={
    src: require('../assets/banks/usa-flag.png'),
    alt: "usa flag", 
}
const Referral:Image={
    src: require('../assets/banks/Highfive.png'),
    alt: "high five", 
}
const EarlyBird:Image={
    src: require('../assets/banks/happycuate.png'),
    alt: "EarlyBird", 
}
const SpreadSmile:Image={
    src: require('../assets/banks/Nerdcuate.png'),
    alt: "SpreadSmile", 
}
const StarEyes:Image={
    src: require('../assets/banks/StarEyes.png'),
    alt: "SpreadSmile", 
}

const PageNotFound:Image={
    src:require('../assets/banks/pageNotFound.png'),
    alt: "PageNotFound",
}

const images: { [key: string]: Image } = {
axisLogo,
iciciLogo,
buckzyLogo,
masterLogo,
sbiLogo,
visaLogo,
pnbLogo,
indianFlag,
usaFlag,
Referral,
EarlyBird,
SpreadSmile,
PageNotFound,
StarEyes
};

export default images;
