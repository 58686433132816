import React from 'react'

const PNBbank: React.FC<React.SVGProps<SVGSVGElement>>=()=>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" width="120" height="30" viewBox="0 0 154 30" fill="none">
  <path d="M153.134 0H-0.00634766V30H153.134V0Z" fill="#A20E37"/>
  <path d="M66.0743 22.2803H66.7892L66.6217 23.034H65.9487L66.0743 22.2803Z" fill="white"/>
  <path d="M67.3754 22.2803H68.0454L67.9198 23.034H67.2079L67.3754 22.2803Z" fill="white"/>
  <path d="M68.6348 22.2803H69.3467L69.2211 23.034H68.5092L68.6348 22.2803Z" fill="white"/>
  <path d="M70.941 23.0339C70.8153 23.0339 70.6897 23.0339 70.5222 23.0339C70.271 23.0339 70.1453 22.992 70.0616 22.9082C69.9778 22.8245 69.9778 22.657 70.0197 22.4476L70.3128 20.7697H69.9778L70.0616 20.2672H70.3966L70.5222 19.5134H71.1503L71.0247 20.2672H71.4016L71.3178 20.7697H70.941L70.6478 22.322L70.6897 22.4476C70.7316 22.4895 70.7735 22.4895 70.8572 22.4895H71.0247L70.941 23.0339Z" fill="white"/>
  <path d="M71.3626 23.0338L72.0326 19.301H72.3676H72.7026L72.4514 20.6021L72.4932 20.5184C72.7026 20.2671 72.9539 20.1834 73.247 20.1834C73.4982 20.1834 73.7076 20.2671 73.8332 20.4346C73.9589 20.6021 74.0007 20.8534 73.917 21.1465L73.582 23.0338H73.247H72.912L73.2051 21.314C73.247 21.1465 73.247 21.0209 73.1632 20.9371C73.1214 20.8534 73.0376 20.8115 72.8701 20.8115C72.7026 20.8115 72.577 20.8534 72.4514 20.979C72.3258 21.1046 72.242 21.2302 72.242 21.4396L71.9489 23.0338H71.6139H71.3626Z" fill="white"/>
  <path d="M75.179 23.1175C74.844 23.1175 74.5928 23.0338 74.4253 22.8663C74.2578 22.6988 74.174 22.4476 74.174 22.1126C74.174 22.0288 74.174 21.9869 74.174 21.9032C74.174 21.8194 74.174 21.7357 74.2159 21.6519C74.2997 21.1913 74.4672 20.8533 74.7184 20.5602C74.9697 20.309 75.2628 20.1833 75.6426 20.1833C75.9358 20.1833 76.187 20.2671 76.3545 20.4346C76.522 20.6021 76.6058 20.8533 76.6058 21.1883C76.6058 21.2721 76.6058 21.3558 76.6058 21.4396C76.6058 21.5233 76.5639 21.649 76.5639 21.7327V21.8164H74.8859V21.8583V21.9839C74.8859 22.1514 74.9278 22.2771 75.0115 22.3608C75.0953 22.4446 75.2209 22.4864 75.3465 22.4864C75.4721 22.4864 75.5559 22.4446 75.6396 22.4027C75.7234 22.3608 75.8071 22.2771 75.849 22.1933H76.519C76.3934 22.4446 76.2259 22.6539 76.0165 22.8214C75.7234 23.0338 75.4721 23.1175 75.179 23.1175ZM75.9776 21.3558V21.2721C75.9776 21.1046 75.9358 20.979 75.8939 20.9371C75.8101 20.8533 75.7264 20.8115 75.6008 20.8115C75.4333 20.8115 75.2658 20.8952 75.182 21.0208C75.0983 21.1046 75.0564 21.2302 75.0145 21.3558H75.9776Z" fill="white"/>
  <path d="M78.0326 23.0339L78.5351 20.2642H78.8701H79.2051L79.1213 20.641L79.2051 20.5154C79.4144 20.2642 79.6657 20.1804 79.9588 20.1804C80.2101 20.1804 80.4194 20.2642 80.545 20.4317C80.6707 20.5992 80.7125 20.8504 80.6288 21.1435L80.2938 23.0309H79.9588H79.6238L79.9169 21.311C79.9588 21.1435 79.9588 21.0179 79.8751 20.9342C79.8332 20.8504 79.7494 20.8085 79.5819 20.8085C79.4144 20.8085 79.2888 20.8504 79.1632 20.976C79.0376 21.1017 78.9538 21.2273 78.9538 21.4367L78.6607 23.0309H78.3257L78.0326 23.0339Z" fill="white"/>
  <path d="M81.4721 23.1176C81.2627 23.1176 81.0952 23.0757 81.0115 22.9501C80.8858 22.8245 80.844 22.6989 80.844 22.4895V22.4057V22.2801C80.8858 22.0708 80.9696 21.8614 81.1371 21.6939C81.3046 21.5264 81.4721 21.4426 81.7233 21.4008L82.2677 21.317L82.3933 21.2751L82.5189 21.1495L82.5608 20.982L82.5189 20.8145C82.4771 20.7727 82.3514 20.7308 82.2258 20.7308C82.1002 20.7308 82.0165 20.7727 81.9327 20.8145C81.849 20.8983 81.8071 20.982 81.7652 21.1076H81.1371C81.2208 20.7726 81.3465 20.5633 81.5558 20.3958C81.7652 20.2283 82.0583 20.1445 82.3963 20.1445C82.7313 20.1445 82.9407 20.2283 83.0663 20.3539C83.1919 20.4377 83.2338 20.6052 83.2338 20.7726V20.982L82.9407 22.4925C82.8988 22.6181 82.8988 22.7019 82.9407 22.7856L83.0244 22.9112V22.995H82.6894H82.3544C82.3544 22.8694 82.3544 22.7856 82.3544 22.66C82.0613 22.9501 81.7652 23.1176 81.4721 23.1176ZM82.4382 22.0289L82.4801 21.6939L82.2707 21.7776L82.0194 21.8195C81.8519 21.8614 81.7263 21.9033 81.6426 21.987C81.5588 22.0289 81.517 22.1126 81.517 22.2382V22.2801V22.322C81.517 22.4057 81.5588 22.4476 81.6007 22.4895C81.6426 22.5314 81.7263 22.5314 81.7682 22.5314C81.9776 22.5314 82.1451 22.4476 82.3126 22.2382L82.3963 22.1126L82.4382 22.0289Z" fill="white"/>
  <path d="M83.4433 23.034L83.9458 20.2643H84.2808H84.6158L84.532 20.6412L84.6158 20.5155C84.7833 20.3062 85.0345 20.1805 85.2858 20.1805C85.537 20.1805 85.7045 20.2643 85.7883 20.3899C85.8301 20.4318 85.872 20.5155 85.872 20.5993L85.9558 20.4737C86.1233 20.2643 86.3326 20.1387 86.6258 20.1387C86.877 20.1387 87.0864 20.2224 87.212 20.3899C87.2958 20.5155 87.3376 20.6412 87.3376 20.8087C87.3376 20.8924 87.3376 20.9762 87.2958 21.0599L86.9608 22.9472H86.6258H86.2908L86.6258 21.1437C86.6676 20.9762 86.6676 20.8924 86.6258 20.8087C86.5839 20.7249 86.5001 20.683 86.3745 20.683C86.207 20.683 86.1233 20.7249 85.9976 20.8087C85.9139 20.8924 85.8301 21.0599 85.7883 21.2274L85.4951 22.9054H85.1602H84.8252L85.1602 21.1437C85.202 20.9762 85.1602 20.8924 85.1183 20.8087C85.0764 20.7249 84.9927 20.683 84.867 20.683C84.7414 20.683 84.6158 20.7249 84.532 20.8087C84.4483 20.8924 84.3645 21.0599 84.3645 21.2274L84.0714 22.9054H83.7364H83.4433V23.034Z" fill="white"/>
  <path d="M88.5639 23.1175C88.2289 23.1175 87.9776 23.0338 87.8101 22.8663C87.6426 22.6988 87.5589 22.4476 87.5589 22.1126C87.5589 22.0288 87.5589 21.9869 87.5589 21.9032C87.5589 21.8194 87.5589 21.7357 87.6008 21.6519C87.6845 21.1913 87.852 20.8533 88.1032 20.5602C88.3545 20.309 88.6476 20.1833 89.0275 20.1833C89.3206 20.1833 89.5718 20.2671 89.7393 20.4346C89.9068 20.6021 89.9906 20.8533 89.9906 21.1883C89.9906 21.2721 89.9906 21.3558 89.9906 21.4396C89.9906 21.5233 89.9487 21.649 89.9487 21.7327V21.8164H88.2707V21.8583V21.9839C88.2707 22.1514 88.3126 22.2771 88.3964 22.3608C88.4801 22.4446 88.6057 22.4864 88.7314 22.4864C88.857 22.4864 88.9407 22.4446 89.0245 22.4027C89.1082 22.3608 89.192 22.2771 89.2339 22.1933H89.9038C89.7782 22.4446 89.6107 22.6539 89.4014 22.8214C89.1082 23.0338 88.857 23.1175 88.5639 23.1175ZM89.3595 21.3558V21.2721C89.3595 21.1046 89.3176 20.979 89.2757 20.9371C89.192 20.8533 89.1082 20.8115 88.9826 20.8115C88.8151 20.8115 88.6476 20.8952 88.5639 21.0208C88.4801 21.1046 88.4382 21.2302 88.3964 21.3558H89.3595Z" fill="white"/>
  <path d="M94.1422 20.2642L92.7992 22.992C92.548 23.4945 92.3805 23.8325 92.213 23.9581C92.0873 24.0838 91.878 24.1675 91.6267 24.1675C91.5011 24.1675 91.4173 24.1675 91.3336 24.1675L91.4592 23.5813H91.5848C91.7523 23.5813 91.878 23.5394 91.9617 23.4556C92.0036 23.4138 92.0455 23.2882 92.1292 23.1207L91.7523 20.2253H92.1292H92.5061L92.6736 22.2802L93.5559 20.2253H93.8909L94.1422 20.2642Z" fill="white"/>
  <path d="M95.1083 23.1177C94.7733 23.1177 94.5221 23.0339 94.3546 22.8245C94.1871 22.6152 94.0614 22.3639 94.0614 22.0708V21.8614L94.1033 21.6521C94.1871 21.1914 94.3546 20.8535 94.6477 20.6022C94.9408 20.351 95.2339 20.2253 95.6557 20.2253C95.9907 20.2253 96.2419 20.3091 96.4094 20.5185C96.5769 20.7278 96.7025 20.9791 96.7025 21.3171V21.5264L96.6607 21.7358C96.5769 22.1964 96.4094 22.5344 96.1163 22.7857C95.8232 22.992 95.4852 23.1177 95.1083 23.1177ZM95.2339 22.4895C95.4433 22.4895 95.5689 22.4058 95.6945 22.2802C95.8202 22.1546 95.9039 21.9033 95.9458 21.6102V21.4846V21.3589C95.9458 21.1914 95.9039 21.0658 95.8202 20.9821C95.7364 20.8983 95.6108 20.8564 95.4852 20.8564C95.2758 20.8564 95.1502 20.9402 95.0246 21.0658C94.8989 21.2333 94.8152 21.4427 94.7733 21.7358V21.8614V21.9871C94.7733 22.1545 94.8152 22.2802 94.8989 22.3639C94.9408 22.4477 95.0664 22.4895 95.2339 22.4895Z" fill="white"/>
  <path d="M98.0036 20.2642L97.6686 21.9841C97.6267 22.1516 97.6268 22.2772 97.7105 22.3609C97.7524 22.4447 97.8361 22.4866 98.0036 22.4866C98.1711 22.4866 98.2967 22.4447 98.4224 22.3191C98.548 22.1934 98.6317 22.0678 98.6317 21.8584L98.9249 20.2642H99.2599H99.5948L99.0924 23.0339H98.7992H98.5061L98.5899 22.6152L98.5061 22.7408C98.2967 22.992 98.0455 23.0758 97.7524 23.0758C97.5011 23.0758 97.2918 22.992 97.1661 22.7827C97.0824 22.657 97.0405 22.4895 97.0405 22.322C97.0405 22.2383 97.0405 22.1127 97.0824 22.0289L97.4174 20.2253H97.7524H98.0036V20.2642Z" fill="white"/>
  <path d="M103.247 22.0289C103.163 22.3639 102.954 22.6151 102.744 22.8275C102.493 23.0368 102.242 23.1206 101.946 23.1206C101.653 23.1206 101.443 23.0369 101.276 22.8694C101.108 22.7019 101.025 22.4506 101.025 22.1575C101.025 22.0737 101.025 21.99 101.025 21.9481C101.025 21.8644 101.025 21.7806 101.067 21.6969C101.15 21.2362 101.318 20.8983 101.569 20.6051C101.82 20.3539 102.155 20.2283 102.493 20.2283C102.828 20.2283 103.038 20.312 103.205 20.5214C103.331 20.6889 103.414 20.8564 103.414 21.1495V21.317H102.744C102.744 21.1495 102.703 21.0658 102.661 20.982C102.619 20.8983 102.535 20.8564 102.409 20.8564C102.242 20.8564 102.116 20.9401 101.991 21.0658C101.865 21.2333 101.781 21.4426 101.74 21.7358L101.698 21.9032V22.0707C101.698 22.2382 101.739 22.3639 101.781 22.4476C101.823 22.5314 101.907 22.5732 102.033 22.5732C102.158 22.5732 102.284 22.5314 102.368 22.4476C102.451 22.3639 102.535 22.2382 102.577 22.1126L103.247 22.0289Z" fill="white"/>
  <path d="M104.129 23.1176C103.92 23.1176 103.752 23.0757 103.668 22.9501C103.543 22.8245 103.501 22.6989 103.501 22.4895V22.4057V22.2801C103.543 22.0708 103.627 21.8614 103.794 21.6939C103.962 21.5264 104.129 21.4426 104.38 21.4008L104.925 21.317L105.05 21.2751L105.176 21.1495L105.218 20.982L105.176 20.8145C105.134 20.7727 105.008 20.7308 104.883 20.7308C104.757 20.7308 104.673 20.7727 104.59 20.8145C104.506 20.8983 104.464 20.982 104.422 21.1076H103.794C103.878 20.7726 104.003 20.5633 104.213 20.3958C104.422 20.2283 104.715 20.1445 105.053 20.1445C105.388 20.1445 105.598 20.2283 105.723 20.3539C105.849 20.4377 105.891 20.6052 105.891 20.7726V20.982L105.598 22.4925C105.556 22.6181 105.556 22.7019 105.598 22.7856L105.681 22.9112V22.995H105.346H105.011C105.011 22.8694 105.011 22.7856 105.011 22.66C104.715 22.9501 104.422 23.1176 104.129 23.1176ZM105.095 22.0289L105.137 21.6939L104.928 21.7776L104.676 21.8195C104.509 21.8614 104.383 21.9033 104.3 21.987C104.216 22.0289 104.174 22.1126 104.174 22.2382V22.2801V22.322C104.174 22.4057 104.216 22.4476 104.258 22.4895C104.3 22.5314 104.383 22.5314 104.425 22.5314C104.635 22.5314 104.802 22.4476 104.97 22.2382L105.053 22.1126L105.095 22.0289Z" fill="white"/>
  <path d="M106.1 23.0339L106.603 20.2642H106.896H107.231L107.147 20.641L107.231 20.5154C107.44 20.2642 107.692 20.1804 107.985 20.1804C108.236 20.1804 108.445 20.2642 108.571 20.4317C108.697 20.5992 108.738 20.8504 108.655 21.1435L108.32 23.0309H107.985H107.65L107.943 21.311C107.985 21.1435 107.985 21.0179 107.901 20.9342C107.859 20.8504 107.775 20.8085 107.608 20.8085C107.44 20.8085 107.315 20.8504 107.189 20.976C107.063 21.1017 106.98 21.2273 106.98 21.4367L106.687 23.0309H106.352L106.1 23.0339Z" fill="white"/>
  <path d="M110.255 23.0338L110.925 19.301H111.26H112.561C112.854 19.301 113.063 19.3848 113.189 19.5104C113.315 19.636 113.398 19.8035 113.398 20.0548V20.1804V20.3479C113.356 20.5991 113.231 20.8085 112.98 20.9341L112.854 21.0179L112.98 21.1016C113.147 21.2273 113.231 21.4366 113.231 21.646V21.7716V21.8972C113.147 22.2322 113.021 22.5254 112.77 22.7377C112.519 22.9471 112.184 23.0308 111.804 23.0308H110.629L110.255 23.0338ZM111.514 19.971L111.346 20.7696H112.145C112.313 20.7696 112.438 20.7277 112.522 20.6859C112.606 20.6021 112.648 20.5184 112.689 20.3928V20.3509V20.309C112.689 20.2253 112.648 20.1415 112.606 20.0996C112.522 20.0578 112.438 20.0159 112.313 20.0159L111.514 19.971ZM111.263 21.3977L111.095 22.3638H111.894C112.103 22.3638 112.229 22.322 112.354 22.2382C112.48 22.1545 112.522 22.0289 112.564 21.8614V21.7776V21.6938C112.564 21.5682 112.522 21.4845 112.438 21.4426C112.354 21.4007 112.271 21.3589 112.103 21.3589H111.263V21.3977Z" fill="white"/>
  <path d="M113.359 23.0338L115.247 19.301H115.666H116.084L116.545 23.0338H116.168H115.791L115.707 22.2352H114.49L114.113 23.0338H113.736H113.359ZM115.498 20.1385L114.786 21.649H115.668L115.498 20.1385Z" fill="white"/>
  <path d="M117.556 23.0338H117.263H116.925L117.598 19.301H117.975H118.352L119.192 21.9451L119.695 19.301H119.988H120.326L119.653 23.0338H119.318H118.941L118.059 20.3479L117.556 23.0338Z" fill="white"/>
  <path d="M120.368 23.0338L121.038 19.301H121.373H121.752L121.456 20.8534L123.009 19.301H123.472H123.933L122.297 20.8534L123.305 23.0338H122.841H122.381L121.708 21.3977L121.289 21.8165L121.038 23.0338H120.703H120.368Z" fill="white"/>
  <path d="M126.029 20.2642L125.694 21.9841C125.653 22.1516 125.653 22.2772 125.736 22.3609C125.778 22.4447 125.862 22.4866 126.029 22.4866C126.197 22.4866 126.323 22.4447 126.448 22.3191C126.574 22.1934 126.658 22.0678 126.658 21.8584L126.951 20.2642H127.286H127.621L127.118 23.0339H126.825H126.49L126.574 22.6152L126.49 22.7408C126.281 22.992 126.029 23.0758 125.736 23.0758C125.485 23.0758 125.276 22.992 125.15 22.7827C125.066 22.657 125.024 22.4895 125.024 22.322C125.024 22.2383 125.024 22.1127 125.066 22.0289L125.401 20.2253H125.736H126.029V20.2642Z" fill="white"/>
  <path d="M128.213 20.2642H128.506H128.799L128.716 20.641L128.757 20.5992C129.009 20.3061 129.26 20.1804 129.556 20.1804C129.807 20.1804 130.017 20.2642 130.142 20.4317C130.268 20.5992 130.352 20.8504 130.352 21.1435C130.352 21.2273 130.352 21.2692 130.352 21.3948C130.352 21.4785 130.352 21.5623 130.31 21.646C130.226 22.0648 130.059 22.3998 129.807 22.6959C129.556 22.992 129.305 23.1146 129.009 23.1146C128.716 23.1146 128.506 22.989 128.381 22.7378L128.129 24.1645H127.794H127.459L128.213 20.2642ZM128.967 22.4895C129.134 22.4895 129.26 22.4057 129.386 22.2801C129.511 22.1126 129.595 21.9451 129.637 21.6939L129.679 21.5264V21.3589C129.679 21.1914 129.637 21.0658 129.553 20.982C129.469 20.8983 129.386 20.8564 129.26 20.8564C129.092 20.8564 128.967 20.9401 128.841 21.0658C128.716 21.2333 128.632 21.4008 128.59 21.652C128.548 21.9451 128.548 22.1545 128.632 22.2801C128.674 22.4476 128.799 22.4895 128.967 22.4895Z" fill="white"/>
  <path d="M131.653 23.1177C131.318 23.1177 131.066 23.0339 130.899 22.8245C130.731 22.6152 130.606 22.3639 130.606 22.0708V21.8614L130.648 21.6521C130.731 21.1914 130.899 20.8535 131.192 20.6022C131.485 20.351 131.778 20.2253 132.2 20.2253C132.535 20.2253 132.786 20.3091 132.954 20.5185C133.121 20.7278 133.247 20.9791 133.247 21.3171V21.5264L133.205 21.7358C133.121 22.1964 132.954 22.5344 132.661 22.7857C132.364 22.992 132.029 23.1177 131.653 23.1177ZM131.778 22.4895C131.988 22.4895 132.155 22.4058 132.239 22.2802C132.364 22.1546 132.448 21.9033 132.49 21.6102V21.4846V21.3589C132.49 21.1914 132.448 21.0658 132.364 20.9821C132.281 20.8983 132.155 20.8564 132.029 20.8564C131.82 20.8564 131.653 20.9402 131.569 21.0658C131.443 21.2333 131.359 21.4427 131.318 21.7358V21.8614V21.9871C131.318 22.1545 131.359 22.2802 131.443 22.3639C131.485 22.4477 131.611 22.4895 131.778 22.4895Z" fill="white"/>
  <path d="M133.372 23.0339L133.875 20.2642H134.21H134.545L134.461 20.641L134.545 20.5154C134.754 20.2642 135.006 20.1804 135.299 20.1804C135.55 20.1804 135.759 20.2642 135.885 20.4317C136.011 20.5992 136.052 20.8504 135.969 21.1435L135.634 23.0309H135.299H134.964L135.257 21.311C135.299 21.1435 135.299 21.0179 135.215 20.9342C135.173 20.8504 135.089 20.8085 134.922 20.8085C134.754 20.8085 134.629 20.8504 134.503 20.976C134.377 21.1017 134.294 21.2273 134.294 21.4367L134.001 23.0309H133.666L133.372 23.0339Z" fill="white"/>
  <path d="M136.686 22.029L136.812 20.2254L136.98 19.343H137.315H137.691L137.524 20.2254L137.021 22.029H136.854H136.686ZM136.477 22.2802H137.147L137.021 22.9921H136.351L136.477 22.2802Z" fill="white"/>
  <path d="M28.7345 5.91626H33.0147V22.5733H28.7345V18.4606C24.9598 19.8455 21.4753 19.9711 18.2031 16.7827C16.8601 15.3559 16.1064 13.4267 16.4414 10.0708C16.4833 9.5683 16.232 7.4686 15.6428 6.1675H19.4593C21.2211 9.02094 18.915 12.3769 21.6398 14.7278C25.3307 17.1625 28.6059 14.2672 28.7315 11.6231V5.91626H28.7345ZM24.4544 7.67797C26.4673 7.67797 28.1453 9.31407 28.1453 11.3689C28.1453 13.3819 26.5092 15.0598 24.4544 15.0598C22.4414 15.0598 20.7634 13.4237 20.7634 11.3689C20.8053 9.35594 22.4414 7.67797 24.4544 7.67797Z" fill="#FBBC09"/>
  <path d="M39.2659 11.2043C40.902 11.2043 42.245 12.5892 42.245 14.2672C42.245 16.322 40.232 17.665 38.3417 17.0369V15.652C39.3915 16.2801 40.7345 15.652 40.7345 14.3928C40.7345 13.5104 40.0226 12.7567 39.1403 12.7567C38.2579 12.7567 37.5461 13.4686 37.5461 14.3928C37.5461 16.2801 37.5042 18.1256 37.5042 20.0159H36.245V14.309V14.0159C36.3287 12.4606 37.6298 11.2462 39.2659 11.2043Z" fill="white"/>
  <path d="M42.7056 11.4976H44.0067V14.4766C43.923 16.2383 46.6508 16.364 46.567 14.4766V11.4976H47.8681V14.5604C47.9937 18.3769 42.4544 18.1257 42.6667 14.5604V11.4976H42.7056Z" fill="white"/>
  <path d="M48.6217 17.1595H49.9227V14.1804C49.839 12.4187 52.5668 12.293 52.4831 14.1804V17.1595H53.7842V14.0966C53.9098 10.2801 48.3704 10.5313 48.5828 14.0966V17.1595H48.6217Z" fill="white"/>
  <path d="M66.7474 17.3719C68.3835 17.3719 69.7265 15.987 69.7265 14.3091C69.7265 12.2542 67.7135 10.9113 65.8232 11.5394V12.9242C66.873 12.2961 68.216 12.9242 68.216 14.1834C68.216 15.0658 67.5041 15.8195 66.6218 15.8195C65.7394 15.8195 65.0276 15.1077 65.0276 14.1834C65.0276 12.2961 64.9857 10.4506 64.9857 8.5603H63.7265V14.3091V14.6022C63.8102 16.1126 65.1532 17.33 66.7474 17.3719Z" fill="white"/>
  <path d="M54.1999 12.8405V11.4976H56.8021V16.9951C56.8021 18.7149 55.6267 20.0579 53.9487 20.1835V18.9661C54.7892 18.6312 55.4173 18.1257 55.4592 16.9951V12.8824H54.1999V12.8405Z" fill="white"/>
  <path d="M57.3047 14.686C57.1791 13.0499 58.3546 11.9163 59.4852 11.5813C61.0794 11.1207 63.0924 11.9582 63.1761 14.2254V17.0788H61.9588V14.1835C61.875 12.8406 59.8621 12.2962 59.0216 13.5554C58.6866 14.2254 58.6028 15.2334 59.5241 15.7777C59.9847 15.9452 60.4902 16.029 61.1183 15.9034V17.1207C60.1103 17.4138 59.1053 17.3301 58.1392 16.4507C57.6427 15.9452 57.3885 15.356 57.3047 14.686Z" fill="white"/>
  <path d="M71.7814 17.1595H73.0825V14.1804C72.9987 12.4187 75.7265 12.293 75.6428 14.1804V17.1595H76.9439V14.0966C77.0695 10.2801 71.5301 10.5313 71.7425 14.0966V17.1595H71.7814Z" fill="white"/>
  <path d="M77.5719 14.686C77.4462 13.0499 78.6217 11.9163 79.7523 11.5813C81.3465 11.1207 83.3595 11.9582 83.4432 14.2254V17.0788H82.2259V14.1835C82.1421 12.8406 80.1292 12.2962 79.2887 13.5554C78.9537 14.2254 78.87 15.2334 79.7912 15.7777C80.2518 15.9452 80.7573 16.029 81.3854 15.9034V17.1207C80.3774 17.4138 79.3725 17.3301 78.4064 16.4507C77.9487 15.9452 77.6975 15.356 77.5719 14.686Z" fill="white"/>
  <path d="M96.2 17.1595H97.5011V14.1804C97.4174 12.4187 100.145 12.293 100.061 14.1804V17.1595H101.363V14.0966C101.488 10.2801 95.9488 10.5313 96.1611 14.0966V17.1595H96.2Z" fill="white"/>
  <path d="M101.991 14.686C101.865 13.0499 103.041 11.9163 104.171 11.5813C105.765 11.1207 107.778 11.9582 107.862 14.2254V17.0788H106.645V14.1835C106.561 12.8406 104.548 12.2962 103.708 13.5554C103.373 14.2254 103.289 15.2334 104.21 15.7777C104.671 15.9452 105.176 16.029 105.804 15.9034V17.1207C104.796 17.4138 103.791 17.3301 102.825 16.4507C102.368 15.9452 102.116 15.356 101.991 14.686Z" fill="white"/>
  <path d="M88.5641 8.72778C89.1503 8.72778 89.6139 9.1884 89.6139 9.77764C89.6139 10.3639 89.1533 10.8275 88.5641 10.8275C87.9778 10.8275 87.5142 10.3669 87.5142 9.77764C87.5142 9.1884 87.9748 8.72778 88.5641 8.72778Z" fill="white"/>
  <path d="M89.3178 11.5393H87.8492V17.1624H89.3178V11.5393Z" fill="white"/>
  <path d="M84.1581 9.56519H85.5848V11.5363H87.3046V12.8792H85.5848V13.8035C85.5429 15.4396 86.0454 15.8164 87.2209 16.1125V17.2043C84.9567 17.4136 84.1581 16.0288 84.1581 13.8065V9.56519Z" fill="white"/>
  <path d="M92.8022 11.4976C91.2079 11.4976 89.865 12.7987 89.865 14.4347C89.865 16.029 91.1661 17.3719 92.8022 17.3719C94.4382 17.3719 95.7393 16.0708 95.7393 14.4347C95.6945 12.8405 94.3964 11.4976 92.8022 11.4976ZM92.8022 15.9452C91.9617 15.9452 91.2917 15.2752 91.2917 14.4347C91.2917 13.5943 91.9617 12.9243 92.8022 12.9243C93.6426 12.9243 94.3126 13.5943 94.3126 14.4347C94.3126 15.2722 93.5978 15.9452 92.8022 15.9452Z" fill="white"/>
  <path d="M120.703 14.686C120.577 13.0499 121.752 11.9163 122.883 11.5813C124.477 11.1207 126.49 11.9582 126.574 14.2254V17.0788H125.357V14.1835C125.273 12.8406 123.26 12.2962 122.419 13.5554C122.084 14.2254 122.001 15.2334 122.922 15.7777C123.382 15.9452 123.888 16.029 124.516 15.9034V17.1207C123.508 17.4138 122.503 17.3301 121.537 16.4507C121.079 15.9452 120.828 15.356 120.703 14.686Z" fill="white"/>
  <path d="M127.331 17.1595H128.632V14.1804C128.548 12.4187 131.276 12.293 131.192 14.1804V17.1595H132.493V14.0966C132.619 10.2801 127.079 10.5313 127.292 14.0966V17.1595H127.331Z" fill="white"/>
  <path d="M117.347 17.3719C118.983 17.3719 120.326 15.987 120.326 14.3091C120.326 12.2542 118.313 10.9113 116.422 11.5394V12.9242C117.472 12.2961 118.815 12.9242 118.815 14.1834C118.815 15.0658 118.103 15.8195 117.221 15.8195C116.339 15.8195 115.627 15.1077 115.627 14.1834C115.627 12.2961 115.585 10.4506 115.585 8.5603H114.326V14.3091V14.6022C114.368 16.1126 115.711 17.33 117.347 17.3719Z" fill="white"/>
  <path d="M108.577 8.68604H109.878V13.4268C109.794 15.3142 110.632 15.8196 112.017 15.9871V17.2045C109.498 17.4139 108.577 15.9034 108.619 13.3879V8.68903H108.577V8.68604Z" fill="white"/>
  <path d="M133.247 8.64404H134.674V13.0498C135.092 11.9162 136.1 11.0787 137.234 11.0787C137.318 11.0787 137.401 11.0787 137.485 11.0787V12.4636C136.561 12.673 135.849 13.4297 135.849 14.309C135.849 15.1914 136.561 15.987 137.485 16.1545V17.3719C137.401 17.3719 137.318 17.3719 137.234 17.3719C136.058 17.3719 135.095 16.5733 134.674 15.4008V17.0787H133.247V8.64404Z" fill="white"/>
</svg>
    )
}

export default PNBbank;

export{};