import React from 'react'

const SBIbank: React.FC<React.SVGProps<SVGSVGElement>> = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="20" viewBox="0 0 86 30" fill="none">
  <g clip-path="url(#clip0_1_3437)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4947 14.8531C29.4947 6.72053 22.9026 0.128418 14.7707 0.128418C6.63811 0.128418 0.0466309 6.72053 0.0466309 14.8531C0.0466309 22.6769 6.08368 29.0838 13.7847 29.5771V18.1797C12.3677 17.7481 11.3206 16.4547 11.3206 14.8531C11.3206 13.0046 12.8604 11.4641 14.7707 11.4641C16.6803 11.4641 18.2208 13.0046 18.2208 14.8531C18.2208 16.4547 17.1736 17.7481 15.6949 18.1797V29.5771C23.3959 29.0838 29.4947 22.6769 29.4947 14.8531Z" fill="#00B6F0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.3579 8.71393C51.2507 6.92966 52.1428 5.14475 53.0349 3.35985C47.9675 -1.37868 38.3399 -1.16888 35.201 5.6135C34.365 7.42172 34.3939 9.42525 35.1916 11.5315C35.5772 12.5477 36.2702 13.8154 37.3709 14.7239C37.9329 15.1882 40.0939 16.258 41.841 17.05C43.4753 17.7909 45.7252 18.3227 46.7579 19.7837C47.9385 21.4527 47.2096 23.5136 44.4714 23.8891C42.9373 24.0995 40.6534 23.4613 37.2493 20.7149C36.0491 22.2844 34.8495 23.8538 33.6492 25.4232C45.0977 35.4075 55.666 26.6657 53.1899 18.896C52.5605 16.9227 51.2664 15.405 49.663 14.2469C47.1831 12.4557 44.5943 11.9706 42.8661 11.2171C39.6277 9.80516 40.0845 5.8315 44.5754 6.07406C47.4175 6.22779 48.3304 7.03928 50.3579 8.71393Z" fill="#271E79"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M56.1605 0.116455H67.2618C70.9936 0.116455 75.3692 3.35927 75.3692 7.23087C75.3692 8.12553 75.4309 8.79589 75.3698 9.8342C75.2482 11.8888 73.3524 14.1947 72.1314 14.1947C74.0322 14.1947 75.9318 16.9177 76.398 18.8028C77.5309 23.3813 75.1254 29.901 67.2618 29.901H56.1605V0.116455ZM62.1144 5.55181H66.4768C68.1143 5.55181 69.4537 6.94105 69.4537 8.64027V9.08256C69.4537 10.538 68.3058 11.7287 66.9033 11.7287H65.1033V17.2353H67.2032C69.1009 17.2353 70.654 18.8463 70.654 20.8145V20.8152C70.654 22.7834 69.1009 24.3944 67.2032 24.3944H62.1144C62.1144 18.1136 62.1144 11.8327 62.1144 5.55181Z" fill="#271E79"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.3655 0.116455H85.6433V29.901H79.3655V0.116455Z" fill="#271E79"/>
  </g>
  <defs>
    <clipPath id="clip0_1_3437">
      <rect width="85.6452" height="30" fill="white"/>
    </clipPath>
  </defs>
</svg>           
    )

}
export default SBIbank;

export {};