

import React, {useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate  } from 'react-router-dom';
import { Grid,  Card, CardContent, TextField,  Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '../assets/themeContext';



const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  // email: Yup.string().email('Invalid Phone Number').required('Email is Phone Number'),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, "Invalid Mobile Number")
    .required("Mobile Number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});



const HomeScreen: React.FC = () => {
  const { theme } = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobileNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };


return (




  <Grid  item lg={4} xs={10} sm={8}  style={{  display: 'flex', justifyContent: 'center', marginTop: '50px', marginLeft: '12px' }}>
    
      <Card sx={{ backgroundColor: '#f2f2f2',paddingTop: '8px' ,boxShadow: '0px 9px 30px 0px rgba(214, 182, 255, 1)', width: '78%', height: '97%' }}>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
          <div style={{ display:"flex",  alignItems:"center" , backgroundColor: '#fff', marginTop: '5px' , height: '95px', borderRadius: 5 }}>
           
           <Grid container spacing={3}  sx={{ display: 'flex', justifyContent: 'center' }}>
                
              
            <Grid item lg={5} sm={5} xs={8} alignItems="center">
              <Grid>
                 <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputProps={
                    {
                    
                    }
                  }
                />
                </Grid>
              </Grid>
              <Grid item lg={5} sm={5} xs={10}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              </Grid>
          
          </div>
          {/* </div> */}
          <div style={{ display:"flex",  alignItems:"center", backgroundColor: '#fff', marginTop: '5px', height: '95px', borderRadius: 5 }}>
           
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              
              <Grid item xs={10} sm={10} lg={10}>
                <TextField 
                fullWidth
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Phone Number"
                  type="mobileNumber"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  error={
                     formik.touched.mobileNumber &&
                        Boolean(formik.errors.mobileNumber)
                     }
                    helperText={
                        formik.touched.mobileNumber && formik.errors.mobileNumber
                     }
                    InputProps={
                    {
                  //   startAdornment: (
                  //  //      <Email />
                  //   )
                  }
                }
                 
              />
              </Grid>
            </Grid>
          </div>
          <div style={{ display:"flex",  alignItems:"center", backgroundColor: '#fff', marginTop: '5px', height: '95px', borderRadius: 5 }}>
           
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              
              <Grid item xs={10} sm={10} lg={10}>
                <TextField 
                   type={showPassword ? "text" : "password"}
                   fullWidth
                   id="password"
                   name="password"
                   label="Password"
                  // type="password"
                   value={formik.values.password}
                   onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                       endAdornment: (
                              <span
                                onClick={handleTogglePasswordVisibility}
                                style={{ cursor: "pointer" }}
                              >
                             {showPassword ? <VisibilityOff /> : <Visibility />}
                        </span>
                        ),
                    }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ display:"flex",  alignItems:"center", backgroundColor: '#fff', marginTop: '5px', height: '95px', borderRadius: 5 }}>
           
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              
              <Grid item xs={10} sm={10} lg={10}>
                <TextField
                   fullWidth
                   id="confirmPassword"
                   name="confirmPassword"
                   label="Confirm Password"
                   type="password"
                   value={formik.values.confirmPassword}
                   onChange={formik.handleChange}
                   error={
                     formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                   }
                  helperText={
                     formik.touched.confirmPassword &&
                     formik.errors.confirmPassword
                   }
                   InputProps={
                     {
                       //  startAdornment: <Lock />,
                       // g
                     }
                   }
                />
              </Grid>
            </Grid>
          </div>

          
          <Grid sx={{ display: 'flex', marginTop: '5px' ,justifyContent: 'center', borderRadius: '9px' }} >
            {/* <Button style={{ backgroundColor: theme.palette.primary.main }} color="primary" variant="contained" > Transfer Now </Button> */}
            <Button
                 color="primary"
                 variant="contained"
                 fullWidth
                 type="submit"
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    marginTop: '7px',
                    padding: '14px',
                    width: '60%'
                }}
              >
                SignUP
              </Button>
              </Grid>
          </form>  
        </CardContent>
      </Card>
    </Grid>


);
};

export default HomeScreen;

















// import React, { useState } from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import {
//   Card,
//   CardContent,
//   Button,
//   TextField,
//   Grid,
//   Stack,
// } from "@mui/material";
// import { useTheme } from "../assets/themeContext";

// const validationSchema = Yup.object().shape({
//   firstName: Yup.string().required("First Name is required"),
//   lastName: Yup.string().required("Last Name is required"),
//   // email: Yup.string().email('Invalid Phone Number').required('Email is Phone Number'),
//   mobileNumber: Yup.string()
//     .matches(/^\d{10}$/, "Invalid Mobile Number")
//     .required("Mobile Number is required"),
//   password: Yup.string()
//     .min(6, "Password must be at least 6 characters")
//     .required("Password is required"),
//   confirmPassword: Yup.string()
//     .oneOf([Yup.ref("password")], "Passwords must match")
//     .required("Confirm Password is required"),
// });

// const SignUpForm = () => {
//   const { theme } = useTheme();
//   const [showPassword, setShowPassword] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       firstName: "",
//       lastName: "",
//       email: "",
//       password: "",
//       confirmPassword: "",
//       mobileNumber: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       alert(JSON.stringify(values, null, 2));
//     },
//   });

//   const handleTogglePasswordVisibility = () => {
//     setShowPassword((prevState) => !prevState);
//   };

//   return (
//     <Grid
//       item
//       style={{ backgroundColor: theme.palette.primary.main }}
//       sx={{
//         fontFamily: "Arial, sans-serif",
//         display: "flex",
//         padding: "30px",
//         alignItems: "center",
//         textAlign: "center",
//         justifyContent: "center",
//         height: "100vh",
//       }}
//     >
//       <Card
//         style={{
//           width: "55%", // Adjust this value to your desired percentage
//           margin: "auto",
//           marginTop: "50px",
//         }}
//       >
//         <CardContent>
//           <h2 style={{ textAlign: "center" }}>Sign Up</h2>
//           <form onSubmit={formik.handleSubmit}>
//             <Grid
//               container
//               spacing={2}
//               sx={{
//                 alignItems: "center",
//                 textAlign: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Grid item lg={5} sm={5} xs={8}>
//                 <TextField
//                   fullWidth
//                   id="firstName"
//                   name="firstName"
//                   label="First Name"
//                   value={formik.values.firstName}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.firstName && Boolean(formik.errors.firstName)
//                   }
//                   helperText={
//                     formik.touched.firstName && formik.errors.firstName
//                   }
//                   InputProps={
//                     {
//                       // startAdornment: (
//                       //   <Person />
//                       // )
//                     }
//                   }
//                 />
//               </Grid>
//               <Grid item lg={5} sm={5} xs={10}>
//                 <TextField
//                   fullWidth
//                   id="lastName"
//                   name="lastName"
//                   label="Last Name"
//                   value={formik.values.lastName}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.lastName && Boolean(formik.errors.lastName)
//                   }
//                   helperText={formik.touched.lastName && formik.errors.lastName}
//                 />
//               </Grid>
//               <Grid item xs={10}>
//                 <TextField
//                   fullWidth
//                   id="mobileNumber"
//                   name="mobileNumber"
//                   label="Phone Number"
//                   type="mobileNumber"
//                   value={formik.values.mobileNumber}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.mobileNumber &&
//                     Boolean(formik.errors.mobileNumber)
//                   }
//                   helperText={
//                     formik.touched.mobileNumber && formik.errors.mobileNumber
//                   }
//                   InputProps={
//                     {
//                       // startAdornment: (
//                       //   <Email />
//                       // )
//                     }
//                   }
//                 />
//               </Grid>
//               <Grid item xs={10}>
//                 <TextField
//                   type={showPassword ? "text" : "password"}
//                   fullWidth
//                   id="password"
//                   name="password"
//                   label="Password"
//                   // type="password"
//                   value={formik.values.password}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.password && Boolean(formik.errors.password)
//                   }
//                   helperText={formik.touched.password && formik.errors.password}
//                   InputProps={{
//                     endAdornment: (
//                       <span
//                         onClick={handleTogglePasswordVisibility}
//                         style={{ cursor: "pointer" }}
//                       >
//                         {showPassword ? <VisibilityOff /> : <Visibility />}
//                       </span>
//                     ),
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={10}>
//                 <TextField
//                   fullWidth
//                   id="confirmPassword"
//                   name="confirmPassword"
//                   label="Confirm Password"
//                   type="password"
//                   value={formik.values.confirmPassword}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.confirmPassword &&
//                     Boolean(formik.errors.confirmPassword)
//                   }
//                   helperText={
//                     formik.touched.confirmPassword &&
//                     formik.errors.confirmPassword
//                   }
//                   InputProps={
//                     {
//                       //  startAdornment: <Lock />,
//                       // g
//                     }
//                   }
//                 />
//               </Grid>
//               <Grid item xs={10}>
//                 <Button
//                   color="primary"
//                   variant="contained"
//                   fullWidth
//                   style={{
//                     backgroundColor: "green",
//                     color: "white",
//                     marginTop: "18px",
//                     padding: "14px",
//                   }}
//                   type="submit"
//                 >
//                   Sign Up
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         </CardContent>
//       </Card>
//     </Grid>
//   );
// };

// export default SignUpForm;
