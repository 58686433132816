import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(0 7 13)', // Change this to your desired color
    },
    secondary: {
      main: 'rgb(99 108 115)', // Change this to your desired color
    }, 
    // error:{

    // },
    // warning:{

    // },
    // info:{

    // },
    // success:{

    // }
  },
  typography: {
    fontFamily: '"Inter", sans-serif'
  },
});

export default darkTheme;
