import React from 'react';

const ReferralSmallIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="350" height="350" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M128.4 348.78C134.616 389.268 108.828 448.8 160.404 471.384C229.284 501.564 272.448 425.184 328.404 411.204C371.1 400.524 444.888 478.044 498.06 428.832C551.232 379.62 461.688 312.432 458.316 272.388C454.98 232.788 529.116 163.692 469.656 121.584C411.288 80.3043 344.856 159.156 289.656 173.88C240.576 186.948 121.44 124.8 86.0162 185.724C52.4642 243.408 122.4 309.684 128.4 348.78Z" fill="#6D1ED4"/>
    <path opacity="0.9" d="M128.4 348.78C134.616 389.268 108.828 448.8 160.404 471.384C229.284 501.564 272.448 425.184 328.404 411.204C371.1 400.524 444.888 478.044 498.06 428.832C551.232 379.62 461.688 312.432 458.316 272.388C454.98 232.788 529.116 163.692 469.656 121.584C411.288 80.3043 344.856 159.156 289.656 173.88C240.576 186.948 121.44 124.8 86.0162 185.724C52.4642 243.408 122.4 309.684 128.4 348.78Z" fill="white"/>
    <path d="M300 508.452C428.499 508.452 532.668 502.37 532.668 494.868C532.668 487.366 428.499 481.284 300 481.284C171.501 481.284 67.332 487.366 67.332 494.868C67.332 502.37 171.501 508.452 300 508.452Z" fill="#F5F5F5"/>
    <path d="M416.232 326.509H507.06C510.408 326.509 513.619 327.839 515.986 330.206C518.354 332.574 519.684 335.785 519.684 339.133V408.169C519.684 411.517 518.354 414.728 515.986 417.095C513.619 419.463 510.408 420.793 507.06 420.793H438.48C437.819 426.19 435.211 431.16 431.146 434.77C427.081 438.38 421.837 440.383 416.4 440.401C415.896 440.401 415.404 440.401 414.9 440.329C418.5 435.649 421.344 428.881 423.24 420.805H416.292C412.944 420.805 409.733 419.475 407.365 417.107C404.998 414.74 403.668 411.529 403.668 408.181V339.133C403.668 335.795 404.99 332.593 407.344 330.227C409.699 327.862 412.894 326.525 416.232 326.509Z" fill="#6D1ED4"/>
    <path opacity="0.4" d="M416.232 326.509H507.06C510.408 326.509 513.619 327.839 515.986 330.206C518.354 332.574 519.684 335.785 519.684 339.133V408.169C519.684 411.517 518.354 414.728 515.986 417.095C513.619 419.463 510.408 420.793 507.06 420.793H438.48C437.819 426.19 435.211 431.16 431.146 434.77C427.081 438.38 421.837 440.383 416.4 440.401C415.896 440.401 415.404 440.401 414.9 440.329C418.5 435.649 421.344 428.881 423.24 420.805H416.292C412.944 420.805 409.733 419.475 407.365 417.107C404.998 414.74 403.668 411.529 403.668 408.181V339.133C403.668 335.795 404.99 332.593 407.344 330.227C409.699 327.862 412.894 326.525 416.232 326.509Z" fill="white"/>
    <path d="M411.936 353.353L407.448 345.757L410.256 340.957C410.438 340.645 410.548 340.297 410.577 339.938C410.606 339.578 410.554 339.217 410.424 338.881L410.208 338.341C410.04 337.891 409.741 337.502 409.35 337.223C408.958 336.945 408.492 336.791 408.012 336.781L403.608 336.661C403.161 336.645 402.718 336.755 402.33 336.978C401.942 337.2 401.624 337.527 401.412 337.921L400.524 339.553C400.309 339.946 400.19 340.385 400.175 340.833C400.161 341.281 400.251 341.726 400.44 342.133L402.336 346.225L405.852 355.633L411.936 353.353Z" fill="#7F3E3B"/>
    <path d="M447.684 352.021L422.04 370.681L410.04 348.145L403.2 351.109C403.2 351.109 413.748 385.261 419.88 383.881C426.012 382.501 443.316 373.945 448.944 368.077C451.788 365.113 453.948 359.677 451.14 352.957C451.011 352.635 450.808 352.347 450.549 352.117C450.289 351.886 449.98 351.719 449.645 351.628C449.31 351.538 448.959 351.526 448.619 351.594C448.278 351.662 447.958 351.808 447.684 352.021Z" fill="#6D1ED4"/>
    <path d="M457.26 307.584C457.26 307.584 448.212 309.984 452.4 320.892C452.4 320.892 456.912 319.692 457.644 316.788C458.376 313.884 459.072 309.78 459.072 309.78L457.26 307.584Z" fill="#263238"/>
    <path opacity="0.3" d="M457.26 307.584C457.26 307.584 448.212 309.984 452.4 320.892C452.4 320.892 456.912 319.692 457.644 316.788C458.376 313.884 459.072 309.78 459.072 309.78L457.26 307.584Z" fill="black"/>
    <path d="M478.656 350.94L462.12 350.4L455.28 351.384L454.344 363.852L467.676 368.28L481.896 365.448L482.64 353.436L481.62 351.036L478.656 350.94Z" fill="#7F3E3B"/>
    <path d="M456.144 420.792H479.544C479.772 412.548 480.996 369.744 480.744 370.008C480.492 370.272 483.216 360.888 483.216 360.888V357.084L480.744 353.004C480.744 353.004 475.44 361.092 466.704 360.696C458.424 360.312 456.996 352.044 456.996 352.044L456 364.68L454.956 395.28L456.288 408.24L456.144 420.792Z" fill="#6D1ED4"/>
    <path opacity="0.6" d="M456.144 420.792H479.544C479.772 412.548 480.996 369.744 480.744 370.008C480.492 370.272 483.216 360.888 483.216 360.888V357.084L480.744 353.004C480.744 353.004 475.44 361.092 466.704 360.696C458.424 360.312 456.996 352.044 456.996 352.044L456 364.68L454.956 395.28L456.288 408.24L456.144 420.792Z" fill="white"/>
    <path d="M446.832 420.792H459.504C462 404.28 462.336 374.088 462.336 374.088C460.823 368.308 459.641 362.447 458.796 356.532C458.028 349.608 467.916 350.532 476.556 351.672C485.196 352.812 469.836 374.664 469.836 374.664C469.836 374.664 468.744 404.196 471.276 420.792H502.476C500.916 416.388 496.308 402.864 495.276 393.276C493.944 381.684 501.924 364.776 500.784 356.532C499.644 348.288 472.296 349.968 472.296 349.968C472.296 349.968 454.788 349.848 450.888 350.028C446.328 350.232 444.504 362.244 442.344 371.736C440.184 381.228 444.156 390.624 446.328 402.564C447.406 408.581 447.576 414.725 446.832 420.792Z" fill="#263238"/>
    <path d="M460.8 349.933C466.488 349.933 472.356 349.933 472.356 349.933C472.356 349.933 475.848 349.717 480.384 349.813C482.196 351.013 484.584 353.917 482.688 359.989L478.728 361.513L479.448 365.413C479.448 365.413 474.888 372.613 469.848 375.373C469.848 374.893 469.848 374.629 469.848 374.629C469.848 374.629 485.22 352.777 476.568 351.637C467.916 350.497 458.04 349.573 458.808 356.497C459.653 362.411 460.835 368.273 462.348 374.053C462.348 374.053 462.348 374.173 462.348 374.377C459.948 372.205 457.848 368.377 456.348 363.793L457.968 359.305L454.368 357.337C454.676 355.638 455.435 354.054 456.567 352.751C457.699 351.448 459.161 350.475 460.8 349.933Z" fill="#6D1ED4"/>
    <path d="M460.56 356.473C460.283 355.836 460.171 355.139 460.234 354.447C460.297 353.755 460.533 353.09 460.92 352.513C467.424 350.317 466.824 345.433 465.324 340.909L475.8 330.217C475.188 335.905 474.96 348.997 478.68 353.557V354.601C477.062 356.68 474.898 358.267 472.428 359.185C469.959 360.102 467.283 360.314 464.7 359.797C463.784 359.642 462.923 359.255 462.198 358.673C461.473 358.091 460.91 357.334 460.56 356.473Z" fill="#7F3E3B"/>
    <path opacity="0.1" d="M465.324 340.908L473.652 332.412L475.572 333.108C475.512 334.128 475.476 335.244 475.464 336.42C474.408 338.58 471.864 341.616 465.96 343.14C465.789 342.385 465.576 341.64 465.324 340.908Z" fill="black"/>
    <path d="M481.2 320.784C478.668 330.468 477.804 334.62 471.864 338.676C462.936 344.784 451.596 339.564 451.176 329.316C450.804 320.088 454.992 305.808 465.36 303.792C467.642 303.338 470.003 303.474 472.218 304.188C474.433 304.903 476.429 306.171 478.016 307.874C479.603 309.576 480.728 311.656 481.285 313.915C481.843 316.175 481.813 318.539 481.2 320.784Z" fill="#7F3E3B"/>
    <path d="M458.124 323.28C457.055 324.962 455.763 326.492 454.284 327.828C454.631 328.198 455.055 328.487 455.525 328.675C455.996 328.864 456.502 328.948 457.008 328.92L458.124 323.28Z" fill="#630F0F"/>
    <path d="M468.456 319.716C468.524 319.713 468.59 319.692 468.648 319.656C468.693 319.63 468.733 319.595 468.764 319.554C468.795 319.512 468.818 319.465 468.83 319.414C468.842 319.364 468.844 319.311 468.836 319.26C468.827 319.209 468.808 319.16 468.78 319.116C468.412 318.468 467.893 317.918 467.265 317.515C466.638 317.111 465.923 316.866 465.18 316.8C465.128 316.798 465.076 316.807 465.028 316.825C464.979 316.844 464.935 316.872 464.897 316.907C464.859 316.943 464.828 316.986 464.807 317.033C464.786 317.081 464.774 317.132 464.772 317.184C464.769 317.235 464.776 317.287 464.793 317.336C464.81 317.384 464.837 317.429 464.872 317.467C464.906 317.505 464.948 317.536 464.995 317.557C465.042 317.579 465.093 317.59 465.144 317.592C465.755 317.66 466.341 317.871 466.854 318.209C467.367 318.547 467.793 319.002 468.096 319.536C468.136 319.595 468.189 319.642 468.252 319.673C468.316 319.705 468.386 319.72 468.456 319.716Z" fill="#263238"/>
    <path d="M452.4 316.8C452.462 316.818 452.529 316.818 452.592 316.8C453.118 316.487 453.711 316.303 454.322 316.264C454.933 316.224 455.545 316.33 456.108 316.572C456.202 316.619 456.311 316.628 456.411 316.597C456.512 316.566 456.597 316.496 456.648 316.404C456.672 316.357 456.687 316.305 456.691 316.253C456.695 316.2 456.689 316.146 456.672 316.096C456.655 316.046 456.628 315.999 456.593 315.959C456.558 315.919 456.515 315.887 456.468 315.864C455.788 315.562 455.045 315.426 454.303 315.467C453.56 315.509 452.837 315.727 452.196 316.104C452.103 316.155 452.034 316.24 452.003 316.34C451.971 316.441 451.98 316.55 452.028 316.644C452.064 316.71 452.118 316.764 452.184 316.8C452.25 316.836 452.325 316.853 452.4 316.848V316.8Z" fill="#263238"/>
    <path d="M461.064 331.585C460.601 331.586 460.139 331.546 459.684 331.465C459.652 331.46 459.621 331.449 459.594 331.433C459.567 331.417 459.543 331.395 459.524 331.369C459.505 331.344 459.491 331.315 459.483 331.283C459.476 331.252 459.475 331.22 459.48 331.189C459.491 331.126 459.526 331.07 459.578 331.032C459.629 330.994 459.692 330.977 459.756 330.985C460.768 331.184 461.813 331.143 462.807 330.866C463.801 330.589 464.716 330.083 465.48 329.389C465.527 329.356 465.584 329.34 465.641 329.344C465.699 329.349 465.753 329.372 465.795 329.412C465.837 329.451 465.864 329.503 465.873 329.56C465.881 329.617 465.869 329.675 465.84 329.725C464.546 330.935 462.835 331.601 461.064 331.585Z" fill="#263238"/>
    <path d="M477.876 328.356C476.704 327.026 475.835 325.457 475.327 323.759C474.82 322.06 474.686 320.271 474.936 318.516C474.936 318.516 471.036 314.52 471.228 310.248C471.228 310.248 470.028 313.656 471.228 316.08C471.228 316.08 461.112 315.348 461.352 310.248C461.352 310.248 460.68 312.816 462.204 314.148C462.204 314.148 453.216 314.52 454.44 309.864C455.664 305.208 462.696 302.94 462.696 302.94C462.696 302.94 469.404 297.24 478.68 307.308C478.68 307.308 489.6 314.124 481.86 325.5L477.876 328.356Z" fill="#263238"/>
    <path d="M481.932 328.992C481.493 329.849 480.887 330.609 480.149 331.227C479.411 331.845 478.556 332.31 477.636 332.592C475.236 333.336 473.904 331.152 474.54 328.812C475.116 326.7 477.084 323.772 479.58 324.012C480.446 324.139 481.225 324.605 481.747 325.308C482.269 326.01 482.491 326.89 482.364 327.756C482.307 328.193 482.16 328.614 481.932 328.992Z" fill="#7F3E3B"/>
    <path d="M465.408 321.9C465.312 322.728 464.796 323.364 464.208 323.304C463.62 323.244 463.296 322.536 463.392 321.684C463.488 320.832 463.992 320.232 464.532 320.28C465.072 320.328 465.492 321.06 465.408 321.9Z" fill="#2E353A"/>
    <path d="M456 320.916C455.916 321.744 455.4 322.368 454.86 322.32C454.32 322.272 453.936 321.54 454.02 320.7C454.104 319.86 454.62 319.236 455.22 319.296C455.82 319.356 456 320.064 456 320.916Z" fill="#2E353A"/>
    <path d="M455.34 319.368L453.396 318.576C453.396 318.576 454.26 320.244 455.34 319.368Z" fill="#2E353A"/>
    <path d="M464.808 320.4L462.852 319.62C462.852 319.62 463.728 321.24 464.808 320.4Z" fill="#2E353A"/>
    <path d="M477.036 337.092C477.664 337.094 478.28 336.912 478.807 336.569C479.335 336.227 479.751 335.738 480.005 335.163C480.26 334.588 480.341 333.952 480.24 333.331C480.139 332.711 479.859 332.133 479.436 331.668L479.016 332.052C479.467 332.542 479.716 333.186 479.712 333.852C479.714 334.383 479.559 334.902 479.266 335.344C478.972 335.785 478.555 336.13 478.065 336.334C477.576 336.538 477.037 336.592 476.517 336.489C475.996 336.387 475.518 336.131 475.143 335.756C474.768 335.382 474.513 334.904 474.41 334.383C474.308 333.863 474.362 333.324 474.566 332.835C474.77 332.345 475.115 331.927 475.556 331.634C475.998 331.341 476.517 331.186 477.048 331.188C477.424 331.185 477.796 331.263 478.14 331.416L478.368 330.9C477.954 330.709 477.503 330.61 477.048 330.612C476.622 330.612 476.201 330.696 475.808 330.859C475.415 331.022 475.057 331.26 474.757 331.561C474.456 331.862 474.217 332.219 474.054 332.612C473.891 333.006 473.808 333.427 473.808 333.852C473.808 334.278 473.891 334.699 474.054 335.092C474.217 335.485 474.456 335.843 474.757 336.143C475.057 336.444 475.415 336.683 475.808 336.846C476.201 337.009 476.622 337.092 477.048 337.092H477.036Z" fill="#6D1ED4"/>
    <g opacity="0.6">
    <path d="M477.036 337.092C477.664 337.094 478.28 336.912 478.807 336.569C479.335 336.227 479.751 335.738 480.005 335.163C480.26 334.588 480.341 333.952 480.24 333.331C480.139 332.711 479.859 332.133 479.436 331.668L479.016 332.052C479.467 332.542 479.716 333.186 479.712 333.852C479.714 334.383 479.559 334.902 479.266 335.344C478.972 335.785 478.555 336.13 478.065 336.334C477.576 336.538 477.037 336.592 476.517 336.489C475.996 336.387 475.518 336.131 475.143 335.756C474.768 335.382 474.513 334.904 474.41 334.383C474.308 333.863 474.362 333.324 474.566 332.835C474.77 332.345 475.115 331.927 475.556 331.634C475.998 331.341 476.517 331.186 477.048 331.188C477.424 331.185 477.796 331.263 478.14 331.416L478.368 330.9C477.954 330.709 477.503 330.61 477.048 330.612C476.622 330.612 476.201 330.696 475.808 330.859C475.415 331.022 475.057 331.26 474.757 331.561C474.456 331.862 474.217 332.219 474.054 332.612C473.891 333.006 473.808 333.427 473.808 333.852C473.808 334.278 473.891 334.699 474.054 335.092C474.217 335.485 474.456 335.843 474.757 336.143C475.057 336.444 475.415 336.683 475.808 336.846C476.201 337.009 476.622 337.092 477.048 337.092H477.036Z" fill="white"/>
    </g>
    <path d="M474.804 386.1L465.996 386.484L463.044 381.684C462.855 381.375 462.597 381.113 462.292 380.917C461.986 380.722 461.641 380.599 461.28 380.556L460.704 380.484C460.23 380.422 459.749 380.503 459.321 380.717C458.893 380.931 458.539 381.268 458.304 381.684L456.12 385.512C455.897 385.9 455.786 386.343 455.801 386.79C455.816 387.237 455.956 387.672 456.204 388.044L457.224 389.592C457.469 389.968 457.798 390.281 458.186 390.506C458.573 390.732 459.009 390.862 459.456 390.888L463.956 391.14L473.916 392.484L474.804 386.1Z" fill="#7F3E3B"/>
    <path d="M490.464 354.001L494.844 385.405H469.296L468.684 392.833C468.684 392.833 503.784 399.637 505.452 393.577C507.12 387.517 507.732 368.233 505.212 360.493C503.928 356.593 500.16 352.093 492.912 351.433C492.572 351.403 492.23 351.449 491.911 351.569C491.592 351.69 491.304 351.88 491.069 352.127C490.834 352.374 490.657 352.67 490.552 352.995C490.447 353.319 490.417 353.663 490.464 354.001Z" fill="#6D1ED4"/>
    <path d="M378.828 172.8H350.784L322.512 496.08H350.556C356.963 495.963 363.105 493.503 367.822 489.165C372.538 484.827 375.501 478.911 376.152 472.536L400.308 196.356C400.641 193.357 400.331 190.322 399.398 187.453C398.465 184.583 396.931 181.946 394.898 179.717C392.866 177.487 390.381 175.717 387.609 174.524C384.838 173.332 381.844 172.744 378.828 172.8Z" fill="#263238"/>
    <path d="M396.203 205.2C396.264 204.597 396.54 204.037 396.981 203.622C397.422 203.208 397.998 202.967 398.603 202.944C398.892 202.939 399.179 202.995 399.445 203.109C399.711 203.224 399.949 203.393 400.144 203.606C400.339 203.82 400.487 204.072 400.577 204.347C400.667 204.621 400.698 204.912 400.667 205.2L398.771 226.8C398.742 227.429 398.478 228.024 398.032 228.468C397.585 228.912 396.989 229.173 396.359 229.2C396.071 229.203 395.786 229.145 395.522 229.03C395.257 228.915 395.021 228.746 394.827 228.532C394.633 228.319 394.486 228.068 394.397 227.794C394.307 227.52 394.277 227.23 394.307 226.944L396.203 205.2Z" fill="#6D1ED4"/>
    <path opacity="0.3" d="M396.203 205.2C396.264 204.597 396.54 204.037 396.981 203.622C397.422 203.208 397.998 202.967 398.603 202.944C398.892 202.939 399.179 202.995 399.445 203.109C399.711 203.224 399.949 203.393 400.144 203.606C400.339 203.82 400.487 204.072 400.577 204.347C400.667 204.621 400.698 204.912 400.667 205.2L398.771 226.8C398.742 227.429 398.478 228.024 398.032 228.468C397.585 228.912 396.989 229.173 396.359 229.2C396.071 229.203 395.786 229.145 395.522 229.03C395.257 228.915 395.021 228.746 394.827 228.532C394.633 228.319 394.486 228.068 394.397 227.794C394.307 227.52 394.277 227.23 394.307 226.944L396.203 205.2Z" fill="white"/>
    <path d="M344.807 496.079H229.259C226.245 496.133 223.253 495.544 220.483 494.351C217.714 493.159 215.231 491.39 213.198 489.162C211.166 486.934 209.632 484.299 208.697 481.433C207.763 478.566 207.45 475.533 207.779 472.535L231.947 196.355C232.59 189.976 235.551 184.054 240.268 179.713C244.986 175.371 251.133 172.911 257.543 172.799H373.091C376.105 172.746 379.096 173.334 381.864 174.526C384.633 175.718 387.116 177.486 389.148 179.713C391.18 181.939 392.714 184.572 393.65 187.438C394.585 190.303 394.899 193.335 394.571 196.331L370.403 472.535C369.753 478.91 366.79 484.826 362.073 489.165C357.357 493.503 351.215 495.963 344.807 496.079Z" fill="#6D1ED4"/>
    <path d="M372.623 178.223H342.623C341.646 178.242 340.704 178.588 339.947 179.206C339.19 179.824 338.662 180.678 338.447 181.631L338.243 182.579C337.722 184.909 336.433 186.997 334.584 188.507C332.734 190.017 330.431 190.863 328.043 190.907H299.459C297.198 190.956 295.01 190.107 293.373 188.546C291.736 186.986 290.783 184.84 290.723 182.579V181.631C290.711 181.171 290.608 180.718 290.42 180.297C290.233 179.877 289.964 179.498 289.63 179.181C289.295 178.865 288.902 178.617 288.472 178.453C288.042 178.289 287.584 178.21 287.123 178.223H257.123C252.189 178.315 247.459 180.21 243.827 183.55C240.194 186.891 237.911 191.446 237.407 196.355L213.179 472.535C212.925 474.845 213.166 477.182 213.885 479.391C214.605 481.6 215.787 483.631 217.352 485.348C218.918 487.064 220.831 488.428 222.965 489.347C225.099 490.266 227.404 490.72 229.727 490.679H345.287C350.223 490.587 354.955 488.691 358.588 485.348C362.22 482.005 364.503 477.447 365.003 472.535L389.171 196.355C389.424 194.047 389.182 191.711 388.462 189.503C387.741 187.295 386.559 185.266 384.993 183.551C383.428 181.836 381.515 180.473 379.382 179.555C377.249 178.636 374.945 178.182 372.623 178.223Z" fill="#6D1ED4"/>
    <path opacity="0.7" d="M372.623 178.223H342.623C341.646 178.242 340.704 178.588 339.947 179.206C339.19 179.824 338.662 180.678 338.447 181.631L338.243 182.579C337.722 184.909 336.433 186.997 334.584 188.507C332.734 190.017 330.431 190.863 328.043 190.907H299.459C297.198 190.956 295.01 190.107 293.373 188.546C291.736 186.986 290.783 184.84 290.723 182.579V181.631C290.711 181.171 290.608 180.718 290.42 180.297C290.233 179.877 289.964 179.498 289.63 179.181C289.295 178.865 288.902 178.617 288.472 178.453C288.042 178.289 287.584 178.21 287.123 178.223H257.123C252.189 178.315 247.459 180.21 243.827 183.55C240.194 186.891 237.911 191.446 237.407 196.355L213.179 472.535C212.925 474.845 213.166 477.182 213.885 479.391C214.605 481.6 215.787 483.631 217.352 485.348C218.918 487.064 220.831 488.428 222.965 489.347C225.099 490.266 227.404 490.72 229.727 490.679H345.287C350.223 490.587 354.955 488.691 358.588 485.348C362.22 482.005 364.503 477.447 365.003 472.535L389.171 196.355C389.424 194.047 389.182 191.711 388.462 189.503C387.741 187.295 386.559 185.266 384.993 183.551C383.428 181.836 381.515 180.473 379.382 179.555C377.249 178.636 374.945 178.182 372.623 178.223Z" fill="white"/>
    <g opacity="0.2">
    <path d="M306.802 182.858C307.76 181.941 307.856 180.488 307.017 179.612C306.178 178.736 304.722 178.769 303.765 179.685C302.807 180.602 302.711 182.055 303.55 182.931C304.388 183.807 305.845 183.774 306.802 182.858Z" fill="black"/>
    <path d="M324.059 178.992H316.187C315.563 179.003 314.964 179.242 314.505 179.665C314.045 180.087 313.755 180.663 313.691 181.284C313.66 181.576 313.692 181.871 313.784 182.15C313.876 182.43 314.027 182.686 314.225 182.903C314.424 183.119 314.666 183.291 314.936 183.407C315.206 183.523 315.497 183.581 315.791 183.576H323.663C324.286 183.562 324.883 183.322 325.343 182.9C325.802 182.478 326.092 181.904 326.159 181.284C326.192 180.991 326.161 180.695 326.07 180.415C325.979 180.135 325.828 179.878 325.63 179.661C325.431 179.444 325.188 179.272 324.917 179.156C324.646 179.041 324.353 178.985 324.059 178.992Z" fill="black"/>
    </g>
    <path d="M250.403 190.872H252.827L253.043 188.436H250.607L250.403 190.872Z" fill="#6D1ED4"/>
    <path d="M255.48 188.436L255.264 190.872H257.688L257.904 188.436L258.12 186.012H255.684L255.48 188.436Z" fill="#6D1ED4"/>
    <path d="M260.76 183.575L260.544 186.011L260.328 188.435L260.124 190.871H262.548L262.764 188.435L262.98 186.011L263.184 183.575H260.76Z" fill="#6D1ED4"/>
    <path d="M365.244 183.743L365.088 185.519H363.888L363.576 189.119H364.776L364.62 190.907H375.312L375.936 183.779L365.244 183.743ZM369.528 189.083H365.928L366.24 185.483H369.84L369.528 189.083Z" fill="#6D1ED4"/>
    <g opacity="0.4">
    <path d="M299.543 190.908L219.779 487.512C222.675 489.598 226.159 490.708 229.727 490.68H235.967L316.583 190.908H299.543Z" fill="white"/>
    <path d="M372.623 178.224H342.623C341.646 178.243 340.704 178.589 339.947 179.207C339.19 179.825 338.662 180.679 338.447 181.632L338.243 182.58C337.736 184.848 336.501 186.888 334.727 188.39C332.952 189.891 330.736 190.771 328.415 190.896L247.751 490.68H309.251L389.171 193.488C388.915 189.3 387.05 185.373 383.966 182.529C380.882 179.684 376.818 178.142 372.623 178.224Z" fill="white"/>
    </g>
    <path d="M251.892 258.72L248.292 257.94C248.06 257.894 247.84 257.798 247.649 257.659C247.458 257.52 247.3 257.34 247.185 257.133C247.071 256.926 247.003 256.697 246.987 256.461C246.971 256.225 247.007 255.988 247.092 255.768L252.12 242.64L257.868 243.84L253.776 257.556C253.668 257.953 253.413 258.294 253.064 258.51C252.714 258.726 252.295 258.801 251.892 258.72Z" fill="#6D1ED4"/>
    <path d="M265.836 241.92C265.599 241.868 265.375 241.771 265.176 241.633C264.977 241.494 264.807 241.318 264.677 241.114C264.546 240.91 264.457 240.682 264.415 240.443C264.373 240.205 264.379 239.96 264.432 239.724L264.912 237.468C264.963 237.23 265.061 237.004 265.2 236.804C265.339 236.604 265.517 236.434 265.722 236.303C265.928 236.173 266.157 236.084 266.397 236.043C266.637 236.002 266.883 236.009 267.12 236.064C267.599 236.167 268.017 236.457 268.283 236.868C268.548 237.28 268.639 237.781 268.536 238.26L268.044 240.504C267.938 240.983 267.646 241.402 267.232 241.667C266.818 241.932 266.317 242.023 265.836 241.92Z" fill="#6D1ED4"/>
    <path d="M262.284 230.736C262.284 230.736 248.604 229.176 243.816 226.728C232.56 220.944 221.796 207.144 219.516 206.652C215.796 205.836 210.732 214.644 208.188 226.32C205.644 237.996 206.604 248.1 210.324 248.904C212.616 249.408 228.12 241.32 240.768 240.732C246.144 240.492 259.2 244.8 259.2 244.8L262.284 230.736Z" fill="#6D1ED4"/>
    <path opacity="0.3" d="M262.284 230.736C262.284 230.736 248.604 229.176 243.816 226.728C232.56 220.944 221.796 207.144 219.516 206.652C215.796 205.836 210.732 214.644 208.188 226.32C205.644 237.996 206.604 248.1 210.324 248.904C212.616 249.408 228.12 241.32 240.768 240.732C246.144 240.492 259.2 244.8 259.2 244.8L262.284 230.736Z" fill="white"/>
    <path d="M221.651 229.199C219.119 240.863 214.043 249.671 210.323 248.855C206.603 248.039 205.655 237.935 208.187 226.271C210.719 214.607 215.795 205.787 219.515 206.603C223.235 207.419 224.195 217.571 221.651 229.199Z" fill="#6D1ED4"/>
    <path opacity="0.4" d="M221.651 229.199C219.119 240.863 214.043 249.671 210.323 248.855C206.603 248.039 205.655 237.935 208.187 226.271C210.719 214.607 215.795 205.787 219.515 206.603C223.235 207.419 224.195 217.571 221.651 229.199Z" fill="black"/>
    <path d="M260.064 229.2L253.716 227.82C253.307 227.729 252.884 227.724 252.474 227.805C252.063 227.885 251.674 228.049 251.329 228.287C250.985 228.524 250.693 228.83 250.472 229.185C250.25 229.541 250.105 229.938 250.044 230.352C249.816 231.948 249.528 233.592 249.168 235.272C248.808 236.952 248.376 238.572 247.968 240.072C247.851 240.474 247.819 240.897 247.873 241.313C247.927 241.729 248.065 242.129 248.28 242.489C248.495 242.849 248.782 243.161 249.122 243.406C249.463 243.651 249.85 243.823 250.26 243.912L257.784 245.544C262.296 246.528 265.548 243.348 266.46 238.812C266.868 236.691 266.426 234.494 265.229 232.696C264.033 230.898 262.178 229.642 260.064 229.2Z" fill="#263238"/>
    <path d="M215.124 226.86L214.728 228.696C214.613 229.209 214.683 229.746 214.927 230.212C215.171 230.678 215.572 231.042 216.06 231.24L220.668 233.16C221.028 231.9 221.364 230.592 221.652 229.248C221.94 227.904 222.192 226.56 222.396 225.264L217.392 225.096C216.867 225.078 216.353 225.245 215.938 225.567C215.524 225.89 215.236 226.347 215.124 226.86Z" fill="#263238"/>
    <path opacity="0.8" d="M215.124 226.86L214.728 228.696C214.613 229.209 214.683 229.746 214.927 230.212C215.171 230.678 215.572 231.042 216.06 231.24L220.668 233.16C221.028 231.9 221.364 230.592 221.652 229.248C221.94 227.904 222.192 226.56 222.396 225.264L217.392 225.096C216.867 225.078 216.353 225.245 215.938 225.567C215.524 225.89 215.236 226.347 215.124 226.86Z" fill="white"/>
    <path opacity="0.2" d="M218.603 228.575C218.351 229.775 218.075 230.855 217.775 231.959L220.667 233.159C221.027 231.899 221.363 230.591 221.651 229.247C221.939 227.903 222.191 226.559 222.395 225.263L219.251 225.155C219.075 226.251 218.859 227.391 218.603 228.575Z" fill="black"/>
    <path d="M329.472 229.296C330.618 229.735 331.852 229.895 333.073 229.764C334.293 229.632 335.465 229.213 336.492 228.54C340.176 226.14 347.088 229.164 345 238.992C341.4 255.888 333.888 268.02 338.76 273.132C338.76 273.132 322.536 273.876 324.936 259.548C326.736 248.748 338.94 236.604 336 234.18C335.016 233.364 333.6 233.784 328.98 233.784L329.472 229.296Z" fill="#263238"/>
    <path d="M328.979 233.784C331.203 233.71 333.425 233.978 335.568 234.576C336.324 232.401 336.618 230.091 336.431 227.796C334.068 228.067 331.737 228.57 329.471 229.296L328.979 233.784Z" fill="#6D1ED4"/>
    <path d="M313.704 216.984C313.704 216.984 306.96 215.064 311.412 210.708C317.22 205.032 325.476 215.664 325.476 215.664C325.476 215.664 344.832 224.124 328.86 243.744C328.86 243.744 323.088 254.064 321.888 250.044C320.688 246.024 328.704 234.636 328.704 234.636L328.224 224.436L326.004 220.464L319.032 216.48L313.704 216.984Z" fill="#263238"/>
    <path d="M286.956 274.451C286.498 273.703 285.898 273.053 285.189 272.537C284.48 272.021 283.676 271.65 282.823 271.444C281.971 271.239 281.086 271.204 280.22 271.342C279.354 271.479 278.523 271.785 277.776 272.243H277.68C275.58 273.527 273.348 274.955 271.14 276.275L264.48 280.271C261.36 282.103 258.224 283.883 255.072 285.611C254.891 283.216 254.851 280.812 254.952 278.411C255.036 275.603 255.24 272.735 255.456 269.867C255.672 266.999 255.96 264.059 256.2 261.215V261.143C256.26 260.344 256.031 259.549 255.554 258.905C255.077 258.261 254.383 257.81 253.601 257.635C252.818 257.46 251.999 257.573 251.293 257.953C250.587 258.333 250.041 258.954 249.756 259.703C248.688 262.595 247.848 265.451 247.008 268.379C246.168 271.307 245.532 274.259 244.968 277.307C244.352 280.472 244.015 283.684 243.96 286.907C243.912 288.936 244.175 290.959 244.74 292.907C245.254 294.65 246.253 296.21 247.62 297.407C248.656 298.283 249.893 298.889 251.22 299.171C252.377 299.396 253.566 299.396 254.724 299.171C255.002 299.11 255.275 299.025 255.54 298.919L255.936 298.763C258.492 297.707 261.036 296.651 263.508 295.451C265.98 294.251 268.452 293.051 270.864 291.791C273.276 290.531 275.664 289.175 278.064 287.771C280.464 286.367 282.696 284.963 285.06 283.331C286.433 282.36 287.391 280.907 287.743 279.262C288.094 277.616 287.813 275.899 286.956 274.451Z" fill="#CE7A63"/>
    <path d="M292.8 265.692C296.844 273.84 291.024 280.908 280.356 292.236C274.784 287.718 270.892 281.459 269.304 274.464C269.304 274.464 281.64 264.288 292.8 265.692Z" fill="#6D1ED4"/>
    <path opacity="0.2" d="M293.22 275.363C289.272 272.963 284.82 271.883 281.736 275.807C279.328 278.967 276.539 281.817 273.432 284.291C275.321 287.288 277.67 289.968 280.392 292.235C286.8 285.467 291.384 280.223 293.22 275.363Z" fill="black"/>
    <path d="M253.2 246L255.936 247.812C256.446 248.144 256.869 248.595 257.168 249.126C257.466 249.656 257.632 250.251 257.652 250.86L257.868 256.632C257.904 257.768 257.67 258.896 257.186 259.924C256.702 260.952 255.982 261.852 255.084 262.548L253.944 263.424C249.144 260.724 246.036 254.316 246.036 254.316L244.932 252.588C244.695 252.24 244.53 251.848 244.446 251.436C244.362 251.023 244.36 250.598 244.442 250.185C244.523 249.772 244.686 249.379 244.921 249.029C245.155 248.68 245.457 248.38 245.808 248.148L249.192 245.916C249.797 245.541 250.497 245.35 251.208 245.365C251.919 245.38 252.611 245.6 253.2 246Z" fill="#CE7A63"/>
    <path d="M283.428 490.68C289.644 471.48 293.892 450.156 289.668 434.592C315.348 359.16 303.768 337.2 303.768 337.2L273.204 326.052C273.204 326.052 268.836 393.984 267.516 426.852C266.784 445.224 263.652 469.116 260.04 490.692L283.428 490.68Z" fill="#263238"/>
    <path opacity="0.5" d="M299.279 365.112L293.075 355.104L289.283 362.904C287.305 384.411 288.242 406.087 292.067 427.343C297.011 412.497 300.916 397.324 303.755 381.935L299.279 365.112Z" fill="black"/>
    <path d="M302.208 490.679H325.38C327.78 470.723 327.78 449.255 320.7 434.903C331.5 355.955 315.9 336.623 315.9 336.623L283.764 331.535C283.764 331.535 292.488 399.047 297.492 431.531C300.072 448.355 301.536 470.183 302.208 490.679Z" fill="#263238"/>
    <path d="M278.496 280.86C277.139 286.701 276.177 292.626 275.616 298.596C274.584 307.296 273.828 315.888 272.832 330.252L316.344 337.2C317.412 324.816 321.048 311.076 333.228 284.736C333.916 283.366 334.289 281.859 334.32 280.326C334.351 278.793 334.038 277.273 333.405 275.877C332.773 274.48 331.836 273.243 330.663 272.255C329.49 271.267 328.112 270.554 326.628 270.168C324.936 269.724 323.136 269.292 321.276 268.896C314.771 267.388 308.166 266.345 301.512 265.776C299.34 265.632 297.072 265.548 294.9 265.5C287.052 265.296 279.948 273.24 278.496 280.86Z" fill="#6D1ED4"/>
    <path d="M323.399 246.371C320.675 252.455 316.727 263.771 320.183 268.667C320.183 268.667 314.303 266.807 300.275 272.687C297.407 266.795 300.431 265.487 300.431 265.487C307.907 264.947 308.951 259.715 308.831 254.687L323.399 246.371Z" fill="#CE7A63"/>
    <path d="M303.216 271.523C303.97 271.801 304.656 272.237 305.228 272.802C305.799 273.367 306.242 274.048 306.528 274.799C306.767 275.346 307.118 275.835 307.56 276.236C308.001 276.636 308.522 276.939 309.089 277.124C309.656 277.308 310.255 277.371 310.848 277.308C311.44 277.244 312.013 277.056 312.528 276.755C316.128 274.727 320.664 271.835 322.932 269.267C321.757 268.373 320.631 267.416 319.56 266.399C319.56 266.399 306.06 269.483 303.216 271.523Z" fill="white"/>
    <path d="M299.316 270.216C298.289 270.382 297.318 270.794 296.484 271.416C296.253 271.584 295.974 271.675 295.688 271.675C295.402 271.675 295.123 271.585 294.892 271.417C294.66 271.249 294.487 271.013 294.398 270.741C294.309 270.469 294.309 270.176 294.396 269.904C295.896 265.656 298.488 265.104 298.488 265.104C299.74 264.627 301.124 264.627 302.376 265.104C301.338 265.447 300.458 266.153 299.896 267.091C299.334 268.029 299.128 269.138 299.316 270.216Z" fill="white"/>
    <path opacity="0.2" d="M317.472 249.768L308.88 254.688C308.92 255.892 308.848 257.097 308.664 258.288C311.472 258.288 315.864 255.984 316.824 253.236C317.252 252.13 317.472 250.954 317.472 249.768Z" fill="black"/>
    <path d="M330.852 234.48C327.252 244.44 326.052 248.712 319.368 252.708C309.396 258.708 297.912 252.888 298.404 242.244C298.86 232.644 304.596 217.98 315.708 216.264C318.083 215.815 320.534 216.01 322.808 216.828C325.082 217.646 327.096 219.057 328.64 220.915C330.185 222.774 331.205 225.011 331.594 227.396C331.983 229.781 331.727 232.227 330.852 234.48Z" fill="#CE7A63"/>
    <path d="M317.472 234.108C317.208 234.96 316.56 235.548 316.008 235.416C315.456 235.284 315.228 234.492 315.48 233.64C315.732 232.788 316.404 232.212 316.956 232.344C317.508 232.476 317.736 233.268 317.472 234.108Z" fill="#263238"/>
    <path d="M306.852 231.276C306.588 232.116 305.928 232.704 305.376 232.572C304.824 232.44 304.596 231.648 304.86 230.808C305.124 229.968 305.784 229.368 306.336 229.5C306.888 229.632 307.116 230.4 306.852 231.276Z" fill="#263238"/>
    <path d="M306.564 229.608L304.716 228.54C304.716 228.54 305.268 230.4 306.564 229.608Z" fill="#263238"/>
    <path d="M309.936 233.027C308.584 234.789 307.011 236.37 305.256 237.731C305.603 238.141 306.038 238.468 306.528 238.688C307.018 238.908 307.551 239.016 308.088 239.003L309.936 233.027Z" fill="#BA4D3C"/>
    <path d="M320.699 229.199C320.631 229.193 320.566 229.172 320.507 229.136C320.449 229.101 320.4 229.053 320.363 228.995C320.036 228.458 319.578 228.011 319.033 227.697C318.488 227.382 317.872 227.21 317.243 227.195C317.178 227.197 317.112 227.185 317.051 227.161C316.99 227.137 316.935 227.101 316.888 227.055C316.841 227.009 316.803 226.955 316.778 226.894C316.752 226.834 316.739 226.769 316.739 226.703C316.748 226.563 316.807 226.43 316.907 226.331C317.006 226.231 317.139 226.172 317.279 226.163C318.074 226.177 318.854 226.388 319.547 226.778C320.24 227.167 320.826 227.723 321.251 228.395C321.298 228.484 321.319 228.584 321.313 228.684C321.307 228.784 321.274 228.88 321.217 228.963C321.16 229.046 321.082 229.111 320.991 229.153C320.9 229.195 320.799 229.21 320.699 229.199Z" fill="#263238"/>
    <path d="M304.572 224.148C304.512 224.124 304.458 224.088 304.413 224.043C304.367 223.997 304.332 223.943 304.308 223.884C304.266 223.755 304.276 223.615 304.337 223.493C304.397 223.372 304.503 223.28 304.632 223.236C305.4 222.913 306.233 222.77 307.065 222.82C307.897 222.87 308.707 223.111 309.432 223.524C309.485 223.562 309.531 223.61 309.565 223.667C309.6 223.723 309.622 223.786 309.631 223.851C309.64 223.916 309.635 223.983 309.618 224.046C309.6 224.11 309.569 224.169 309.528 224.22C309.444 224.329 309.323 224.403 309.187 224.428C309.052 224.452 308.912 224.425 308.796 224.352C308.217 224.029 307.572 223.843 306.91 223.81C306.248 223.776 305.588 223.896 304.98 224.16C304.914 224.185 304.844 224.197 304.774 224.195C304.704 224.193 304.635 224.177 304.572 224.148Z" fill="#263238"/>
    <path d="M317.184 232.452L315.348 231.384C315.348 231.384 315.888 233.22 317.184 232.452Z" fill="#263238"/>
    <path d="M277.608 313.572H267.24C266.736 313.55 266.253 313.365 265.863 313.044C265.474 312.724 265.199 312.286 265.08 311.796L260.28 289.332C260.218 289.121 260.208 288.898 260.25 288.682C260.292 288.467 260.385 288.264 260.521 288.092C260.657 287.919 260.833 287.781 261.033 287.69C261.233 287.599 261.452 287.557 261.672 287.568H272.04C272.542 287.589 273.024 287.773 273.413 288.091C273.802 288.409 274.078 288.844 274.2 289.332L279 311.796C279.061 312.008 279.07 312.231 279.028 312.447C278.986 312.663 278.893 312.866 278.757 313.04C278.622 313.213 278.447 313.352 278.247 313.444C278.047 313.537 277.828 313.581 277.608 313.572Z" fill="#263238"/>
    <path d="M276.407 313.572H266.039C265.536 313.55 265.053 313.365 264.663 313.044C264.273 312.724 263.998 312.286 263.879 311.796L259.079 289.332C259.018 289.121 259.008 288.898 259.05 288.682C259.092 288.467 259.185 288.264 259.321 288.092C259.457 287.919 259.633 287.781 259.833 287.69C260.033 287.599 260.252 287.557 260.471 287.568H270.839C271.342 287.589 271.823 287.773 272.213 288.091C272.602 288.409 272.878 288.844 272.999 289.332L277.799 311.796C277.86 312.008 277.87 312.231 277.828 312.447C277.786 312.663 277.693 312.866 277.557 313.04C277.421 313.213 277.246 313.352 277.046 313.444C276.847 313.537 276.628 313.581 276.407 313.572Z" fill="#263238"/>
    <path opacity="0.2" d="M276.407 313.572H266.039C265.536 313.55 265.053 313.365 264.663 313.044C264.273 312.724 263.998 312.286 263.879 311.796L259.079 289.332C259.018 289.121 259.008 288.898 259.05 288.682C259.092 288.467 259.185 288.264 259.321 288.092C259.457 287.919 259.633 287.781 259.833 287.69C260.033 287.599 260.252 287.557 260.471 287.568H270.839C271.342 287.589 271.823 287.773 272.213 288.091C272.602 288.409 272.878 288.844 272.999 289.332L277.799 311.796C277.86 312.008 277.87 312.231 277.828 312.447C277.786 312.663 277.693 312.866 277.557 313.04C277.421 313.213 277.246 313.352 277.046 313.444C276.847 313.537 276.628 313.581 276.407 313.572Z" fill="white"/>
    <path d="M272.688 300.336L275.904 299.628C276.498 299.496 277.115 299.508 277.703 299.662C278.292 299.817 278.835 300.109 279.288 300.516L283.584 304.38C284.426 305.144 285.075 306.098 285.477 307.161C285.88 308.225 286.025 309.369 285.9 310.5L285.732 311.916C280.488 313.464 273.732 311.28 273.732 311.28L271.716 310.872C271.301 310.799 270.904 310.646 270.549 310.419C270.194 310.192 269.888 309.897 269.648 309.551C269.408 309.205 269.239 308.815 269.151 308.403C269.063 307.991 269.058 307.566 269.136 307.152L269.892 303.156C270.04 302.468 270.382 301.836 270.877 301.337C271.373 300.837 272.001 300.49 272.688 300.336Z" fill="#CE7A63"/>
    <path d="M327.996 276.971C326.236 276.804 324.482 277.342 323.119 278.467C321.755 279.592 320.894 281.212 320.724 282.971V283.199C320.604 284.399 320.448 285.863 320.292 287.219C320.136 288.575 319.944 289.979 319.74 291.359C319.332 294.131 318.84 296.867 318.288 299.579C317.736 302.291 317.088 304.955 316.308 307.559C315.672 309.647 314.94 311.663 314.16 313.631C313.192 313.67 312.223 313.61 311.268 313.451C308.169 312.985 305.106 312.308 302.1 311.423C299.28 310.535 296.436 309.503 293.592 308.423C290.748 307.343 287.88 306.179 285.084 305.063H285.012C284.26 304.75 283.419 304.725 282.649 304.991C281.879 305.257 281.234 305.797 280.835 306.507C280.437 307.218 280.314 308.05 280.489 308.846C280.663 309.641 281.124 310.345 281.784 310.823C284.424 312.767 287.088 314.423 289.836 316.151C292.584 317.879 295.428 319.355 298.392 320.783C301.804 322.279 305.337 323.483 308.952 324.383C310.907 324.923 312.923 325.213 314.952 325.247C316.31 325.263 317.663 325.065 318.96 324.659C320.778 324.103 322.401 323.042 323.64 321.599C323.855 321.332 324.048 321.047 324.216 320.747L324.336 320.519C325.887 317.662 327.235 314.699 328.368 311.651C329.461 308.68 330.39 305.652 331.152 302.579C331.932 299.543 332.532 296.483 333.024 293.411C333.252 291.863 333.456 290.327 333.636 288.779C333.816 287.231 333.948 285.731 334.032 283.979C334.123 282.258 333.546 280.569 332.422 279.263C331.297 277.957 329.711 277.136 327.996 276.971Z" fill="#CE7A63"/>
    <path d="M330 271.668C337.416 276.912 337.776 284.76 333.768 299.784C328.404 302.472 315.372 299.076 315.372 299.076C315.372 299.076 319.476 275.748 330 271.668Z" fill="#6D1ED4"/>
    <path d="M303.312 241.883C303.312 241.883 306.912 241.175 316.692 241.475C315.492 244.403 313.236 246.731 308.184 246.575C306.972 246.654 305.777 246.249 304.864 245.448C303.95 244.647 303.392 243.516 303.312 242.303C303.312 242.159 303.3 242.027 303.312 241.883Z" fill="#263238"/>
    <path d="M310.584 244.283C308.647 244.119 306.708 244.568 305.04 245.567C305.961 246.201 307.054 246.54 308.172 246.539C310.106 246.709 312.043 246.259 313.704 245.255C312.793 244.609 311.701 244.269 310.584 244.283Z" fill="#BA4D3C"/>
    <path d="M303.756 241.823C303.846 242.186 304.033 242.518 304.297 242.782C304.561 243.047 304.893 243.234 305.256 243.323C308.177 243.406 311.099 243.241 313.992 242.831C314.453 242.801 314.9 242.658 315.293 242.416C315.686 242.173 316.014 241.838 316.248 241.439C312.08 241.237 307.903 241.366 303.756 241.823Z" fill="white"/>
    <path d="M315.792 215.604C315.163 215.7 314.541 215.84 313.932 216.024V216.6C315.51 216.579 317.082 216.809 318.588 217.284C326.892 219.816 328.188 224.484 325.404 231.384C324.072 234.672 323.352 241.644 324.888 242.952C325.284 243.288 326.484 243.24 328.044 242.952C329.076 240.552 330.06 237.756 331.32 234.228C332.206 231.921 332.461 229.419 332.057 226.98C331.653 224.542 330.606 222.256 329.023 220.357C327.44 218.459 325.38 217.018 323.054 216.182C320.728 215.346 318.221 215.147 315.792 215.604Z" fill="#263238"/>
    <path d="M331.799 243.599C331.03 244.403 330.109 245.046 329.089 245.491C328.07 245.936 326.972 246.174 325.859 246.191C322.979 246.191 322.523 243.215 324.143 240.743C325.595 238.523 328.835 235.847 331.343 237.059C333.851 238.271 333.791 241.547 331.799 243.599Z" fill="#CE7A63"/>
    <g opacity="0.9">
    <path d="M314.1 294.576C293.892 293.376 284.772 289.596 281.136 287.484C281.019 287.421 280.91 287.345 280.812 287.256C279.768 286.344 281.088 284.676 282.3 285.372C285.48 287.208 294.24 290.94 314.244 292.176C314.56 292.194 314.856 292.337 315.068 292.573C315.279 292.809 315.388 293.119 315.372 293.436C315.364 293.594 315.325 293.75 315.257 293.893C315.189 294.037 315.093 294.165 314.974 294.271C314.856 294.377 314.718 294.459 314.568 294.511C314.418 294.563 314.259 294.585 314.1 294.576Z" fill="white"/>
    </g>
    <g opacity="0.9">
    <path d="M313.044 305.999C313.044 306.162 313.011 306.323 312.947 306.473C312.883 306.622 312.789 306.757 312.671 306.87C312.553 306.982 312.414 307.069 312.261 307.126C312.109 307.182 311.946 307.207 311.784 307.199C300.254 306.78 288.915 304.123 278.4 299.375C278.254 299.303 278.125 299.202 278.02 299.079C277.915 298.956 277.836 298.812 277.788 298.657C277.74 298.503 277.723 298.34 277.74 298.179C277.756 298.017 277.805 297.861 277.884 297.719C278.031 297.45 278.277 297.248 278.569 297.154C278.862 297.06 279.179 297.082 279.456 297.215C289.688 301.792 300.703 304.366 311.904 304.799C312.211 304.815 312.501 304.948 312.714 305.171C312.926 305.395 313.044 305.691 313.044 305.999Z" fill="white"/>
    </g>
    <g opacity="0.9">
    <path d="M292.715 326.003C283.355 324.179 278.543 322.931 276.515 322.403C276.36 322.358 276.215 322.282 276.089 322.18C275.964 322.077 275.86 321.951 275.785 321.807C275.71 321.664 275.665 321.506 275.652 321.345C275.639 321.183 275.66 321.021 275.711 320.867C275.81 320.576 276.017 320.334 276.29 320.191C276.562 320.048 276.879 320.015 277.175 320.099C279.119 320.651 283.919 321.887 293.171 323.699C293.483 323.76 293.758 323.942 293.935 324.206C294.113 324.469 294.179 324.792 294.119 325.103C294.048 325.406 293.861 325.669 293.599 325.837C293.337 326.005 293.02 326.065 292.715 326.003Z" fill="white"/>
    </g>
    <path d="M314.075 219.6C313.672 219.771 313.218 219.777 312.81 219.618C312.402 219.459 312.073 219.147 311.891 218.748C311.728 218.342 311.673 217.901 311.732 217.467C311.791 217.034 311.962 216.623 312.227 216.276C312.765 215.581 313.466 215.029 314.267 214.668C313.588 215.475 313.114 216.434 312.887 217.464C312.911 218.4 313.199 219.9 314.075 219.6Z" fill="#263238"/>
    <path d="M324.096 244.091C324.094 244.202 324.068 244.31 324.021 244.409C323.974 244.509 323.906 244.597 323.822 244.669C323.738 244.74 323.64 244.793 323.534 244.824C323.428 244.855 323.317 244.863 323.208 244.847C322.988 244.8 322.786 244.691 322.625 244.534C322.463 244.377 322.35 244.178 322.296 243.959C322.206 243.516 322.206 243.059 322.296 242.615C322.369 241.593 322.616 240.59 323.028 239.651C323.442 238.705 324.196 237.947 325.14 237.527C325.309 237.727 325.417 237.972 325.451 238.231C325.485 238.491 325.444 238.755 325.332 238.991C325.085 239.413 324.804 239.815 324.492 240.191C324.13 240.727 323.896 241.339 323.807 241.98C323.717 242.62 323.775 243.273 323.976 243.887C324.072 243.911 324 244.079 324.096 244.091Z" fill="#263238"/>
    <path d="M188.892 320.208H103.452C100.301 320.208 97.2798 321.46 95.0518 323.688C92.8239 325.916 91.5723 328.937 91.5723 332.088V397.032C91.5723 400.183 92.8239 403.205 95.0518 405.432C97.2798 407.66 100.301 408.912 103.452 408.912H168C168.627 413.997 171.09 418.677 174.927 422.072C178.764 425.466 183.709 427.342 188.832 427.344C189.3 427.344 189.768 427.344 190.236 427.272C186.9 422.88 184.176 416.472 182.388 408.912H188.928C192.079 408.912 195.101 407.66 197.329 405.432C199.557 403.205 200.808 400.183 200.808 397.032V332.088C200.808 330.525 200.5 328.977 199.901 327.533C199.301 326.09 198.423 324.779 197.316 323.675C196.209 322.571 194.895 321.697 193.45 321.102C192.004 320.507 190.455 320.203 188.892 320.208Z" fill="#6D1ED4"/>
    <path opacity="0.4" d="M188.892 320.208H103.452C100.301 320.208 97.2798 321.46 95.0518 323.688C92.8239 325.916 91.5723 328.937 91.5723 332.088V397.032C91.5723 400.183 92.8239 403.205 95.0518 405.432C97.2798 407.66 100.301 408.912 103.452 408.912H168C168.627 413.997 171.09 418.677 174.927 422.072C178.764 425.466 183.709 427.342 188.832 427.344C189.3 427.344 189.768 427.344 190.236 427.272C186.9 422.88 184.176 416.472 182.388 408.912H188.928C192.079 408.912 195.101 407.66 197.329 405.432C199.557 403.205 200.808 400.183 200.808 397.032V332.088C200.808 330.525 200.5 328.977 199.901 327.533C199.301 326.09 198.423 324.779 197.316 323.675C196.209 322.571 194.895 321.697 193.45 321.102C192.004 320.507 190.455 320.203 188.892 320.208Z" fill="white"/>
    <path d="M206.004 347.808L212.652 341.652L218.34 352.176C218.34 352.176 211.752 357.204 207.852 354.108L206.004 347.808Z" fill="#FF8B7B"/>
    <path d="M206.004 347.796L201.48 357.42L207.684 362.556L213.108 353.772L206.004 347.796Z" fill="#FF8B7B"/>
    <path d="M218.976 343.08L220.068 347.052C220.339 347.98 220.318 348.969 220.009 349.885C219.7 350.801 219.118 351.601 218.34 352.176L215.94 353.772L209.148 341.844L214.968 340.38C215.409 340.289 215.864 340.288 216.305 340.375C216.747 340.462 217.166 340.637 217.539 340.888C217.913 341.139 218.232 341.463 218.479 341.839C218.725 342.215 218.895 342.637 218.976 343.08Z" fill="#FF8B7B"/>
    <path d="M206.004 347.808C206.004 347.808 208.404 340.392 208.02 338.124C207.816 336.768 209.22 336.984 210.348 337.392C210.783 337.54 211.163 337.817 211.437 338.185C211.711 338.554 211.867 338.997 211.884 339.456C211.98 341.856 211.884 346.536 210.048 346.836C208.675 347.044 207.322 347.37 206.004 347.808Z" fill="#FF8B7B"/>
    <path d="M213.768 355.512C211.903 353.427 209.417 351.996 206.676 351.432L204.66 351.012C203.46 350.772 203.232 351.012 203.088 351.36L202.512 352.26L201.312 354.048C200.52 355.248 199.728 356.448 198.912 357.648C197.316 360.048 195.648 362.292 193.968 364.476C192.395 366.564 190.666 368.53 188.796 370.356L188.304 370.812C187.68 370.14 187.104 369.432 186.48 368.7C184.724 366.48 183.086 364.168 181.572 361.776C180.024 359.376 175.008 352.104 173.64 349.488C172.956 348.192 172.284 346.872 171.648 345.552L170.724 343.572C170.448 342.936 170.112 342.192 169.92 341.712L169.788 341.4C168.804 339.235 167.003 337.547 164.779 336.706C162.554 335.865 160.087 335.938 157.917 336.91C155.746 337.882 154.048 339.673 153.195 341.893C152.341 344.113 152.4 346.58 153.36 348.756C153.792 349.728 154.128 350.424 154.56 351.228L155.76 353.532C156.56 355.06 157.36 356.56 158.16 358.032C159.864 360.996 165.288 368.556 167.28 371.4C169.299 374.296 171.508 377.054 173.892 379.656C176.401 382.425 179.179 384.937 182.184 387.156L182.556 387.432C183.479 388.101 184.542 388.552 185.665 388.749C186.787 388.947 187.94 388.886 189.036 388.572C191.481 387.858 193.761 386.667 195.744 385.068C197.259 383.867 198.668 382.538 199.956 381.096C202.208 378.564 204.258 375.86 206.088 373.008C207.852 370.284 209.4 367.5 210.888 364.716C211.596 363.3 212.292 361.896 212.94 360.456L213.9 358.296L214.368 357.204C214.512 356.82 214.584 356.4 213.768 355.512Z" fill="#263238"/>
    <path opacity="0.4" d="M169.296 351.696L162.696 356.364L160.8 360.876C161.124 361.476 161.436 362.076 161.772 362.676C163.464 365.64 165.372 368.52 167.316 371.364C169.335 374.26 171.543 377.018 173.928 379.62C175.128 380.82 176.328 382.02 177.528 383.22C174.288 375.6 169.296 351.696 169.296 351.696Z" fill="black"/>
    <path d="M110.304 388.176C110.82 400.176 108.924 399.624 108.876 408.912H167.352C170.532 380.58 169.044 376.224 169.104 357.42C169.104 346.836 167.484 336.036 156.948 334.932C144.573 333.725 132.106 333.805 119.748 335.172C117.897 335.397 116.109 335.99 114.49 336.916C112.871 337.842 111.453 339.082 110.319 340.563C109.186 342.044 108.36 343.736 107.889 345.541C107.419 347.346 107.314 349.226 107.58 351.072C109.944 367.068 108.66 376.128 110.304 388.176Z" fill="#263238"/>
    <path opacity="0.9" d="M151.608 334.488C143.817 333.978 136.002 333.954 128.208 334.416C128.376 336.816 130.2 342.816 143.484 348.012C143.484 348.012 146.448 341.58 148.944 344.472C148.944 344.472 150.948 340.776 155.244 347.58C155.244 347.58 161.652 340.716 151.608 334.488Z" fill="white"/>
    <path opacity="0.4" d="M120 351.504C121.284 362.628 114.18 381.228 110.4 390.216C110.4 389.592 110.4 388.908 110.4 388.176C109.368 380.664 109.488 374.304 109.2 366.72L120 351.504Z" fill="black"/>
    <path d="M133.944 313.2C134.141 320.497 132.732 327.748 129.816 334.44C129.708 334.695 129.681 334.977 129.739 335.247C129.797 335.518 129.937 335.764 130.14 335.952C135.037 340.174 141.316 342.446 147.78 342.336C155.64 342.432 155.184 339.936 153.324 337.212C153.053 336.825 152.701 336.501 152.292 336.264C146.916 333.072 146.724 328.8 148.236 326.22L133.944 313.2Z" fill="#FF8B7B"/>
    <path opacity="0.2" d="M133.956 313.2L148.236 326.22C147.759 327.04 147.483 327.961 147.431 328.908C147.379 329.855 147.552 330.801 147.936 331.668C144.923 331.762 141.986 330.712 139.716 328.728C136.032 325.596 134.784 321.9 133.86 316.728C133.932 315.6 133.956 314.4 133.956 313.2Z" fill="black"/>
    <path d="M129.697 306.432C130.321 316.032 128.965 317.568 133.837 322.428C141.169 329.736 153.109 331.128 154.801 321.408C156.313 312.648 157.201 296.796 147.733 293.1C145.646 292.27 143.382 291.986 141.156 292.275C138.929 292.563 136.813 293.415 135.007 294.75C133.201 296.085 131.766 297.858 130.837 299.902C129.907 301.946 129.515 304.194 129.697 306.432Z" fill="#FF8B7B"/>
    <path d="M153 316.8C153 316.8 149.628 315.732 140.064 315.324C141.084 318.756 143.088 321.612 147.984 321.768C152.88 321.924 153 316.8 153 316.8Z" fill="#263238"/>
    <path d="M145.8 318.972C147.725 318.942 149.598 319.601 151.08 320.832C150.195 321.495 149.099 321.815 147.996 321.732C146.067 321.771 144.19 321.105 142.716 319.86C143.603 319.201 144.698 318.886 145.8 318.972Z" fill="#FF5652"/>
    <path d="M152.569 316.644C152.281 317.352 151.981 318.072 151.021 318.264C148.189 318.13 145.376 317.728 142.621 317.064C142.147 316.978 141.704 316.771 141.336 316.461C140.968 316.152 140.686 315.751 140.521 315.3C144.569 315.378 148.602 315.828 152.569 316.644Z" fill="white"/>
    <path d="M149.244 307.836C150.255 309.626 151.512 311.265 152.976 312.708C152.598 313.078 152.143 313.361 151.643 313.535C151.143 313.709 150.611 313.771 150.084 313.716L149.244 307.836Z" fill="#FF5652"/>
    <path d="M139.776 303.696C139.694 303.685 139.618 303.647 139.56 303.588C139.515 303.543 139.479 303.49 139.454 303.431C139.43 303.372 139.417 303.309 139.417 303.246C139.417 303.182 139.43 303.119 139.454 303.06C139.479 303.002 139.515 302.948 139.56 302.904C140.058 302.32 140.688 301.864 141.397 301.574C142.107 301.284 142.876 301.168 143.64 301.236C143.767 301.255 143.882 301.324 143.959 301.427C144.035 301.531 144.067 301.66 144.048 301.788C144.029 301.915 143.96 302.03 143.857 302.106C143.753 302.183 143.623 302.215 143.496 302.196C142.889 302.149 142.279 302.247 141.718 302.483C141.157 302.719 140.66 303.085 140.268 303.552C140.208 303.62 140.13 303.669 140.043 303.695C139.956 303.72 139.863 303.721 139.776 303.696Z" fill="#263238"/>
    <path d="M155.713 305.4C155.634 305.413 155.554 305.407 155.479 305.382C155.403 305.357 155.335 305.313 155.281 305.256C154.883 304.802 154.382 304.45 153.821 304.229C153.259 304.007 152.653 303.923 152.053 303.984C151.929 304.005 151.801 303.976 151.698 303.904C151.595 303.833 151.524 303.723 151.501 303.6C151.48 303.472 151.509 303.342 151.583 303.237C151.658 303.131 151.77 303.059 151.897 303.036C152.654 302.952 153.419 303.05 154.131 303.322C154.843 303.593 155.479 304.03 155.989 304.596C156.034 304.64 156.07 304.693 156.095 304.752C156.119 304.811 156.132 304.874 156.132 304.938C156.132 305.001 156.119 305.064 156.095 305.123C156.07 305.182 156.034 305.235 155.989 305.28C155.911 305.347 155.815 305.389 155.713 305.4Z" fill="#263238"/>
    <path d="M92.4966 390.132C92.5175 390.586 92.5859 391.036 92.7006 391.476C93.7848 395.313 95.1939 399.05 96.9126 402.648C97.7846 404.552 98.6846 406.424 99.6126 408.264C100.849 408.687 102.146 408.906 103.453 408.912H116.401L115.117 406.044C113.689 402.912 112.369 399.768 111.169 396.66C110.169 394.139 109.327 391.558 108.649 388.932C108.785 387.927 108.989 386.933 109.261 385.956C109.633 384.516 110.137 382.956 110.713 381.372C111.913 378.216 113.233 374.964 114.673 371.772C116.113 368.58 117.709 365.304 119.317 362.076C120.925 358.848 122.605 355.584 124.273 352.536V352.428L124.357 352.248C125.445 350.123 125.645 347.654 124.913 345.382C124.18 343.11 122.575 341.222 120.451 340.134C118.326 339.045 115.857 338.845 113.585 339.578C111.313 340.31 109.425 341.915 108.337 344.04C106.537 347.568 104.917 350.94 103.285 354.432C101.653 357.924 100.177 361.452 98.7366 365.052C97.2966 368.652 96.0006 372.288 94.8006 376.188C94.2126 378.144 93.6846 380.16 93.2406 382.332C92.6886 384.805 92.4348 387.335 92.4846 389.868L92.4966 390.132Z" fill="#263238"/>
    <path d="M125.34 312.864C126.172 314.922 127.755 316.586 129.768 317.52C132.336 318.624 133.752 316.116 133.14 313.236C132.6 310.656 130.596 306.96 127.968 307.08C125.34 307.2 124.248 310.224 125.34 312.864Z" fill="#FF8B7B"/>
    <path d="M142.044 306.96C142.044 307.788 142.392 308.496 142.956 308.532C143.52 308.568 143.988 307.92 144.024 307.068C144.06 306.216 143.676 305.532 143.124 305.496C142.572 305.46 142.092 306.108 142.044 306.96Z" fill="#2E353A"/>
    <path d="M151.536 307.512C151.536 308.352 151.896 309.06 152.436 309.096C152.976 309.132 153.468 308.472 153.528 307.632C153.588 306.792 153.168 306.096 152.628 306.06C152.088 306.024 151.584 306.672 151.536 307.512Z" fill="#2E353A"/>
    <path d="M178.992 151.884H93.6004C90.4517 151.887 87.4331 153.14 85.2078 155.368C82.9824 157.595 81.7324 160.615 81.7324 163.764V228.708C81.7309 230.267 82.0367 231.812 82.6324 233.253C83.2281 234.695 84.102 236.004 85.2042 237.108C86.3064 238.211 87.6153 239.086 89.056 239.683C90.4966 240.281 92.0409 240.588 93.6004 240.588H158.124C158.743 245.675 161.202 250.359 165.038 253.757C168.873 257.155 173.82 259.031 178.944 259.032C179.424 259.032 179.892 259.032 180.36 258.96C177.024 254.556 174.288 248.16 172.512 240.588H179.052C182.203 240.588 185.225 239.336 187.453 237.108C189.681 234.88 190.932 231.859 190.932 228.708V163.764C190.931 162.199 190.621 160.65 190.019 159.206C189.418 157.761 188.537 156.449 187.428 155.346C186.319 154.242 185.003 153.368 183.555 152.774C182.108 152.18 180.557 151.878 178.992 151.884Z" fill="#6D1ED4"/>
    <path opacity="0.4" d="M178.992 151.884H93.6004C90.4517 151.887 87.4331 153.14 85.2078 155.368C82.9824 157.595 81.7324 160.615 81.7324 163.764V228.708C81.7309 230.267 82.0367 231.812 82.6324 233.253C83.2281 234.695 84.102 236.004 85.2042 237.108C86.3064 238.211 87.6153 239.086 89.056 239.683C90.4966 240.281 92.0409 240.588 93.6004 240.588H158.124C158.743 245.675 161.202 250.359 165.038 253.757C168.873 257.155 173.82 259.031 178.944 259.032C179.424 259.032 179.892 259.032 180.36 258.96C177.024 254.556 174.288 248.16 172.512 240.588H179.052C182.203 240.588 185.225 239.336 187.453 237.108C189.681 234.88 190.932 231.859 190.932 228.708V163.764C190.931 162.199 190.621 160.65 190.019 159.206C189.418 157.761 188.537 156.449 187.428 155.346C186.319 154.242 185.003 153.368 183.555 152.774C182.108 152.18 180.557 151.878 178.992 151.884Z" fill="white"/>
    <path d="M158.064 240.588C157.575 233.613 157.575 226.612 158.064 219.636C158.808 207.348 155.136 194.064 152.364 191.796C148.368 188.496 144.648 185.388 143.328 189.54C142.56 191.94 142.332 195.9 142.38 200.34C142.5 211.068 144.78 224.448 145.86 230.628C146.256 232.812 147.252 240.6 147.252 240.6L158.064 240.588Z" fill="#6D1ED4"/>
    <path d="M150.601 192.324C150.464 191.442 150.075 190.618 149.481 189.952C148.887 189.285 148.113 188.805 147.253 188.568C144.253 187.764 139.069 186.456 134.353 185.616C128.795 184.713 123.182 184.199 117.553 184.08C113.953 183.936 110.497 184.08 107.725 184.2C107.396 184.226 107.071 184.282 106.753 184.368C106.167 184.534 105.62 184.814 105.142 185.191C104.665 185.569 104.267 186.037 103.97 186.568C103.674 187.1 103.485 187.685 103.415 188.289C103.344 188.894 103.394 189.506 103.561 190.092C110.677 215.496 112.153 227.652 112.525 240.588H147.253C148.873 228.852 152.053 219.216 152.341 212.316C152.588 205.606 152.003 198.89 150.601 192.324Z" fill="#6D1ED4"/>
    <path opacity="0.8" d="M150.601 192.324C150.464 191.442 150.075 190.618 149.481 189.952C148.887 189.285 148.113 188.805 147.253 188.568C144.253 187.764 139.069 186.456 134.353 185.616C128.795 184.713 123.182 184.199 117.553 184.08C113.953 183.936 110.497 184.08 107.725 184.2C107.396 184.226 107.071 184.282 106.753 184.368C106.167 184.534 105.62 184.814 105.142 185.191C104.665 185.569 104.267 186.037 103.97 186.568C103.674 187.1 103.485 187.685 103.415 188.289C103.344 188.894 103.394 189.506 103.561 190.092C110.677 215.496 112.153 227.652 112.525 240.588H147.253C148.873 228.852 152.053 219.216 152.341 212.316C152.588 205.606 152.003 198.89 150.601 192.324Z" fill="white"/>
    <path d="M136.332 179.748C134.052 182.832 132.384 186.492 135.96 190.548C136.736 191.394 137.19 192.485 137.244 193.632C137.244 195.468 136.224 197.232 130.248 193.728C121.584 188.64 122.316 182.928 122.316 182.928C126.864 181.548 129.588 171.984 130.644 166.512L136.332 179.748Z" fill="#FFB573"/>
    <path opacity="0.2" d="M131.124 174.684C130.464 177.012 132.924 180.684 134.796 182.196C135.232 181.352 135.742 180.549 136.32 179.796L132.96 171.936C132.174 172.723 131.551 173.656 131.124 174.684Z" fill="black"/>
    <path d="M153.925 150.972C156.973 154.836 150.109 166.476 149.365 164.04C148.165 160.08 146.413 152.04 146.437 148.74C146.485 143.532 150.889 147.12 153.925 150.972Z" fill="#263238"/>
    <path d="M128.653 152.184C129.462 150.807 130.534 149.603 131.808 148.641C133.083 147.678 134.534 146.977 136.08 146.575C137.626 146.174 139.235 146.082 140.817 146.303C142.398 146.524 143.92 147.054 145.297 147.864C153.469 152.592 152.845 165.864 149.845 173.496C146.557 182.004 135.445 183.096 129.697 175.356C125.833 170.196 126.325 166.44 127.009 157.548C127.128 155.656 127.692 153.818 128.653 152.184Z" fill="#FFB573"/>
    <path d="M139.512 162C139.452 162.732 139.776 163.356 140.244 163.404C140.712 163.452 141.156 162.9 141.24 162.204C141.324 161.508 140.976 160.848 140.508 160.8C140.04 160.752 139.62 161.364 139.512 162Z" fill="#263238"/>
    <path d="M140.269 160.908L142.057 160.584C142.057 160.584 141.049 161.808 140.269 160.908Z" fill="#263238"/>
    <path d="M148.608 164.076C148.608 164.808 148.872 165.432 149.34 165.48C149.808 165.528 150.252 164.976 150.336 164.28C150.42 163.584 150.072 162.924 149.604 162.876C149.136 162.828 148.716 163.392 148.608 164.076Z" fill="#263238"/>
    <path d="M149.364 162.936L151.152 162.612C151.152 162.612 150.144 163.836 149.364 162.936Z" fill="#263238"/>
    <path d="M145.2 169.56C145.621 169.731 146.074 169.809 146.527 169.788C146.981 169.767 147.425 169.649 147.828 169.44C146.965 167.888 146.319 166.224 145.908 164.496L145.2 169.56Z" fill="#ED893E"/>
    <path d="M138.349 169.259C138.327 169.208 138.324 169.15 138.342 169.097C138.359 169.044 138.396 168.999 138.445 168.971C138.496 168.946 138.554 168.942 138.608 168.96C138.662 168.978 138.707 169.017 138.733 169.067C139.176 169.855 139.8 170.525 140.553 171.024C141.306 171.522 142.167 171.835 143.065 171.935C143.116 171.949 143.162 171.981 143.192 172.025C143.223 172.069 143.237 172.122 143.233 172.175C143.23 172.202 143.222 172.228 143.208 172.251C143.195 172.274 143.177 172.294 143.156 172.31C143.134 172.325 143.11 172.337 143.084 172.342C143.058 172.348 143.031 172.349 143.005 172.343C142.659 172.306 142.318 172.237 141.985 172.139C141.218 171.925 140.501 171.559 139.877 171.065C139.253 170.571 138.733 169.957 138.349 169.259Z" fill="#263238"/>
    <path d="M139.2 157.38C139.133 157.29 139.105 157.178 139.12 157.068C139.136 156.958 139.195 156.858 139.284 156.792C139.997 156.359 140.794 156.084 141.622 155.985C142.45 155.886 143.289 155.964 144.084 156.216C144.19 156.254 144.276 156.331 144.326 156.432C144.375 156.533 144.383 156.649 144.348 156.756C144.313 156.86 144.237 156.947 144.138 156.996C144.04 157.045 143.925 157.054 143.82 157.02C143.157 156.806 142.457 156.734 141.764 156.808C141.072 156.883 140.403 157.102 139.8 157.452C139.73 157.507 139.644 157.537 139.554 157.537C139.465 157.537 139.378 157.507 139.308 157.452L139.2 157.38Z" fill="#263238"/>
    <path d="M152.292 160.152C152.004 159.701 151.611 159.326 151.148 159.058C150.684 158.791 150.163 158.639 149.628 158.616C149.572 158.617 149.516 158.608 149.464 158.587C149.412 158.567 149.364 158.536 149.324 158.497C149.283 158.458 149.251 158.411 149.229 158.359C149.208 158.307 149.196 158.252 149.196 158.196C149.196 158.083 149.24 157.975 149.319 157.894C149.397 157.814 149.504 157.767 149.616 157.764C150.291 157.787 150.949 157.972 151.538 158.302C152.127 158.631 152.628 159.097 153 159.66C153.031 159.705 153.053 159.756 153.064 159.81C153.074 159.864 153.074 159.919 153.063 159.973C153.052 160.027 153.03 160.078 152.998 160.123C152.967 160.168 152.927 160.206 152.88 160.236C152.818 160.276 152.745 160.298 152.67 160.298C152.596 160.298 152.523 160.276 152.46 160.236L152.292 160.152Z" fill="#263238"/>
    <path d="M141.372 150.408C140.032 150.659 138.764 151.205 137.66 152.006C136.556 152.807 135.643 153.844 134.988 155.04C134.376 156.168 130.44 158.64 129.768 159.756C127.656 163.272 129.96 173.28 128.964 174.036C127.968 174.792 128.316 171.828 127.368 171.252C126.362 170.798 125.399 170.256 124.488 169.632C123.288 168.624 119.124 157.764 124.26 151.188C126.816 147.719 130.557 145.311 134.772 144.42C139.043 143.694 143.427 144.691 146.964 147.192C148.08 147.996 150.036 150.192 146.964 151.176C146.124 151.44 146.088 150.768 145.212 150.552C143.958 150.228 142.648 150.179 141.372 150.408Z" fill="#263238"/>
    <path d="M120.949 159.756C123.913 156.876 127.609 156.876 130.873 156.876C132.423 156.999 133.984 156.823 135.469 156.36C138.757 154.932 140.773 150.24 141.145 148.704C141.343 148.075 141.699 147.508 142.178 147.054C142.656 146.601 143.242 146.276 143.881 146.112C144.4 146.086 144.919 146.181 145.395 146.39C145.872 146.598 146.294 146.913 146.629 147.312C147.217 148.164 147.157 149.4 146.449 150.912L146.917 151.116C147.697 149.376 147.733 147.984 147.049 146.976C146.662 146.509 146.173 146.137 145.619 145.889C145.066 145.641 144.463 145.525 143.857 145.548C143.107 145.715 142.415 146.079 141.852 146.602C141.288 147.125 140.875 147.788 140.653 148.524C140.317 149.94 138.337 154.524 135.265 155.844C133.844 156.28 132.353 156.439 130.873 156.312C127.681 156.312 123.673 156.312 120.601 159.336L120.949 159.756Z" fill="#263238"/>
    <path opacity="0.1" d="M120.949 159.756C123.913 156.876 127.609 156.876 130.873 156.876C132.423 156.999 133.984 156.823 135.469 156.36C138.757 154.932 140.773 150.24 141.145 148.704C141.343 148.075 141.699 147.508 142.178 147.054C142.656 146.601 143.242 146.276 143.881 146.112C144.4 146.086 144.919 146.181 145.395 146.39C145.872 146.598 146.294 146.913 146.629 147.312C147.217 148.164 147.157 149.4 146.449 150.912L146.917 151.116C147.697 149.376 147.733 147.984 147.049 146.976C146.662 146.509 146.173 146.137 145.619 145.889C145.066 145.641 144.463 145.525 143.857 145.548C143.107 145.715 142.415 146.079 141.852 146.602C141.288 147.125 140.875 147.788 140.653 148.524C140.317 149.94 138.337 154.524 135.265 155.844C133.844 156.28 132.353 156.439 130.873 156.312C127.681 156.312 123.673 156.312 120.601 159.336L120.949 159.756Z" fill="white"/>
    <path d="M123.493 163.128C123.542 162.747 123.665 162.38 123.856 162.047C124.048 161.714 124.303 161.422 124.607 161.188C124.912 160.954 125.259 160.782 125.63 160.682C126.001 160.583 126.388 160.558 126.769 160.608C128.977 160.944 129.985 163.92 129.985 165.864C129.985 168.012 128.389 169.608 126.445 168.396C124.988 167.468 123.958 166.001 123.577 164.316C123.465 163.931 123.437 163.526 123.493 163.128Z" fill="#FFB573"/>
    <path d="M127.416 194.796C126.948 200.94 121.272 224.148 108.972 224.796C107.013 224.851 105.103 224.17 103.62 222.888C93.7084 216.888 101.496 187.968 103.488 186.288C104.353 185.547 105.39 185.033 106.505 184.796C107.619 184.558 108.775 184.604 109.868 184.929C110.96 185.254 111.953 185.847 112.757 186.655C113.561 187.462 114.149 188.459 114.468 189.552C114.727 188.637 114.932 187.708 115.08 186.768C115.596 183.636 117.3 176.34 120.444 175.812C122.34 175.488 126.24 175.32 127.368 176.868C128.496 178.416 128.196 184.656 127.416 194.796Z" fill="#6D1ED4"/>
    <g opacity="0.2">
    <path d="M126.769 160.607V161.147L127.333 166.475V173.339L128.269 169.259C128.506 168.196 128.559 167.1 128.425 166.019L127.765 160.907L126.769 160.607Z" fill="black"/>
    </g>
    <path d="M120.6 177.048L119.124 166.356L128.04 166.284C128.04 166.284 127.308 174.312 125.076 177.084L120.6 177.048Z" fill="#FFB573"/>
    <path d="M120.696 159.048L127.296 160.212L128.004 166.26L119.124 166.356L120.696 159.048Z" fill="#FFB573"/>
    <path opacity="0.2" d="M111.732 199.284C111.732 199.284 106.392 211.62 106.224 206.736C106.056 201.42 111.732 199.284 111.732 199.284Z" fill="black"/>
    <path d="M440.868 271.416H407.844C406.332 271.416 404.882 272.017 403.813 273.086C402.744 274.154 402.144 275.604 402.144 277.116V298.38C402.147 299.89 402.749 301.336 403.817 302.403C404.886 303.469 406.334 304.068 407.844 304.068H417.336L421.572 309.612C421.897 310.04 422.318 310.387 422.799 310.625C423.281 310.864 423.812 310.988 424.35 310.988C424.887 310.988 425.418 310.864 425.9 310.625C426.382 310.387 426.802 310.04 427.128 309.612L431.364 304.068H440.868C442.376 304.068 443.823 303.469 444.89 302.402C445.956 301.335 446.556 299.889 446.556 298.38V277.116C446.556 275.606 445.957 274.158 444.89 273.09C443.824 272.021 442.377 271.419 440.868 271.416Z" fill="#6D1ED4"/>
    <path opacity="0.7" d="M440.868 271.416H407.844C406.332 271.416 404.882 272.017 403.813 273.086C402.744 274.154 402.144 275.604 402.144 277.116V298.38C402.147 299.89 402.749 301.336 403.817 302.403C404.886 303.469 406.334 304.068 407.844 304.068H417.336L421.572 309.612C421.897 310.04 422.318 310.387 422.799 310.625C423.281 310.864 423.812 310.988 424.35 310.988C424.887 310.988 425.418 310.864 425.9 310.625C426.382 310.387 426.802 310.04 427.128 309.612L431.364 304.068H440.868C442.376 304.068 443.823 303.469 444.89 302.402C445.956 301.335 446.556 299.889 446.556 298.38V277.116C446.556 275.606 445.957 274.158 444.89 273.09C443.824 272.021 442.377 271.419 440.868 271.416Z" fill="white"/>
    <path d="M438.948 286.032L428.4 279.6V283.464H423.6C420.493 283.464 417.514 284.698 415.318 286.894C413.122 289.09 411.888 292.069 411.888 295.176C411.891 296.043 411.988 296.908 412.176 297.756C412.765 295.165 414.216 292.851 416.291 291.192C418.366 289.534 420.943 288.628 423.6 288.624H428.4V292.5L438.948 286.032Z" fill="#6D1ED4"/>
    <path d="M208.5 141.264H233.82C234.978 141.264 236.089 141.724 236.908 142.543C237.727 143.362 238.188 144.473 238.188 145.632V162C238.188 162.573 238.075 163.14 237.855 163.669C237.635 164.199 237.313 164.679 236.907 165.084C236.502 165.489 236.02 165.809 235.49 166.028C234.96 166.246 234.393 166.357 233.82 166.356H226.536L223.296 170.616C223.046 170.944 222.724 171.21 222.354 171.393C221.985 171.576 221.578 171.672 221.166 171.672C220.753 171.672 220.346 171.576 219.977 171.393C219.607 171.21 219.285 170.944 219.036 170.616L215.784 166.356H208.5C207.927 166.357 207.36 166.246 206.831 166.027C206.302 165.809 205.821 165.488 205.416 165.083C205.011 164.679 204.69 164.198 204.472 163.669C204.254 163.139 204.142 162.572 204.144 162V145.632C204.142 145.059 204.254 144.491 204.472 143.961C204.69 143.431 205.011 142.95 205.415 142.544C205.82 142.138 206.301 141.816 206.83 141.596C207.359 141.377 207.927 141.264 208.5 141.264Z" fill="#6D1ED4"/>
    <path opacity="0.7" d="M208.5 141.264H233.82C234.978 141.264 236.089 141.724 236.908 142.543C237.727 143.362 238.188 144.473 238.188 145.632V162C238.188 162.573 238.075 163.14 237.855 163.669C237.635 164.199 237.313 164.679 236.907 165.084C236.502 165.489 236.02 165.809 235.49 166.028C234.96 166.246 234.393 166.357 233.82 166.356H226.536L223.296 170.616C223.046 170.944 222.724 171.21 222.354 171.393C221.985 171.576 221.578 171.672 221.166 171.672C220.753 171.672 220.346 171.576 219.977 171.393C219.607 171.21 219.285 170.944 219.036 170.616L215.784 166.356H208.5C207.927 166.357 207.36 166.246 206.831 166.027C206.302 165.809 205.821 165.488 205.416 165.083C205.011 164.679 204.69 164.198 204.472 163.669C204.254 163.139 204.142 162.572 204.144 162V145.632C204.142 145.059 204.254 144.491 204.472 143.961C204.69 143.431 205.011 142.95 205.415 142.544C205.82 142.138 206.301 141.816 206.83 141.596C207.359 141.377 207.927 141.264 208.5 141.264Z" fill="white"/>
    <path d="M210 152.471L218.112 147.527V150.491H221.82C223.166 150.495 224.494 150.801 225.706 151.385C226.918 151.97 227.984 152.82 228.824 153.871C229.665 154.922 230.259 156.149 230.563 157.46C230.867 158.771 230.873 160.134 230.58 161.447C230.129 159.461 229.016 157.687 227.424 156.416C225.833 155.145 223.857 154.452 221.82 154.451H218.112V157.415L210 152.471Z" fill="#6D1ED4"/>
    </svg>
    
    
  );
};

export default ReferralSmallIcon;
export {};