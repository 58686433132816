import React from 'react'

const ICICIbank: React.FC<React.SVGProps<SVGSVGElement>>=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="30" viewBox="0 0 153 30" fill="none">
  <path d="M28.0698 29.7154H33.1931L37.2087 14.7988H32.0855L28.0698 29.7154Z" fill="#005B75"/>
  <path d="M54.3416 29.7154H59.4648L63.4805 14.7988H58.3573L54.3416 29.7154Z" fill="#005B75"/>
  <path d="M80.609 29.7154H85.7322L89.7509 14.7988H84.6276L80.609 29.7154Z" fill="#005B75"/>
  <path d="M49.5866 19.8806H54.7098C55.4058 16.5194 53.2351 14.7988 48.1947 14.7988C42.3756 14.7988 38.4399 17.627 37.128 22.5844C35.9404 27.0502 38.3155 29.7569 43.7674 29.7569C49.2994 29.7569 52.5391 28.0363 53.931 24.1835H48.8078C48.1118 25.8627 46.7585 26.7659 44.9135 26.7659C42.4555 26.7659 41.4724 25.2082 42.2097 22.3386C42.9886 19.389 44.875 17.7484 47.3744 17.7484C48.9736 17.7898 49.711 18.4887 49.5866 19.8806Z" fill="#005B75"/>
  <path d="M75.8576 19.8806H80.9809C81.6768 16.5194 79.5061 14.7988 74.4658 14.7988C68.6466 14.7988 64.7109 17.627 63.399 22.5844C62.2115 27.0502 64.5865 29.7569 70.0385 29.7569C75.5704 29.7569 78.8102 28.0363 80.202 24.1835H75.0788C74.3829 25.8627 73.0295 26.7659 71.1845 26.7659C68.7266 26.7659 67.7434 25.2082 68.4808 22.3386C69.2596 19.389 71.146 17.7484 73.6455 17.7484C75.2446 17.7898 75.982 18.4887 75.8576 19.8806Z" fill="#005B75"/>
  <path d="M8.31893 6.48026C15.1627 -0.280639 23.4428 -2.04564 26.7626 2.58601C30.0823 7.21765 27.1713 16.5194 20.3274 23.3247C13.4836 30.0856 5.20352 31.8506 1.88379 27.219C-1.43595 22.5844 1.43365 13.2826 8.31893 6.48026Z" fill="#F99D27"/>
  <path d="M12.8276 2.87293C12.0902 3.44745 11.3114 4.06046 10.574 4.79785C4.55047 10.6999 2.08954 18.8142 5.04206 22.8713C8.07454 26.9285 15.4929 25.4537 21.5993 19.4687C24.8776 16.2733 27.0898 12.5419 28.0344 9.10078C28.3217 6.55989 27.9515 4.30626 26.8055 2.58568C24.0987 -1.02132 18.4839 -0.734063 12.8276 2.87293Z" fill="#B02A30"/>
  <path d="M21.061 2.66873C21.8399 3.44758 21.3897 5.20962 19.9949 6.60444C18.6001 7.95781 16.838 8.4494 16.0592 7.67055C15.2389 6.8917 15.7305 5.12966 17.0838 3.73484C18.5201 2.29855 20.2822 1.88988 21.061 2.66873ZM16.225 26.6413C13.0297 29.0578 9.70993 30.2483 6.47015 29.7567C7.82352 29.8397 8.92812 28.3234 9.83135 26.3156C10.7316 24.2663 11.3061 22.3414 11.7178 20.5379C12.3722 17.7097 12.4137 15.6604 12.0879 15.2103C11.5549 14.4729 10.2844 14.6772 8.89259 15.4976C8.23811 15.8677 7.33489 15.6219 8.40099 14.3515C9.4671 13.0396 13.7286 9.92418 15.2863 9.43259C16.9654 8.941 18.8933 9.59547 18.2358 11.4404C17.7413 12.7908 11.8422 28.282 16.225 26.6413Z" fill="white"/>
  <path d="M93.5618 29.757H101.226C103.397 29.757 105.037 29.5941 106.595 28.528C107.62 27.832 108.398 26.7245 108.727 25.5784C109.26 23.5291 108.318 22.1372 105.982 21.8085C108.194 21.3999 109.589 20.2508 110.081 18.4473C110.451 17.094 110.163 16.1108 109.18 15.4563C108.36 14.9233 107.048 14.8403 105.245 14.8403H97.6219L93.5618 29.757ZM100.201 17.094H103.397C105.775 17.094 106.879 17.3812 106.512 18.8975C106.103 20.3722 104.833 20.7839 102.33 20.7839H99.2151L100.201 17.094ZM97.4531 27.3819L98.6406 23.0375H102.002C104.584 23.0375 105.73 23.4876 105.239 25.2497C104.788 26.8903 103.559 27.3819 101.223 27.3819H97.4531Z" fill="#005B75"/>
  <path d="M117.17 29.7572H120.244C120.327 29.0613 120.49 28.2824 120.736 27.4206L121.843 23.2391C122.581 20.4939 121.227 19.0576 117.745 19.0576C114.384 19.0576 112.213 20.2037 111.555 22.173H114.505C114.875 21.1898 115.734 20.7397 117.17 20.7397C118.769 20.7397 119.302 21.4356 118.891 22.9519L118.849 23.1562H117.209C112.619 23.1562 110.161 23.9765 109.462 26.6388C108.929 28.6881 110.199 30 112.944 30C114.706 30 116.264 29.4255 117.493 28.3209L117.17 29.7572ZM118.358 24.9212L118.316 25.1255C117.783 27.0505 116.226 28.1995 114.342 28.1995C112.947 28.1995 112.376 27.6664 112.621 26.6832C112.992 25.3299 114.179 24.9212 116.433 24.9212H118.358Z" fill="#005B75"/>
  <path d="M122.905 29.7568H126.021L127.454 24.4292C127.987 22.5043 129.749 21.1509 131.594 21.1509C133.398 21.1509 134.052 22.217 133.48 24.3463L132.006 29.7982H135.038L136.759 23.4045C137.496 20.7008 136.104 19.1431 133.11 19.1431C131.144 19.1431 129.545 19.7975 128.233 21.2338L128.603 19.3889H125.529C125.2 21.0295 124.996 22.0541 124.913 22.4213L122.905 29.7568Z" fill="#005B75"/>
  <path d="M137.783 29.7569H140.774L142.29 24.1421L146.347 29.7569H150.487L145.897 23.8163L152.128 19.3476H148.234L142.456 23.5291L144.79 14.7988H141.799L137.783 29.7569Z" fill="#005B75"/>
</svg> 
    )
}
export default ICICIbank

export{};