import React from 'react';
import { Box, Card, Grid, useTheme as T, useMediaQuery } from '@mui/material';
import { useTheme } from "../assets/themeContext";
import EarlyBirdIcon from '../assets/icons/EarlyBirdIcon';
import SpreadSmilesIcon from '../assets/icons/SpreadSmilesIcon';

const FeatureCards = () => {
  const { theme } = useTheme();
  const t = T();
  const isMobile = useMediaQuery(t.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1 }} mt={5}>
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">

        <Grid item xs={12} sm={6}>
          <Card sx={{ 
            backgroundColor:'#F8F9FD', 
            borderRadius: '20px', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
          }}>
            <Box p={2} sx={{display:'flex', flexDirection:'column'}}>
              <label style={{color:theme.palette.secondary.main, fontSize: isMobile ? '24px' : '38px', fontWeight:600}}>
                Early Bird Benefits
              </label>
              <label style={{color:theme.palette.primary.dark, fontSize: isMobile ? '16px' : '20px', fontWeight:400, paddingTop:'10px'}}>
                Join now and enjoy ₹1 extra on your transactions in first 6 months
              </label>
            </Box>
            <Box sx={{
              display: "flex",
              flexGrow: 1, 
              alignItems: "center", 
              justifyContent: "center", 
              background: 'linear-gradient(180deg, #F8F9FD 0%, #EDE0FE 100%)',
              borderRadius: '20px',
              padding: "0px 50px 10px 50px"
            }}>
              <EarlyBirdIcon/>
              {/* <img src={images.EarlyBird.src} alt={images.EarlyBird.alt} style={{ height:'auto', maxWidth: '100%' }} /> */}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card sx={{ 
            backgroundColor:'#F8F9FD', 
            borderRadius: '20px', 
            height: '100%',
            display: 'flex', 
            flexDirection: 'column',
          }}>
            <Box sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
              background: 'linear-gradient(180deg, #EDE0FE 0%, #F8F9FD 100%)',
              borderRadius: '20px',
              padding: "0px 50px 10px 50px"
            }}>
                <SpreadSmilesIcon/>
              {/* <img src={images.SpreadSmile.src} alt={images.SpreadSmile.alt} style={{ height:'auto', maxWidth: '100%' }} /> */}
            </Box>
            <Box p={2} sx={{display:'flex', flexDirection:'column'}}>
              <label style={{color:theme.palette.secondary.main, fontSize: isMobile ? '24px' : '38px', fontWeight:600}}>
                Spread smiles
              </label>
              <label style={{color:theme.palette.primary.dark, fontSize: isMobile ? '16px' : '20px', fontWeight:400, paddingTop:'10px'}}>
                Every $1 you send helps us donate ₹0.5 towards Girl Child Education on your behalf
              </label>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeatureCards;
