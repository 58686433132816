import React, { useState, useEffect } from "react";
import { useTheme } from "../assets/themeContext";
import {Box, useMediaQuery, useTheme as T } from "@mui/material";
import CheckmarkIcon from "../assets/icons/CheckmarkIcon";
import { useTheme as useMuiTheme } from "@mui/system";
import PciDssIcon from "../assets/icons/PciDssIcon";
import VisaIcon from "../assets/icons/VisaIcon";
import MastercardIcon from "../assets/icons/MastercardIcon";

const SendMoneyInstant: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    animateText();
  }, []);

  const animateText = () => {
    const textToType = "nstantly"; // Change this to your desired text
    let index = 0;
    let typingForward = true;

    const typingInterval = setInterval(() => {
      if (typingForward) {
        setText(textToType.slice(0, index));
        index++;
        if (index > textToType.length) {
          clearInterval(typingInterval);
          setTimeout(() => {
            typingForward = false;
            animateText();
          }, 1000); // Adjust the delay for switching direction
        }
      } else {
        index--;
        if (index < 2) {
          clearInterval(typingInterval);
          setTimeout(() => {
            typingForward = true;
            animateText();
          }, 1000); // Adjust the delay for switching direction
        } else {
          setText(textToType.slice(0, index));
        }
      }
    }, 100); // Adjust the interval for typing speed
  };

  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  return (
    <Box sx={{ maxWidth: t.spacing(100), padding: t.spacing(2) , display:'flex', flexDirection:'column'}}>
      <label
        style={{
          fontSize: "22px",
          fontWeight: "400",
          color: theme.palette.secondary.main,
        }}
      >
        USA to india money transfer.
      </label>

      <label
        style={{
          fontSize: isSmallScreen ? "45px" : "60px",
          fontWeight: "800",
          color: theme.palette.secondary.main,
          letterSpacing: "-1.8px",
          lineHeight: isSmallScreen ? "45px" : "60px",
        }}
      >
        Send money online cross-border, <br />{" "}
        {/* <Typography sx={{fontSize: isSmallScreen ? '45px' : '60px',fontWeight:'800',color: theme.palette.primary.main,letterSpacing: '-1.8px',lineHeight:isSmallScreen ? '45px' : '80px'}}> */}
        <span style={{ color: theme.palette.primary.main, fontFamily:'Inter' }}>I{text}</span>
        {/* </Typography> */}
      </label>

      <label
        style={{
          fontSize: "20px",
          fontWeight: "400",
          color: theme.palette.secondary.main,
          paddingTop: "10px",
          paddingBottom: "10px",
          marginTop: "2px",
        }}
      >
        Our mission is to make international money transfers faster, easier, and
        more transparent. Choose how and when you send, with great exchange
        rates and low fees.
      </label>

      <label
        style={{
          fontSize: "16px",
          fontWeight: "400",
          color: theme.palette.secondary.main,
          opacity: 0.6,
          paddingTop: "10px",
          paddingBottom:'10px',
        }}
      >
        Partnered with
      </label>
      <Box display="flex" flexDirection="row" pt={1} pb={2}>
        <Box>
          <MastercardIcon />
        </Box>
        <Box sx={{ paddingX: "10px" }}>
          <VisaIcon />
        </Box>

        <PciDssIcon />
      </Box>

      <Box display="flex" paddingY="10px">
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <CheckmarkIcon style={{ marginRight: "8px" }} />
          <label
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: theme.palette.secondary.main,
              opacity: 0.6,
            }}
          >
            Zero fee
          </label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
            marginLeft: "10px",
          }}
        >
          <CheckmarkIcon style={{ marginRight: "8px" }} />
          <label
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: theme.palette.secondary.main,
              opacity: 0.6,
            }}
          >
            Best FX rates
          </label>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <CheckmarkIcon style={{ marginRight: "8px" }} />
          <label
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: theme.palette.secondary.main,
              opacity: 0.6,
            }}
          >
            Instant payments
          </label>
        </div>
      </Box>
    </Box>
  );
};

export default SendMoneyInstant;
