import React from "react";

const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z"
        fill="#6D1ED4"
      />
      <path
        d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z"
        stroke="#6D1ED4"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M2.42188 16.8752C3.18979 15.5449 4.2944 14.4401 5.62465 13.672C6.9549 12.9039 8.46392 12.4995 10 12.4995C11.5361 12.4995 13.0451 12.9039 14.3753 13.672C15.7056 14.4401 16.8102 15.5449 17.5781 16.8752"
        stroke="#6D1ED4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default UserIcon;
export {};
