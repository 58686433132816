import React from "react";

const KickStartIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="145" height="150" viewBox="0 0 145 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M104.395 98.4403C105.091 100.234 103.584 102.109 101.682 101.815L52.8988 94.2698C50.997 93.9756 50.1267 91.7329 51.3324 90.2329L82.2587 51.7581C83.4644 50.2581 85.8417 50.6258 86.5379 52.4199L104.395 98.4403Z" stroke="#6D1FD4" stroke-width="3" stroke-linejoin="round"/>
<path d="M90.4865 84.7507C91.4611 87.2625 89.3512 89.8875 86.6885 89.4757L37.9052 81.9302C35.2425 81.5183 34.0242 78.3785 35.7122 76.2786L66.6385 37.8037C68.3264 35.7037 71.6547 36.2185 72.6294 38.7303L90.4865 84.7507Z" stroke="#6D1FD4" stroke-linejoin="round"/>
</svg>

  );
};
export default KickStartIcon;
export {};
