import React from "react";

const MultiLayerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3834 3.63336C20.9491 3.43527 20.4773 3.33276 20 3.33276C19.5227 3.33276 19.051 3.43527 18.6167 3.63336L4.33335 10.1334C4.0376 10.2638 3.78615 10.4774 3.60963 10.7481C3.4331 11.0189 3.33911 11.3351 3.33911 11.6584C3.33911 11.9816 3.4331 12.2978 3.60963 12.5686C3.78615 12.8394 4.0376 13.053 4.33335 13.1834L18.6334 19.7C19.0676 19.8981 19.5394 20.0006 20.0167 20.0006C20.494 20.0006 20.9657 19.8981 21.4 19.7L35.7 13.2C35.9958 13.0696 36.2472 12.856 36.4238 12.5853C36.6003 12.3145 36.6943 11.9983 36.6943 11.675C36.6943 11.3518 36.6003 11.0355 36.4238 10.7648C36.2472 10.494 35.9958 10.2804 35.7 10.15L21.3834 3.63336Z"
        stroke="#6D1ED4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1334 15.8334L4.30005 18.5C4.01373 18.6346 3.77162 18.8479 3.60204 19.115C3.43246 19.3821 3.34241 19.692 3.34241 20.0084C3.34241 20.3248 3.43246 20.6346 3.60204 20.9017C3.77162 21.1688 4.01373 21.3821 4.30005 21.5167L18.6334 28.0334C19.0654 28.229 19.5342 28.3302 20.0084 28.3302C20.4826 28.3302 20.9514 28.229 21.3834 28.0334L35.6834 21.5334C35.9791 21.403 36.2306 21.1894 36.4071 20.9186C36.5836 20.6479 36.6776 20.3316 36.6776 20.0084C36.6776 19.6851 36.5836 19.3689 36.4071 19.0981C36.2306 18.8274 35.9791 18.6138 35.6834 18.4834L29.8501 15.8334"
        stroke="#6D1ED4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1334 24.1666L4.30005 26.8333C4.01373 26.9679 3.77162 27.1812 3.60204 27.4483C3.43246 27.7154 3.34241 28.0252 3.34241 28.3416C3.34241 28.658 3.43246 28.9679 3.60204 29.235C3.77162 29.5021 4.01373 29.7154 4.30005 29.85L18.6334 36.3666C19.0654 36.5622 19.5342 36.6634 20.0084 36.6634C20.4826 36.6634 20.9514 36.5622 21.3834 36.3666L35.6834 29.8666C35.9791 29.7362 36.2306 29.5226 36.4071 29.2519C36.5836 28.9811 36.6776 28.6648 36.6776 28.3416C36.6776 28.0184 36.5836 27.7021 36.4071 27.4314C36.2306 27.1606 35.9791 26.947 35.6834 26.8166L29.8501 24.1666"
        stroke="#6D1ED4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="19.619" cy="22.619" r="6.98413" fill="#43D991" />
      <g clip-path="url(#clip0_198_5073)">
        <path
          d="M19.5002 23.9583C19.6612 23.9583 19.7918 23.8277 19.7918 23.6667C19.7918 23.5056 19.6612 23.375 19.5002 23.375C19.3391 23.375 19.2085 23.5056 19.2085 23.6667C19.2085 23.8277 19.3391 23.9583 19.5002 23.9583Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.5418 21.9166H17.4585C17.1363 21.9166 16.8751 22.1778 16.8751 22.5V24.8333C16.8751 25.1555 17.1363 25.4166 17.4585 25.4166H21.5418C21.864 25.4166 22.1251 25.1555 22.1251 24.8333V22.5C22.1251 22.1778 21.864 21.9166 21.5418 21.9166Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0417 21.9167V21.0417C18.0417 20.6549 18.1954 20.284 18.4689 20.0105C18.7424 19.737 19.1133 19.5834 19.5001 19.5834C19.8869 19.5834 20.2578 19.737 20.5313 20.0105C20.8048 20.284 20.9584 20.6549 20.9584 21.0417V21.9167"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_198_5073">
          <rect
            width="7"
            height="7"
            fill="white"
            transform="translate(16.0001 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MultiLayerIcon;
export {};
