import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import GitHubIcon from '@mui/icons-material/GitHub';
import PhoneIcon from "../assets/icons/PhoneIcon";
import MailIcon from "../assets/icons/MailIcon";
interface Blog {
    name: string;
    url: string;
  }

  interface SocialLink {
    icon: JSX.Element;
    url: string;
  }
   
  interface ContactLink {
    icon: JSX.Element;
    info: string;
    url: string;
  }

  interface FaqData {
    question: string;
    answer: string;
    subAnswer:string;
  }
  export const blogs: Blog[] = [
    { name: 'White-glove implementation', url: 'https://example.com/blog/white-glove-implementation' },
    { name: 'Switch from Jira', url: 'https://example.com/blog/switch-from-jira' },
    { name: 'Better than Asana', url: 'https://example.com/blog/better-than-asana' },
    // { name: 'Simpler than Monday.com', url: 'https://example.com/blog/simpler-than-monday' },
    // { name: 'WhoTrack?', url: 'https://example.com/blog/whotrack' },
  ];

  export const socialLinks: SocialLink[] = [
    { icon: <FacebookOutlinedIcon />, url: 'https://facebook.com' },
    { icon: <InstagramIcon />, url: 'https://instagram.com' },
    { icon: <XIcon />, url: 'https://example.com' },
    { icon: <GitHubIcon />, url: 'https://github.com' },
  ];

  export const contactLinks: ContactLink[] = [
    {
      icon: <PhoneIcon />,
      info: '+918074978910',
      url: 'tel:+918074978910',
    },
    {
      icon: <MailIcon />,
      info: 'connect@aptremit.io',
      url: 'mailto:hello@connect.io',
    },
  ];
  export {};
  
export  const faqData: FaqData[] = [
  {
    question:'Are my transactions safe ?',
    answer:
     'We prioritize security and safety for all transactions. Our platform uses state-of-the-art encryption protocols and complies with regulatory standards to protect your personal and financial information.',
     subAnswer:'Furthermore, we continuously monitor our systems for any suspicious activities and employ robust fraud prevention measures to mitigate any risks. Rest assured, your transactions are handled with the highest level of security and confidentiality.'
  },
  {
    question:'Why is KYC Verification needed ?',
    answer:'To facilitate secure transactions, both senders and receivers are required to undergo identity verification,commonly known as KYC (Know Your Customer).',
    subAnswer:'This essential process is imperative to adhere to regulatory standards and ensure the compliance of our financial services.'
  },
  {
    question:'What is the transfer limit ?',
    answer:'Our transfer limits are subject to factors such as your location, the recipients country, and the chosen transfer method. These limits are in place to ensure security and regulatory compliance. ',
    subAnswer:'We may ask for additional verification to increase your transfer limit. Security is our top priority. For accurate transfer limit details, log into your account or contact customer support.'
  },
  {
    question:'How does referral rewards work ?',
    answer:'Our referral program rewards you for referring friends and family to our platform. Share your unique link or code, and when someone you refer completes a qualifying transaction, both of you earn rewards. ',
    subAnswer:'Rewards come in the form of credits or discounts on future transactions. The more referrals you make, the more rewards you can earn.'
  },
  {
    question:'What are the perks of using AptRemit ?',
    answer: 'AptRemit offers competitive exchange rates and low fees for international money transfers. Our platform provides fast and reliable transfer times, prioritizes security, and is easy to use with a user-friendly interface and intuitive mobile app.',
    subAnswer:'AptRemit is a trusted platform for international money transfers with excellent customer support. Whether sending money to family or conducting business, AptRemit provides a seamless experience for all your transfer needs.'
  }
]