import React from "react";
import {
  Typography,
  useMediaQuery,
  useTheme as MuiUseTheme,
} from "@mui/material";
import { useTheme } from "../assets/themeContext";

const Promotion: React.FC = () => {
  const { theme } = useTheme(); // Your custom theme
  const muiTheme = MuiUseTheme(); // Material-UI theme
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down("md"));

  const scrollingText = (
    <div
      style={{
        overflow: "hidden", // Hide the overflowed text
        whiteSpace: "nowrap", // Keep text in a single line
        backgroundColor: theme.palette.primary.main, // Set the background color
        padding: "5px 0",
      }}
    >
      <Typography
        sx={{
          fontFamily:'Inter',
          display: "inline-block",
          fontSize: "16px",
          fontWeight: "400",
          color: "#FCFBFF",
          animation: "scroll-horizontal 10s linear infinite", // Keep the duration or adjust as needed
          animationTimingFunction: "cubic-bezier(0.5, 0.9, 0, 0)", // Adjust this bezier curve to control the speed
          "@keyframes scroll-horizontal": {
            "0%": {
              transform: "translateX(100%)", // Start off the right edge of the screen
            },
            "100%": {
              transform: "translateX(-100%)", // End off the left edge of the screen
            },
          },
        }}
      >
        Start strong. Gain an additional ₹1 above the Google rate with each
        transaction during your first six months!
      </Typography>
    </div>
  );

  return isSmallScreen ? (
    scrollingText
  ) : (
    <Typography
      sx={{
        fontFamily:'Inter',
        flexGrow: 2,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        fontSize: "16px",
        fontWeight: "400",
        color: "#FCFBFF",
        padding: "5px 0", // Add some padding to ensure the background covers the area
      }}
    >
      Start strong. Gain an additional ₹1 above the Google rate with each
      transaction during your first six months!
    </Typography>
  );
};

export default Promotion;
