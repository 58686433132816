import React from "react";
import { useTheme as T, useMediaQuery, Button, Box } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import AppstoreIcon from "../assets/icons/AppstoreIcon";
import PlaystoreIcon from "../assets/icons/PlaystoreIcon";
const Download = () => {
  const { theme } = useTheme();
  const t = T();
  const matchesSM = useMediaQuery(t.breakpoints.down("sm"));

  const backgroundSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" width="1440" height="596" viewBox="0 0 1440 596" fill="none">
      <path d="M1443 214.361V0L721.5 69.1803L0 0V214.361C0 295.592 51.6411 367.838 128.499 394.132L715.026 594.785C719.223 596.221 723.777 596.221 727.974 594.785L1314.5 394.132C1391.36 367.838 1443 295.592 1443 214.361Z" fill="#F5F8FF"/>
    </svg>
  `;

  const backgroundStyle = {
    ...(matchesSM
      ? {
          backgroundColor: "#F5F8FF",
          backgroundSize: "auto 100%", // This will scale the height to 100% of the container and adjust the width automatically
        }
      : {
          backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
            backgroundSVG
          )}")`,
          backgroundSize: "cover", // Or any other size that suits your large-screen design
        }),
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        ...backgroundStyle,
        width: "100%",
        height: matchesSM ? "400px" : "500px",
      }}
    >
      <Box textAlign="center" p={2} sx={{display:'flex', flexDirection:'column'}}>
        <label
          style={{
            fontWeight: 400,
            color: theme.palette.primary.main,
            fontSize: "14px",
          }}
        >
          CROSSBORDER PAYMENT
        </label>
        <label
          style={{
            fontWeight: 800,
            color: theme.palette.secondary.main,
            fontSize: matchesSM ? "22px" : "40px",
          }}
        >
          Download our app and Remit <br /> seamlessly now
        </label>
        {/* change paddingTop */}
        <label
          style={{
            fontWeight: 500,
            color: theme.palette.secondary.light,
            fontSize: "20px",
            paddingTop: "2px",
          }}
        >
          Remittance made easy and stress free
        </label>
        <Box mt={5}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              fontSize: "16px",
              fontWeight: 500,
              marginRight: 2,
              borderRadius: 5,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            startIcon={<AppstoreIcon />}
          >
            Appstore
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              fontSize: "16px",
              fontWeight: 500,
              borderRadius: 5,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            startIcon={<PlaystoreIcon />}
          >
            Google Play
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Download;
