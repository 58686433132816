import { ReactElement } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import ThemeSwitch from '../components/themeSwitch';
import Login from '../pages/Loginform';
import SignUpForm from '../pages/SignUpForm';


interface MainRoutesObject {
    path: string;
    children: RouteObject[];
}

const MainRoutes: MainRoutesObject = {
    path: '/',
    children: [
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'signup',
            element: <SignUpForm />
        },
        {
            path: 'theme',
            element: <ThemeSwitch />
        },
        
    ]
};

export default MainRoutes;
