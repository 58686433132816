import React from "react";

const Step3Icon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="32.1404" cy="31.3904" r="11.1746" fill="#43D991"/>
<path d="M30.1103 33.0159L36.1039 27.0223C36.2902 26.836 36.5272 26.7429 36.815 26.7429C37.1029 26.7429 37.3399 26.836 37.5261 27.0223C37.7124 27.2085 37.8055 27.4456 37.8055 27.7334C37.8055 28.0212 37.7124 28.2583 37.5261 28.4445L30.8214 35.1493C30.6182 35.3524 30.3812 35.454 30.1103 35.454C29.8394 35.454 29.6023 35.3524 29.3992 35.1493L26.7579 32.508C26.5716 32.3218 26.4785 32.0847 26.4785 31.7969C26.4785 31.5091 26.5716 31.272 26.7579 31.0858C26.9441 30.8995 27.1812 30.8064 27.469 30.8064C27.7568 30.8064 27.9939 30.8995 28.1801 31.0858L30.1103 33.0159Z" fill="white"/>
<path d="M54.0837 34.6668C54.0837 48.0001 44.7503 54.6668 33.657 58.5335C33.0761 58.7303 32.4451 58.7209 31.8703 58.5068C20.7503 54.6668 11.417 48.0001 11.417 34.6668V16.0001C11.417 15.2929 11.6979 14.6146 12.198 14.1145C12.6981 13.6144 13.3764 13.3335 14.0837 13.3335C19.417 13.3335 26.0837 10.1335 30.7237 6.08014C31.2886 5.59747 32.0073 5.33228 32.7503 5.33228C33.4934 5.33228 34.212 5.59747 34.777 6.08014C39.4437 10.1601 46.0837 13.3335 51.417 13.3335C52.1242 13.3335 52.8025 13.6144 53.3026 14.1145C53.8027 14.6146 54.0837 15.2929 54.0837 16.0001V34.6668Z" stroke="#6D1ED4" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>  
  );
};
export default Step3Icon;
export {};
