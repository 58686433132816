import { ReactElement } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import ThemeSwitch from '../components/themeSwitch';
import Navbar from '../pages/Navbar';
import HomePage from '../pages/HomeScreen';


interface MainRoutesObject {
    path: string;
    children: RouteObject[];
}

const MainRoutes: MainRoutesObject = {
    path: '/',
    
    children: [
        {
            index: true, 
            element: <HomePage />
        }
    ]
};

export default MainRoutes;
