import React from 'react';

const MobileArrowUpIcon:React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return(
        <svg 
            width="12" 
            height="8" 
            viewBox="0 0 12 8" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M1.33331 6.33337L5.99998 1.66671L10.6666 6.33337" 
                stroke="#6D1ED4" 
                stroke-width="2" 
                stroke-linecap="round" 
                stroke-linejoin="round"
            />
        </svg>
    );
}

export default MobileArrowUpIcon;

export{};