// services/ExchangeService.js
const getExchangeRate = async () => {
    try {
      const response = await fetch('https://api-dev.aptremit.io/exchange-api/api/exchange/', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Could not fetch exchange rates:", error);
      // Handle errors as appropriate for your application
      throw error;
    }
  };
  
  export default getExchangeRate;
  