import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6D1ED4',
      light:'#D6B6FF',
      dark:'#64607D'
    },
    secondary: {
      main: '#1F1F1F',
      light:'#474747', // Change this to your desired color
    },
    // error:{

    // },
    // warning:{

    // },
    // info:{

    // },
    // success:{

    // }

  },
  typography: { 
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),
    }, 
});

export default lightTheme;
